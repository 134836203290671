import { cloneDeep, isEqual } from 'lodash';
import { PremiumCalculatorApiClient } from 'PremiumCalculator/PremiumCalculatorApiClient';
import { StringUtil } from 'Util/Helpers';
import Logger from 'Util/Logger';
import * as PolicyActions from './PolicyActions';

const logger = new Logger("PolicyThunks");

export function calculatePremium(po) {
    return async function (dispatch, getState) {
        const currState = getState();

        if (currState.order.isCalculatingPremium) {
            logger.debug("auto-calculate: already calculating. skipping.");
            return;
        }

        if (currState.order.premiumChanges === 0) {
            logger.debug("no changes. skipping auto-calculation.");
            return;
        }

        dispatch({ type: PolicyActions.setIsCalculatingPremium.type, payload: true });

        const secureId = currState.order.orderVolatileData.SecureId;

        //Cloning as the object is read-only and causes write error
        const _po = cloneDeep(po);

        if (!StringUtil.isNullOrEmpty(secureId)) {
            _po.SecureId = secureId;
        }

        _po.PolicyMap = currState.order.policyMap;

        const changes = currState.order.orderChanges;

        const resp = await PremiumCalculatorApiClient.calculatePremium(_po, changes, 'AutoCalculation');

        dispatch({ type: PolicyActions.setIsCalculatingPremium.type, payload: false });

        if (resp.hasValidationErrors || resp.hasError)
            return;

        const respData = resp.data;

        const premium = respData.Premium;

        //Sipatch only if changed, triggers auto-save
        if (!isEqual(currState.order.premium, premium))
            dispatch({ type: PolicyActions.setPremium.type, payload: premium });
    }
}