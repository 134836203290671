import { Box } from '@material-ui/core';
import { Question } from 'Components/Questionary';
import { CollectionTypeEnum } from 'Model/PolicyOrder';
import * as PolicyActions from 'Policy/State/PolicyActions';
import React from 'react';
import { useDispatch } from 'react-redux';
import { SectionHelper } from '../../Util/SectionHelper';
import NHPCondoLegalDescriptionInfo from './NHPCondoLegalDescriptionInfo';

function NHPCondoLegalDescriptionSection(props) {
    const { questionParams, itemsList, property } = props;

    const dispatch = useDispatch();

    const collectionName = CollectionTypeEnum.NHPCondoLegalDescriptions;

    const canAdd = itemsList.length < 6;

    function add() {
        const newItem = SectionHelper.addNewSection(collectionName, questionParams, { NHPCondoLegalDescriptionPropertyId: property.Id });

        dispatch({ type: PolicyActions.add_nph_condo_legal_description.type, payload: newItem });
    }

    function remove(item) {
        SectionHelper.removeSection({ collectionName, item, questionParams });

        dispatch({ type: PolicyActions.remove_nph_condo_legal_description.type, payload: item });
    }

    return <Question qId="NHPCondoLegalDescription" {...questionParams} optional>
        {itemsList.map((item, index) => {
            return (
                <NHPCondoLegalDescriptionInfo
                    key={item.Id}
                    idx={index}
                    collectionName={collectionName}
                    item={item}
                    canAdd={canAdd}
                    add={add}
                    remove={remove}
                    questionParams={questionParams} />
            );
        })}
        <Box mb={2} />
    </Question>;
}

export default NHPCondoLegalDescriptionSection;