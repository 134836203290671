import { TableRow } from '@material-ui/core';
import { CollectionTypeEnum } from 'Model/PolicyOrder';
import React from "react";
import PurchaserInfo from './PurchaserInfo';

function Purchasers(props) {
    const { itemsList, questionParams, propertyConditionalProps } = props;

    if (!itemsList)
        return null;

    const collectionName = CollectionTypeEnum.Purchasers;

    return itemsList.map(item =>
        <TableRow key={item.Id}>
            <PurchaserInfo
                collectionName={collectionName}
                item={item}
                questionParams={questionParams}
                propertyConditionalProps={propertyConditionalProps} />
        </TableRow>
    );
}

export default Purchasers;