const crypto = require("crypto");
const base64url = require('base64url');

class IdGenerator {
    NewId() {
        const buffer = crypto.randomBytes(18);
        const id = base64url.encode(buffer);
        return id;
    }
}

const instance = new IdGenerator();

export { instance as IdGenerator }