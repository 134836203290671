import { Grid, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { AppSettings } from 'AppSettings';
import ErrorSnackbar from 'Components/ErrorSnackbar';
import NotAuthorized from 'Components/NotAuthorized';
import { SectionHeader } from 'Components/Questionary';
import { BackgroundCheckTypeEnum } from 'Model/BackgroundCheck';
import { PermittedOrderActionsEnum } from 'Model/PolicyOrder';
import * as UserPermissions from 'Model/UserPermisions';
import NotesFrame from 'Notes/NotesFrame2.3';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import * as UserActions from 'User/State/UserActions';
import BrowserHelper from 'Util/BrowserHelper';
import { StringUtil, UriUtil, UserHelper } from 'Util/Helpers';
import Logger from 'Util/Logger';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';
import { ValidationHelper } from 'Util/ValidationHelper';
import BackgroundCheck from './BackgroundCheck';
import AutoSaver from './FX/AutoSaver';
import ProjectPropertyReloader from './FX/ProjectPropertyReloader';
import QuestionaryReloader from './FX/QuestionaryReloader';
import { PolicyApiClient } from './PolicyApiClient';
import PolicyDecisionActions from './PolicyDecisionActions';
import PolicyViewActions from './PolicyViewActions';
import PolicyViewFrame from './PolicyViewFrame';
import PolicyViewHelper from './PolicyViewHelper';
import CertificateOfTitleSection from './Sections/CertificateOfTitleSection';
import EndorsementsView from './Sections/Endorsements/EndorsementsView';
import ExceptionsView from './Sections/Exceptions/ExceptionView';
import OrderSummaryView from './Sections/OrderSummary/OrderSummaryView';
import PolicyDocumentsSection from './Sections/PolicyDocumentsSection';
import PropertiesSection from './Sections/PropertiesSection';
import TransactionSection from './Sections/TransactionSection';
import * as PolicyActions from './State/PolicyActions';
import * as PolicyThunks from './State/PolicyThunks';
import PremiumBreakdown from './Sections/PremiumSection/PremiumInfo';
import AutoCalculator from './FX/AutoCalculator';
import InsureOverView from './Sections/InsureOvers/InsureOverView';
import { FormFieldHelper } from 'Components/Form/FormFieldHelper';

const urljoin = require('url-join');

const logger = new Logger(PolicyView.name);

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  // ---------------
  loadingSection: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  form: {
    // width: '100%',
    width: BrowserHelper.isIE11 ? '1200px' : '100%', // Fix IE 11 issue.
    margin: theme.spacing(2)
  }
}));

function PolicyView(props) {
  const classes = useStyles();

  const { t } = useTranslation();

  const [initLoadData, setInitLoadData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      loading: true,
      loadingErrorMessage: '',
      questionary: {},
      reviewMap: {},
      po: {},
      answersMap: null,
      canView: true
    }
  );

  // IGOR NEED TO GET RID OF THESE replace with Redux
  const [eventLogs, setEventLogs] = useState(null);
  const [inputs, setInputs] = useState({});
  const [changingStatus, setChangingStatus] = useState(false); // IGOR can we get rid and just use isBusy??
  const [actionMsg, setActionMsg] = useState(''); // IGOR -- let's get rid, this is now in redux
  const [actionMsgType, setActionMsgType] = useState(''); // IGOR -- let's get rid, this is now in redux
  // IGOR NEEED TO GET RID OF THESE, replace with Redux

  const { po, loading, loadingErrorMessage, questionary, reviewMap } = initLoadData;

  const selectUserInfo = useMemo(
    UserActions.createUserInfoSelector,
    []
  );

  const userInfo = useSelector(x => selectUserInfo(x));

  const dispatch = useDispatch();

  const viewHelper = new PolicyViewHelper(po, dispatch);

  const searchParams = new URLSearchParams(window.location.search);

  const selectChange = useMemo(
    FormFieldHelper.createFieldStateSelector,
    []
);

  const permittedActions = useSelector(x => selectChange(x.order.orderVolatileData, "PermittedActions"));
  //IGOR -> Change order status causes an issue as the premitted actions are resolved only onload
  //Resolving it from redux store will reload the enitre parent
  const allowSave = StringUtil.contains(permittedActions, "Save");

  // #region Policy Status

  function setDecisionComments(decisionComments) {

    // igor perf
    /*
    setPo({
      ...po,
      DecisionComments: decisionComments
    });
    */
  }

  // #endregion Policy Status

  //#region Event Logs -- load once upon deal load ....
  /* IGOR DISABLE FOR NOW
  useEffect(() => {
    let isCancelled = false;

    function onUnload() {
      isCancelled = true;
    }

    async function loadEventLogs() {
      const resp = await PolicyApiClient.loadEventLogs(po.SecureId);

      if (resp.hasError) {
        return;
      }

      const eventLogs = resp.data.EventLogs;

      if (!isCancelled) {
        setEventLogs(eventLogs);
      }
    }

    // doing a "!loading" check prevents logs being multiple times...
    if (po && po.SecureId && !loading) {
      // loadEventLogs(); // IGOR IE 11 FOR HENRY
    }

    return onUnload;
  }, [po]);
  */
  //#endregion Event Logs

  // FIRST LOAD
  // IGOR When using useState and invoking setState form aync location (such as from useEffect), keep in mind
  // React will NOT batch the updates, causing re-render for each setState call ..
  useEffect(() => {
    let isCancelled = false;

    function onUnload() {
      isCancelled = true;

      logger.debug('unload');
    }

    async function loadDeal() {
      let secureIdToLoad = viewHelper.getDealIdFromQueryString();

      if (StringUtil.isNullOrEmpty(secureIdToLoad)) {
        logger.debug('loadDeal: deal # is not provided. assuming new deal.');

        secureIdToLoad = 'new';

        if (!UserHelper.hasPermission(userInfo, UserPermissions.create_deal)) {
          if (!isCancelled) {
            setInitLoadData({ loading: false, canView: false });
          }

          return; // RETURN !!!
        }
      }

      const result = await PolicyApiClient.loadPolicy(secureIdToLoad);

      if (result.hasError) {
        if (!isCancelled) {
          setInitLoadData({ loading: false, loadingErrorMessage: result.errorMessage });
        }

        return onUnload;
      }

      let newReviewMap = {};

      if (result.hasValidationInfo) {
        newReviewMap = ValidationHelper.getValidationMap(result.data); // IGOR -- this is Kenneth
      }

      const newPo = result.data.PolicyOrder;

      if (!isCancelled) {
        const newQuestionary = newPo.Questionary;

        const answersMap = new Map();

        // inputs must be set before setLoading(false)...
        for (const q of newQuestionary.Questions) {
          QuestionaryHelper.setAnswerInitialValue(newPo, q, answersMap, isCancelled);
        }

        delete newPo.Questionary;

        viewHelper.setNewOrderData(newPo);

        setInitLoadData({
          loading: false,
          loadingErrorMessage: '',
          questionary: newQuestionary,
          reviewMap: newReviewMap,
          po: newPo,
          answersMap
        });
      }
    }

    logger.debug(loadDeal.name + ' useEffect');

    loadDeal();

    return onUnload;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (initLoadData.loading === false && initLoadData.canView === false) {
    return <NotAuthorized />
  }

  if (loading) {
    logger.debug("loading");

    return (
      <div className={classes.loadingSection}>
        <LinearProgress />
        <Typography align="center">Loading policy...</Typography>
      </div>
    );
  }

  if (!StringUtil.isNullOrEmpty(loadingErrorMessage)) {
    logger.debug("loading error message");

    return (
      <div style={{ marginTop: '0.5em' }}><ErrorSnackbar message={loadingErrorMessage} centered={true} /></div>
    );
  }

  function getInitialAnswer(fieldName) {
    if (!initLoadData || !initLoadData.answersMap || !fieldName) {
      return null;
    }

    const a = initLoadData.answersMap.get(fieldName);

    return a;
  }

  const handleInputChange = (event) => {
    event.persist();

    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    dispatch({ type: PolicyActions.changeOrder.type, payload: { fieldName, fieldValue } });
    dispatch({ type: PolicyActions.clearValidationError.type, payload: { fieldName } });

    logger.debug(`${handleInputChange.name} - ${fieldName} change: ${fieldValue}`);
  }

  const isReviewRequired = (fieldName) => {
    if (StringUtil.isNullOrEmpty(reviewMap) || StringUtil.isNullOrEmpty(fieldName))
      return null;

    const e = reviewMap[fieldName.toLowerCase()];

    if (StringUtil.isNullOrEmpty(e))
      return null;

    return StringUtil.isEqual(e.ErrorCode, 'REVIEW_REQUIRED');
  }

  function setSecureIdUrlParam(secureId) {
    if (StringUtil.isNullOrEmpty(secureId)) {
      return;
    }

    UriUtil.replaceUrlParam('id', secureId);
  }

  function save(event, submitOrder) {
    dispatch(PolicyThunks.saveOrder(po, submitOrder));
  }

  async function restore(event) {
    setActionMsgType('');
    setActionMsg('');

    setChangingStatus(true);

    const resp = await PolicyApiClient.restoreOrder(po.SecureId);

    setChangingStatus(false);

    if (resp.hasError) {
      setActionMsgType('error');
      setActionMsg(resp.errorMessage);

      if (!resp.hasValidationResult) {
        return;
      }
    }

    const respData = resp.data;

    const validationMap = ValidationHelper.getValidationMap(respData);

    // IGOR PERF setValidationsMap(validationMap);

    if (!StringUtil.isNullOrEmpty(validationMap)) {
      return; // we hit a validation error
    }

    setActionMsgType(respData.Status);
    setActionMsg(respData.StatusDescription);

    const newPo = respData.PolicyOrder;

    // IGOR PERF setPo(newPo);
  }

  async function changeOrderStatus(event, orderStatus) {
    dispatch(PolicyThunks.changeOrderStatus(po, orderStatus));
  }

  async function executeCheck(type) {

    const policyId = po.SecureId;

    let result = null;

    switch (type) {
      case BackgroundCheckTypeEnum.PinCheckOnPolicies:
        result = await PolicyApiClient.retryPinCheckOnPolicies(policyId, po.Matter.Properties);
        break;
      case BackgroundCheckTypeEnum.LawyerCheck:
        result = await PolicyApiClient.retryLawyerCheck(policyId, "A012751R");
        break;
      case BackgroundCheckTypeEnum.NameChecksOnSupplementalDb:
        result = await PolicyApiClient.retryNameCheckOnSupplementalDb(policyId, "can wit");
        break;
    }

    if (result.hasError) {
      setActionMsgType('error');
      setActionMsg(result.errorMessage);
      return;
    }

    if (result.data.HasValidationErrors) {
      setActionMsgType('error');
      setActionMsg(`${type} : Retry failed`);
      return;
    }

    // igor PERF
    /*
    setPo(prevState => ({
      ...prevState,
      [type]: result.data[type]
    }));
    */

    return;
  }

  const isBusy = loading || changingStatus; // IGOR TODO - get rid of changingStatus

  function inputProps(name) {
    const p = {
      name: name,
      onChange: handleInputChange,
      defaultValue: getInitialAnswer(name) || ''
    };

    return p;
  }

  function getUploadUrls(q) {
    const urls = {
      list: urljoin(AppSettings.urls.documents, `/policy/ListUploadedFiles?poId=${po.SecureId}&questionId=${q.Id}`),
      upload: urljoin(AppSettings.urls.documents, `/policy/GetUploadFileUrl?poId=${po.SecureId}&questionId=${q.Id}`),
      view: urljoin(AppSettings.urls.documents, `/policy/ViewUploadedFile?poId=${po.SecureId}&questionId=${q.Id}`)
    };

    return urls;
  }

  const readOnly = !allowSave;

  const questionParams = {
    getUploadUrls,
    questionary,
    inputProps,
    readOnly,
    isReviewRequired,
    fieldStateResolver: (x) => x.order.formState,
    twoWayStateResolver: (x) => x.order.shadowChanges
  };

  const policyViewActionsParams = {
    isBusy,
    save,
    changingStatus,
    restore,
    po,
    actionMsgType,
    setActionMsgType,
    actionMsg,
    setActionMsg,
    changeOrderStatus,
    readOnly
  };

  const policyDecisionActionsParams = {
    po,
    changeOrderStatus
  };

  const notesParams = {
    notes: po.Notes,
    reviewValidationMap: reviewMap,
    policyId: po.SecureId
  };

  const backgroundCheckParams = {
    executeCheck: executeCheck,
    po
  };

  //#region Background Checks

  const backgroundChecks = <React.Fragment>
    <SectionHeader section="checks" title="Background Checks" />
    <BackgroundCheck type={BackgroundCheckTypeEnum.PinCheckOnPolicies} text="Pin check on issued policies" {...backgroundCheckParams} />
    <BackgroundCheck type={BackgroundCheckTypeEnum.PinCheckOnDirectory} text="Pin check on directory" {...backgroundCheckParams} />
    <BackgroundCheck type={BackgroundCheckTypeEnum.LawyerCheck} text="Lawyer eligibility" {...backgroundCheckParams} />
    <BackgroundCheck type={BackgroundCheckTypeEnum.NameChecksOnSupplementalDb} text="Name" {...backgroundCheckParams} />
  </React.Fragment>

  //#endregion Background Checks

  //#region Notes

  const notesFrame = StringUtil.contains(po.PermittedActions, PermittedOrderActionsEnum.TakeNotes) &&
    <Grid container spacing={2}>
      <SectionHeader title="Notes" />
      <NotesFrame {...notesParams} />
    </Grid>;

  //#endregion Notes

  const showBgChecks = UserHelper.hasPermission(userInfo, UserPermissions.view_background_checks) && StringUtil.containsAny(po.PermittedActions, PermittedOrderActionsEnum.Approve, PermittedOrderActionsEnum.Decline);

  const sectionParams = { questionary, questionParams, inputs, setInputs, po, getInitialAnswer };

  logger.debug("po render full");

  return (
    <PolicyViewFrame po={po} userInfo={userInfo} {...policyViewActionsParams}>
      <div className={classes.toolbar} />
      <form className={classes.form} noValidate autoComplete="off">
        <PolicyDecisionActions {...policyDecisionActionsParams} />
        {notesFrame}
        <Grid container spacing={2}>
          {showBgChecks && backgroundChecks}
          <TransactionSection {...sectionParams} />
          <PropertiesSection {...sectionParams} />
          <ExceptionsView po={po} />
          <EndorsementsView po={po} />
          <InsureOverView po={po} />
          <OrderSummaryView {...sectionParams} />
          <CertificateOfTitleSection {...sectionParams} userInfo={userInfo} />
          <PolicyDocumentsSection po={po} {...sectionParams} />
          {/* <EventLogs logs={eventLogs} /> */}
          <Grid item xs={12}>
            <PolicyViewActions {...policyViewActionsParams} />
          </Grid>
        </Grid>
      </form>
      <AutoSaver po={po} />
      <QuestionaryReloader getInitialAnswer={getInitialAnswer} />
      <AutoCalculator po={po} />
      <ProjectPropertyReloader />
    </PolicyViewFrame>
  );
}

export default PolicyView;