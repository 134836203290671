import React, { useState } from 'react';
import { FormGroup, FormControl, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StringUtil } from 'Util/Helpers';
import SyntheticEvent from './SyntheticEvent';

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main
  }
}));

function MultipleOptionGroup(props) {
  const { name, onChange, opts, error, helperText, defaultValue } = props;

  const classes = useStyles();

  if (StringUtil.isNullOrEmpty(opts))
    opts = [];

  let selectedDefault = [];

  if (!StringUtil.isNullOrEmpty(defaultValue)) {
    selectedDefault = defaultValue.split('|');
  }

  const [selected, setSelected] = useState(selectedDefault);

  function isChecked(option) {
    const result = selected.includes(option);

    return result;
  }

  const handleInputChange = option => event => {
    event.persist();

    const checked = event.target.checked;

    let changed = false;

    const selectedNew = [...selected];

    if (!checked) {
      const idx = selected.indexOf(option);

      if (idx !== -1) {
        selectedNew.splice(idx, 1);

        changed = true;
      }
    }
    else {
      selectedNew.push(option);

      changed = true;
    }

    if (changed) {
      setSelected(selectedNew); // this will re-render the entire control ...
    }

    const selectedOpts = selectedNew.join('|');

    const ev = new SyntheticEvent(name, selectedOpts);

    onChange(ev);
  }

  const classN = error ? classes.error : null;

  return (
    <FormControl error={error} component="fieldset" aria-label={name} name={name} className={classN}>
      <FormGroup row>
        {opts.map((opt) =>
          <FormControlLabel
            key={opt.Id}
            value={opt.Id}
            control={<Checkbox color="primary" className={classN} checked={isChecked(opt.Id)} onChange={handleInputChange(opt.Id)} />}
            label={opt.Label}
            labelPlacement="end"
          />
        )}
      </FormGroup>
      {!StringUtil.isNullOrEmpty(helperText) &&
        <FormHelperText style={{ marginBottom: '0.8rem' }}>{helperText}</FormHelperText>
      }
    </FormControl>
  );
}

export default MultipleOptionGroup;