import { AppSettings } from 'AppSettings';
import * as AppErrors from 'Model/AppErrors';
import { ApiHelper } from 'Util/ApiHelper';
import { StringUtil } from 'Util/Helpers';
import Logger from 'Util/Logger';
import ApiResponse from 'Model/ApiResponse';

const urljoin = require('url-join');

class AdminApiClient {
    logger;

    constructor() {
        this.logger = new Logger(AdminApiClient.name);
    }

    async listDeals(listReq) {
        if (StringUtil.isNullOrEmpty(listReq))
            return;

        const url = urljoin(AppSettings.urls.policy, '/admin/listDeals');

        const authHeaders = await ApiHelper.authHeaders();

        try {
            const response = await fetch(url, {
                body: JSON.stringify(listReq),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error listing policies', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    
    async changeTags(secureId, newTags) {
        const url = urljoin(AppSettings.urls.policy, '/admin/changeTags');

        const req = {
            SecureId: secureId,
            Tags: newTags
        };

        const authHeaders = await ApiHelper.authHeaders();

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error changing tags', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async changeOwner(secureId, userInfo) {
        const url = urljoin(AppSettings.urls.policy, '/admin/changeOwner');

        const req = {
            SecureId: secureId,
            Owner: userInfo
        };

        const authHeaders = await ApiHelper.authHeaders();

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error changing owner', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async changeContributors(secureId, userInfoArr) {
        const url = urljoin(AppSettings.urls.policy, '/admin/changeContributors');

        const req = {
            SecureId: secureId,
            Contributors: userInfoArr
        };

        const authHeaders = await ApiHelper.authHeaders();

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error changing contributors', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async loadAllTPUsers() {
        const url = urljoin(AppSettings.urls.policy, '/admin/loadAllTPUsers');

        const req = {
        };

        const authHeaders = await ApiHelper.authHeaders();

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error loading users', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async getAvailableEndorsements(applicability) {
        const url = urljoin(AppSettings.urls.policy, '/admin/getAvailableEndorsements');

        const req = {
            Applicability : applicability
        };

        const authHeaders = await ApiHelper.authHeaders();

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error getting available endorsements', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async changeEndorsements(secureId, newEndorsements) {
        const url = urljoin(AppSettings.urls.policy, '/admin/changeEndorsements');

        const req = {
            SecureId: secureId,
            EndorsementIds: newEndorsements
        };

        const authHeaders = await ApiHelper.authHeaders();

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error changing endorsements', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async searchLawyer(province, searchString) {
        const url = urljoin(AppSettings.urls.policy, '/admin/searchLawyer');

        const req = {
            Province: province,
            SearchString: searchString
        };

        const authHeaders = await ApiHelper.authHeaders();

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error searching lawyers', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }
}

const instance = new AdminApiClient();

export { instance as AdminApiClient }