import React from 'react';
import DropDown from 'Components/DropDown';
import { useSelector } from 'react-redux';

function PropertiesList(props) {
    const { q, fieldName, subQuestion, optionGroupProps } = props;

    const orderProperties = useSelector(x => x.order.properties);

    const opts = [];

    if (orderProperties && orderProperties.length > 0) {
        for (const p of orderProperties) {
            opts.push({
                Id: p.Id,
                Label: p.ItemNumber
            });
        }
    }

    return (
        <DropDown q={q} opts={opts} subQuestion={subQuestion} {...optionGroupProps(fieldName)} />
    );
}

export default PropertiesList;