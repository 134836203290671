import { createSelector } from '@reduxjs/toolkit';
import { SectionHeader } from 'Components/Questionary';
import { cloneDeep, isEmpty } from 'lodash';
import React, { Fragment, useMemo } from "react";
import { useSelector } from 'react-redux';
import BrowserHelper from 'Util/BrowserHelper';
import PropertyInfo from './PropertyInfo';

function createOrderSelector() {
  return createSelector(
    state => state,
    x => x
  );
}

function OrderSummaryView(props) {
  const { questionParams, loadQuestionary, getInitialAnswer } = props;
  let { questionary } = props;

  const select = useMemo(
    createOrderSelector,
    []
  );

  const reloadedQuestionary = useSelector(x => select(x.order.questionary));

  if (reloadedQuestionary && !isEmpty(reloadedQuestionary)) {
    const reloadedQuestionaryCopy = cloneDeep(reloadedQuestionary);

    questionary = reloadedQuestionaryCopy;
    questionParams.questionary = reloadedQuestionaryCopy;
  }

  const properties = useSelector(x => select(x.order.properties));

  const policyMap = useSelector(x => select(x.order.policyMap));

  if (!policyMap)
    return null;

  questionParams.twoWayStateResolver = (x) => x.order.orderChanges;

  return <Fragment>
    <SectionHeader
      section="orderSummary"
      title="Order Summary"
      open={true}
      collapsible={!BrowserHelper.isIE11()}
      iconImgPath={"/img/tag_to_notes.png"} />
      {properties.map((item, index) => <PropertyInfo
        key={item.Id}
        item={item}
        idx={index}
        questionParams={questionParams}
        questionary={questionary}
        loadQuestionary={loadQuestionary}
        getInitialAnswer={getInitialAnswer}
      />)}
  </Fragment>;
}

export default OrderSummaryView;