import { AppSettings } from 'AppSettings';
import ApiResponse from 'Model/ApiResponse';
import * as AppErrors from 'Model/AppErrors';
import { BackgroundCheckContext } from 'Model/BackgroundCheck';
import { ApiHelper } from 'Util/ApiHelper';
import { StringUtil } from 'Util/Helpers';
import Logger from 'Util/Logger';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';

const urljoin = require('url-join');

class PolicyApiClient {
    logger;

    constructor() {
        this.logger = new Logger(PolicyApiClient.name);
    }

    get uploadFileUrl() {
        const url = urljoin(AppSettings.urls.policy, '/policy/UploadFile');

        return url;
    }

    async savePolicy(po, inputs, submitOrder) {
        if (StringUtil.isNullOrEmpty(submitOrder)) {
            submitOrder = false;
        }

        for (const fieldName in inputs) {
            const answer = inputs[fieldName];

            if (!QuestionaryHelper.houseNestedAnswer(fieldName, answer, po)) {
                po[fieldName] = answer;
            }
        }

        const url = urljoin(AppSettings.urls.policy, `/policy/save?submit=${submitOrder}`);

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            PolicyOrder: po,
            SaveAndSubmit: submitOrder
        };

        let apiResp;

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    ...authHeaders
                }
            });

            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error saving policy', ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }

    async restoreOrder(secureId) {
        const url = urljoin(AppSettings.urls.policy, '/policy/restore');

        const req = {
            PolicyId: secureId
        };

        const authHeaders = await ApiHelper.authHeaders();

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error restoring order', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async changeStatus(secureId, newStatus) {
        const url = urljoin(AppSettings.urls.policy, '/policy/changeStatus');

        const req = {
            SecureId: secureId,
            OrderStatus: newStatus
        };

        const authHeaders = await ApiHelper.authHeaders();

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error changing status', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async registerPolicy(secureId) {
        const url = urljoin(AppSettings.urls.policy, '/policy/RegisterPolicy');

        const req = {
            PolicyId: secureId
        };

        const authHeaders = await ApiHelper.authHeaders();

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error changing status', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async loadPolicy(secureId) {
        if (StringUtil.isNullOrEmpty(secureId))
            return;

        const url = urljoin(AppSettings.urls.policy, `/policy/load?secureId=${secureId}`);

        const authHeaders = await ApiHelper.authHeaders();

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    ...authHeaders,
                    'Cache-Control': 'no-cache, no-store'
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error(`error loading policy ${secureId}`, ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async list(listReq) {
        if (StringUtil.isNullOrEmpty(listReq))
            return;

        const url = urljoin(AppSettings.urls.policy, '/policy/list');

        const authHeaders = await ApiHelper.authHeaders();

        try {
            const response = await fetch(url, {
                body: JSON.stringify(listReq),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error listing policies', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async saveNote(secureId, note) {

        const url = urljoin(AppSettings.urls.policy, '/note/save');

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            Note: note,
            PolicyId: secureId
        };

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error saving note to policy', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async saveNotes(secureId, notes) {

        const url = urljoin(AppSettings.urls.policy, '/note/batchsave');

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            Notes: notes,
            PolicyId: secureId
        };

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error saving notes to policy', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async deleteNote(secureId, note) {

        const url = urljoin(AppSettings.urls.policy, '/note/delete');

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            Note: note,
            PolicyId: secureId
        };

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error deleteing note from policy', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async searchNotes(searchNoteRequest) {

        const url = urljoin(AppSettings.urls.policy, '/note/search');

        const authHeaders = await ApiHelper.authHeaders();

        try {
            const response = await fetch(url, {
                body: JSON.stringify(searchNoteRequest),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error deleteing note from policy', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async loadNotes(policyId) {

        const url = urljoin(AppSettings.urls.policy, '/note/loadall');

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            PolicyId: policyId
        };

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error deleteing note from policy', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async loadReviewRules(policyId) {

        const url = urljoin(AppSettings.urls.policy, '/note/loadreviewrules');

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            SecureId: policyId
        };

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error deleteing note from policy', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async retryPinCheckOnPolicies(policyId, properties) {

        const url = urljoin(AppSettings.urls.policy, '/policy/checkpin');

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            PolicyId: policyId,
            Properties: properties,
            Context: BackgroundCheckContext.Retry
        };

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error performing pin check', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async retryLawyerCheck(policyId, lsoNumber) {

        const url = urljoin(AppSettings.urls.policy, '/policy/checklawyer');

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            PolicyId: policyId,
            LSONumber: lsoNumber,
            Context: BackgroundCheckContext.Retry
        };

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error performing lawyer check', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async retryNameCheckOnSupplementalDb(policyId, searchText) {

        const url = urljoin(AppSettings.urls.policy, '/policy/checkName');

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            PolicyId: policyId,
            InsuredName: searchText,
            Context: BackgroundCheckContext.Retry
        };

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error performing name check', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async searchPolicyException(searchText) {

        const url = urljoin(AppSettings.urls.policy, '/policy/searchexception');

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            SearchCriteria: searchText
        };

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error performing name check', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async savePolicyExceptions(policyId, exceptions) {

        const url = urljoin(AppSettings.urls.policy, '/policy/saveexceptions');

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            PolicyId: policyId,
            Exceptions: exceptions
        };

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error performing name check', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }
    async deletePolicyException(policyId, exception) {

        const url = urljoin(AppSettings.urls.policy, '/policy/deleteexception');

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            PolicyId: policyId,
            Exception: exception
        };

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error performing name check', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async loadEventLogs(policyId) {
        const url = urljoin(AppSettings.urls.policy, '/policy/loadEventLogs');

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            PolicyId: policyId
        };

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error loading event logs', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async loadQuestionary(transactionType, propertyType, isEnrolledInNHP, nhpCode) {
        const url = urljoin(AppSettings.urls.policy, '/policy/loadquestionary');

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            QuestionaryId: 'residential',
            QuestionaryVersion: 1,
            Property: {
                TransactionType: transactionType,
                PropertyType: propertyType,
                IsEnrolledInNHP: isEnrolledInNHP,
                NHPCode: nhpCode
            }
        };

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error loading questionary', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    //#region Endorsements

    async searchPolicyEndorsement(searchText) {

        const url = urljoin(AppSettings.urls.policy, '/policy/searchendorsement');

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            SearchCriteria: searchText
        };

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error searching endosement', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async savePolicyEndorsements(policyId, endorsements) {

        const url = urljoin(AppSettings.urls.policy, '/policy/saveendorsements');

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            PolicyId: policyId,
            Endorsements: endorsements
        };

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error saving endorsements', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async deletePolicyEndorsement(policyId, endorsement) {

        const url = urljoin(AppSettings.urls.policy, '/policy/deleteendorsement');

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            PolicyId: policyId,
            Endorsement: endorsement
        };

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error saving endorsement', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    //#endregion Endorsements

    //#region Insure Over

    async searchInsureOver(searchText) {

        const url = urljoin(AppSettings.urls.policy, '/policy/searchinsureover');

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            SearchCriteria: searchText
        };

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error searching insure over', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async saveInsureOvers(policyId, insureOvers) {

        const url = urljoin(AppSettings.urls.policy, '/policy/saveinsureovers');

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            PolicyId: policyId,
            InsureOvers: insureOvers
        };

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error saving insure overs', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async deleteInsureOver(policyId, insureOver) {

        const url = urljoin(AppSettings.urls.policy, '/policy/deleteinsureover');

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            PolicyId: policyId,
            InsureOver: insureOver
        };

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error saving insure over', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    //#endregion Insure Over

    //#region New Home Program

    async searchProject(searchText) {
        const url = urljoin(AppSettings.urls.policy, '/project/searchproject');

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            SearchCriteria: searchText
        };

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error searching projects', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    async loadProjectProperty(nhpCode) {
        const url = urljoin(AppSettings.urls.policy, '/project/loadprojectproperty');

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            NHPCode: nhpCode
        };

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            this.logger.error('error loading property info by project', ex);

            const errMsg = AppErrors.generic;

            const apiResp = new ApiResponse(true);
            apiResp.error = errMsg;

            return apiResp;
        }
    }

    //#endregion New Home Program

    //#region Premium

    async savePremium(po, premium) {
        if (StringUtil.isNullOrEmpty(po.SecureId))
            return;

        const url = urljoin(AppSettings.urls.policy, `/policy/savepremium`);

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            PolicyOrder: {
                ...po,
                Premium: { ...premium }
            }
        };

        let apiResp;

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    ...authHeaders
                }
            });

            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error saving premium', ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }
    //#endregion Premium
}

const instance = new PolicyApiClient();

export { instance as PolicyApiClient };
