import React, { useMemo } from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import Fact from 'Components/Fact';
import { Question, SectionHeader } from 'Components/Questionary';
import * as QuestionIds from 'Components/Questionary/QuestionIds';
import Conditional from 'Policy/Conditional';
import { AdminApiClient } from 'Admin/AdminApiClient';
import { ValidationHelper } from 'Util/ValidationHelper';
import { StringUtil } from 'Util/Helpers';

function inputValueSelector() {
    return createSelector(
        state => state,
        (_, fieldName) => fieldName,
        (x, fieldName) => x[fieldName] // case sensitive! ...
    );
}

function SolicitorQ(props) {
    const { questionParams, getInitialAnswer } = props;

    const selectInputVal = useMemo(
        inputValueSelector,
        []
    );

    let prov = useSelector(x => selectInputVal(x.order.orderChanges, QuestionIds.Province));

    if (!prov && getInitialAnswer) {
        prov = getInitialAnswer(QuestionIds.Province);
    }

    async function lookupLawyers(searchCriteria) {
        if (StringUtil.isNullOrEmpty(searchCriteria)) {
            return [];
        }

        if (searchCriteria.length < 3) {
            return [];
        }

        const resp = await AdminApiClient.searchLawyer(prov , searchCriteria);

        if (resp.hasError)
            return [];

        if(ValidationHelper.hasErrors(resp))
            return [];

        const respData = resp.data;

        const opts = respData.Found;

        return opts;
    }

    return <Question qId="Solicitor" {...questionParams} short onSearchEventHandler={lookupLawyers} />;
}

function TransactionSection(props) {
    const { questionary, questionParams, getInitialAnswer } = props;

    return (
        <React.Fragment>
            <SectionHeader section="getStarted" title="Transaction" questionary={questionary} />
            <Question qId={QuestionIds.Province} {...questionParams} />
            <SolicitorQ {...props} />
            <Question qId="Contact" {...questionParams} short />
            <Question qId="ClientFileNumber" {...questionParams} short />
            <Question qId="ClosingDate" {...questionParams} />
            <Question qId="DeclinedByOther" {...questionParams}>
                <Conditional upon="DeclinedByOther" when={(x) => StringUtil.isEqual(x, "yes")} getInitialAnswer={getInitialAnswer}>
                    <Question qId="DeclinedByOtherReason" subQuestion {...questionParams} />
                    <Fact warning>This will prompt a manual review. Please contact TitlePLUS immediately.</Fact>
                </Conditional>
            </Question>
        </React.Fragment>
    );
}

export default TransactionSection;