import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import { Security, SecureRoute } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Typography, LinearProgress } from '@material-ui/core';
import { AuthService } from 'Services/AuthService';
import Login from 'Auth/Login';
import withUserProfile from 'Auth/withUserProfile';
import EagerLoader from 'State/EagerLoader';
import CustomLoginCallback from 'Auth/CustomLoginCallback';

import PolicyView from 'Policy/PolicyView';
const DealsListPage = lazy(() => import('Admin/DealsListPage'));

const UserConfirmEmail = lazy(() => import('Auth/UserConfirmEmail'));
const SignUp = lazy(() => import('Auth/SignUp'));
const SignUpSuccess = lazy(() => import('Auth/SignUpSuccess'));
const MigrateLawPROUserSuccess = lazy(() => import('Auth/MigrateLawPROUserSuccess'));
const WelcomeLawPROFriend = lazy(() => import('Auth/WelcomeLawPROFriend'));
const Register = lazy(() => import('User/Register'));
const RegisterSuccess = lazy(() => import('User/RegisterSuccess'));

const UserProfileAssociations = lazy(() => import('User/Profile/Associations'));

function LoadingRoute(props) {
    return (
        <div>
            <LinearProgress />
            <Typography align="center">Loading...</Typography>
        </div>
    );
}

const ProtectedRoute = withUserProfile(SecureRoute);
// const ProtectedRoute = SecureRoute;
// const ProtectedRoute = withUserProfile(Route); // IGOR IE 11 FOR HENRY

function Routes() {
    const hist = useHistory();

    function onAuthRequiredHandler(oktaAuth) {
        AuthService.onPreLogin();
        
        hist.push('/login');

        return Promise.resolve();
    }

    function restoreOriginalUri(oktaAuth, originalUri) {
        const newUri = toRelativeUrl(originalUri, window.location.origin);
        
        hist.replace(newUri);
        
        return Promise.resolve();
    }

    return (
        <Security oktaAuth={AuthService.authClient} onAuthRequired={onAuthRequiredHandler} restoreOriginalUri={restoreOriginalUri} >
            <EagerLoader />
            <Suspense fallback={<LoadingRoute />}>
                <Switch>
                    <ProtectedRoute path="/" exact component={DealsListPage} />
                    <ProtectedRoute path="/admin/deals" exact component={DealsListPage} />
                    <ProtectedRoute path="/policy" component={PolicyView} />
                    <ProtectedRoute path="/user/register" component={Register} />
                    <ProtectedRoute path="/user/register-success" component={RegisterSuccess} />
                    <ProtectedRoute path="/user/profile/associations" component={UserProfileAssociations} />
                    <Route path="/login" component={Login} />
                    <Route path="/confirm-email" component={UserConfirmEmail} />
                    <Route path='/implicit/callback' component={CustomLoginCallback} />
                    <Route path="/signup" component={SignUp} />
                    <Route path="/signup-success" component={SignUpSuccess} />
                    <Route path="/welcome-lawpro-friend" component={WelcomeLawPROFriend} />
                    <Route path="/migrate-lawpro-success" component={MigrateLawPROUserSuccess} />
                </Switch>
            </Suspense>
        </Security>
    );
}

function RouterSetup(props) {
    return (
        <Router>
            <Routes />
        </Router>
    );
}

export default RouterSetup;