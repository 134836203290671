import { cloneDeep, isEmpty } from 'lodash';
import * as PolicyThunks from 'Policy/State/PolicyThunks';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

function createLoadProjectPropertyEventSelector() {
    return createSelector(
        state => state.order.loadProjectPropertyEvent,
        x => x
    );
}

function ProjectPropertyReloader(props) {
    const selectLoadProjectPropertyEvent = useMemo(
        createLoadProjectPropertyEventSelector,
        []
    );

    const loadProjectPropertyEvent = useSelector(x => selectLoadProjectPropertyEvent(x));

    const dispatch = useDispatch();

    useEffect(() => {
        let isCancelled = false;

        function onUnload() {
            isCancelled = true;
        }

        if (loadProjectPropertyEvent && !isEmpty(loadProjectPropertyEvent)) {
            const ev = cloneDeep(loadProjectPropertyEvent);

            dispatch(PolicyThunks.loadProjectProperty(ev));
        }

        return onUnload;
    },
        [loadProjectPropertyEvent]
    );

    return null;
}

export default ProjectPropertyReloader;