import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormFieldHelper } from './Form/FormFieldHelper';

// igor -- this is not pure as it modifies props. bad igor.

function withFieldStateBindings(Component) {
    function ComponentExtended(props) {
        const { forwardedRef, fieldStateResolver, ...passThroughProps } = props;

        let fieldStateResolverFunc = fieldStateResolver;

        if (!fieldStateResolver || !passThroughProps.name) {
            fieldStateResolverFunc = FormFieldHelper.nullFieldStateResolver;
        }

        const selectFieldState = useMemo(
            FormFieldHelper.createFieldStateSelector_WithLowerCasing,
            []
        );

        const fieldState = useSelector(x => selectFieldState(fieldStateResolverFunc(x), passThroughProps.name));

        if (fieldState) {
            passThroughProps.error = fieldState.error;
            passThroughProps.helperText = fieldState.helperText;
        }

        return <Component ref={forwardedRef} {...passThroughProps} />;
    }

    function forwardRefBody(props, ref) {
        return <ComponentExtended {...props} forwardedRef={ref} />;
    }

    const name = Component.displayName || Component.name;
    forwardRefBody.displayName = `${withFieldStateBindings.name}(${name})`;

    return React.forwardRef(forwardRefBody);
}

export default withFieldStateBindings;