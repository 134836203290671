import StringUtil from "./StringUtil";

class CurrencyUtil {
    formatter_enCA;
    currFormatter;

    constructor() {
        this.formatter_enCA = new Intl.NumberFormat('en-CA', {
            style: 'currency',
            currency: 'CAD',
            minimumFractionDigits: 2
        });

        this.currFormatter = this.formatter_enCA; // TODO: handle locale
    }

    format(value) {
        if(StringUtil.isNullOrEmpty(value)) {
            return '';
        }
        
        const result = this.currFormatter.format(value);

        return result;
    }
}

const instance = new CurrencyUtil();

export { instance as CurrencyUtil }