import { AppSettings } from 'AppSettings';
import ApiResponse from 'Model/ApiResponse';
import * as AppErrors from 'Model/AppErrors';
import { ApiHelper } from 'Util/ApiHelper';
import { StringUtil } from 'Util/Helpers';
import Logger from 'Util/Logger';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';

const urljoin = require('url-join');

class PremiumCalculatorApiClient {
    logger;

    constructor() {
        this.logger = new Logger(PremiumCalculatorApiClient.name);
    }

    async calculatePremium(po, inputs, context) {
        for (const fieldName in inputs) {
            const answer = inputs[fieldName];

            if (!QuestionaryHelper.houseNestedAnswer(fieldName, answer, po)) {
                po[fieldName] = answer;
            }
        }

        const url = urljoin(AppSettings.urls.premiumCalculator, `/premium/calculate`);

        const authHeaders = await ApiHelper.authHeaders();

        const req = {
            PolicyOrder: po,
            Context: context
        };

        let apiResp;

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    ...authHeaders
                }
            });

            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error saving policy', ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }
}

const instance = new PremiumCalculatorApiClient();

export { instance as PremiumCalculatorApiClient };
