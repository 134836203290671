import { FormFieldHelper } from 'Components/Form/FormFieldHelper';
import * as PremiumCalculatorThunks from 'Policy/State/PremiumCalculatorThunks';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'Util/UseDebounce';

function AutoCalculator(props) {
    const { po } = props;

    const selectOrder = useMemo(
        FormFieldHelper.createFieldStateSelector,
        []
    );

    const calculatePremiumTriggerCount = useSelector(x => selectOrder(x.order, 'premiumChanges'));

    const policyMap = useSelector(x => selectOrder(x.order, 'policyMap'));

    const debouncedCalculationByOrderChanges = useDebounce(calculatePremiumTriggerCount, 1000);

    const debouncedCalculationByPolicyMapChanges = useDebounce(policyMap, 1000);

    const dispatch = useDispatch();

    // silent calculation
    useEffect(() => {
        let isCancelled = false;

        function onUnload() {
            isCancelled = true;
        }

        if (debouncedCalculationByOrderChanges) {
            dispatch(PremiumCalculatorThunks.calculatePremium(po));
        }

        return onUnload;
    }, [debouncedCalculationByOrderChanges]);

    useEffect(() => {
        let isCancelled = false;

        function onUnload() {
            isCancelled = true;
        }

        if (debouncedCalculationByPolicyMapChanges) {
            dispatch(PremiumCalculatorThunks.calculatePremium(po));
        }

        return onUnload;
    }, [debouncedCalculationByPolicyMapChanges]);

    return null;
}

export default AutoCalculator;