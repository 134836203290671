import React, { Fragment, useMemo } from 'react';
import { Question } from 'Components/Questionary';
import { StringUtil } from 'Util/Helpers';
import { Box } from '@material-ui/core';
import { SectionHeader } from 'Components/Questionary';
import { useTranslation } from "react-i18next";
import { MortgageTypeEnum, TransactionTypeEnum } from 'Model/PolicyOrder';
import { MortgageHelper, PropertyHelper, PurchaserHelper } from '../PolicyOrderHelper'
import { QuestionaryHelper } from 'Util/QuestionaryHelper';
import Conditional from 'Policy/Conditional';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

function NoticeCorporationOther(props) {
    const { item, questionParams, collectionName } = props;

    questionParams.indexer = QuestionaryHelper.createIndexer(collectionName, item);

    return <React.Fragment>
        <Question qId="NoticeCorporationOther" optional subQuestion {...questionParams} />
        <Box mt={2}></Box>
        <Question qId="CorporateProfileDoc" topBuffer optional {...questionParams} />
    </React.Fragment>;
}

function inputValueSelector() {
    return createSelector(
        state => state,
        (_, fieldName) => fieldName,
        (x, fieldName) => x[fieldName] // case sensitive! ...
    );
}

function PropertyIdentityFraud(props) {
    const { item, questionParams, collectionName, questionary, conditionalProps } = props;

    questionParams.indexer = QuestionaryHelper.createIndexer(collectionName, item);

    const selectInputVal = useMemo(
        inputValueSelector,
        []
    );

    let addExisitingOwner = useSelector(x => selectInputVal(x.order.orderChanges, `${questionParams.indexer}${'AddExistingOwnerPolicy'}`));

    if (StringUtil.isNullEmptyOrWhiteSpace(addExisitingOwner))
        addExisitingOwner = item['AddExistingOwnerPolicy'];

    let transactionType = useSelector(x => selectInputVal(x.order.orderChanges, `${questionParams.indexer}${'TransactionType'}`));

    if (StringUtil.isNullEmptyOrWhiteSpace(transactionType))
        transactionType = item['TransactionType'];

    return (<Fragment>
        {(StringUtil.isYes(addExisitingOwner) || StringUtil.isEqual(transactionType, TransactionTypeEnum.ExistingOwner)) && <Fragment>
            <Question qId="PropertyFraud" {...questionParams} />
            <Question qId="IdentityFraud" {...questionParams} />
        </Fragment>
        }
    </Fragment>);
}

function Fraud(props) {
    const { item, questionParams, collectionName, questionary, conditionalProps } = props;

    const { t } = useTranslation();

    questionParams.indexer = QuestionaryHelper.createIndexer(collectionName, item);

    const selectInputVal = useMemo(
        inputValueSelector,
        []
    );
    
    const showFraudSection = useSelector(x => selectInputVal(x.order.orderChanges, `${questionParams.indexer}ShowFraudSection`));

    if (!showFraudSection)
        return null;

    return (
        <Fragment>
            <SectionHeader section="fraud" title={t('fraud_prevention')} questionary={questionary} subSection />

            <Conditional upon="HasPvtLenderMortgage" when={(x) => x == true} {...conditionalProps}>
                <Question qId="PowerOfAttorney" {...questionParams}>
                    <Conditional upon="PowerOfAttorney" when={(x) => StringUtil.isYes(x)} {...conditionalProps}>
                        <Question qId="PowerOfAttorneyDocs" optional {...questionParams} />
                    </Conditional>
                </Question>
            </Conditional>

            <Conditional upon="HasPvtLenderMortgage" when={(x) => x == true} {...conditionalProps}>
                <Fragment>
                    <Question qId="DepositPaidToVendor" {...questionParams} />
                    <Question qId="PriceReduction" {...questionParams} />
                    <Conditional upon="HasCorporatePurchaser" when={(x) => x == true} {...conditionalProps}>
                        <Conditional upon="TotalMortgageAmount" when={(x) => x >= 100000} {...conditionalProps}>
                            <Question qId="KnownCoropration" {...questionParams} />
                        </Conditional>
                    </Conditional>

                    <Conditional upon="KnownCoropration" when={(x) => StringUtil.isNo(x)} {...conditionalProps}>
                        <Question qId="NoticeCorporation" {...questionParams}>
                            {/* <Conditional upon="NoticeCorporation" when={(x) => StringUtil.isNo(x)} {...conditionalProps}>
                                <Conditional upon="TransactionType" when={(x) => !StringUtil.isEqual(x, TransactionTypeEnum.MortgageOnly)} {...conditionalProps}>
                                    <NoticeCorporationOther {...props} />
                                </Conditional>
                            </Conditional> */}

                            <Conditional upon="NoticeCorporation" when={(x) => StringUtil.isYes(x)} {...conditionalProps}>
                                {/* <Conditional upon="TransactionType" when={(x) => StringUtil.isEqual(x, TransactionTypeEnum.MortgageOnly)} {...conditionalProps}> */}
                                    <NoticeCorporationOther {...props} />
                                {/* </Conditional> */}
                            </Conditional>
                        </Question>
                    </Conditional>
                </Fragment>
            </Conditional>

            <Conditional upon="HasMortgageClosingPriorityOfLessThan3" when={(x) => x == true} {...conditionalProps}>
                <Question qId="MortgageStatement" {...questionParams} />
            </Conditional>

            <Conditional upon="HasPvtLenderMortgage" when={(x) => x == true} {...conditionalProps}>
                <Question qId="MortgageProceeds" {...questionParams}>
                    <Conditional upon="MortgageProceeds" when={(x) => StringUtil.isNo(x)} {...conditionalProps}>
                        <Question qId="NonApprovedAmount" {...questionParams} />
                    </Conditional>
                </Question>
            </Conditional>


            <Conditional upon="TotalPvtMortgageAmount" when={(x) => x > 99999} {...conditionalProps}>
                <Question qId="BorrowerLawyerName" {...questionParams} />
            </Conditional>

            <Question qId="RepresentedByRealEstateAgent" {...questionParams} />

            <Conditional upon="RepresentedByRealEstateAgent" when={(x) => StringUtil.isNo(x)} {...conditionalProps}>
                <Question qId="KnownPurchaser" {...questionParams}>
                    <Conditional upon="KnownPurchaser" when={(x) => StringUtil.isNo(x)} {...conditionalProps}>
                        <Question qId="TransferType" {...questionParams} />
                    </Conditional>
                </Question>
            </Conditional>

            <Conditional upon="TotalPvtMortgageAmount" when={(x) => x > 99999} {...conditionalProps}>
                <Question qId="IsMortgageFree" {...questionParams} />
            </Conditional>

            <Question qId="TransferOrDischargeLast6Month" {...questionParams} />

            <Conditional upon="TransferOrDischargeLast6Month" when={(x) => StringUtil.isEither(x, 'transfers', 'transfers_and_discharges')} {...conditionalProps}>
                <Question qId="TransferLast6Month" {...questionParams} />
            </Conditional>

            <Conditional upon="TransferOrDischargeLast6Month" when={(x) => StringUtil.isEither(x, 'discharges', 'transfers_and_discharges')} {...conditionalProps}>
                <Question qId="DischargeLast6Month" {...questionParams} />
            </Conditional>

            <PropertyIdentityFraud {...props} />
        </Fragment>
    );
}

export default Fraud;