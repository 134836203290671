import { FormLabel, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({

}));


function Label(props) {
    const { defaultValue } = props;

    return (
        <Typography>
            {defaultValue}
        </Typography>
    );
}

export default Label;