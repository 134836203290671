import React from 'react';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import './App.css';
import Logger from 'Util/Logger';
//import RouterSetup from 'Startup/RouterSetup';
import RouterSetup from 'Startup/RouterSetupOkta';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import EagerLoader from 'State/EagerLoader';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#165c7d' /* Pantone 7700 C*/
    }
  },
  typography: {
    fontSize: 12,
    caption: {
      fontSize: '0.8rem'
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1em"
      }
    }
  }
});

const logger = new Logger(App.name);

function App(children) {
  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Container maxWidth="xl">
          <CssBaseline />
          <RouterSetup />
          <EagerLoader />
        </Container>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

export default App;