import { AppSettings } from 'AppSettings';
import * as AppErrors from 'Model/AppErrors';
import { ApiHelper } from 'Util/ApiHelper';
import { StringUtil } from 'Util/Helpers';
import Logger from 'Util/Logger';
import ApiResponse from 'Model/ApiResponse';

const urljoin = require('url-join');

function sleep(milliseconds)
{
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

class AuthApiClient {
    logger;

    constructor() {
        this.logger = new Logger(AuthApiClient.name);
    }

    async createUser(inputs) {
        const req = {};
        const userInfo = {};

        for (const fieldName in inputs) {
            const answer = inputs[fieldName];

            userInfo[fieldName] = answer;
        }

        req.UserInfo = userInfo;

        const url = urljoin(AppSettings.urls.policy, '/user/add');

        let apiResp;

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST"
            });

            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error creating new user', ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }

    async loadUserProfile() {
        const url = urljoin(AppSettings.urls.policy, '/user/getProfile');

        let apiResp;

        const authHeaders = await ApiHelper.authHeaders();

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    ...authHeaders
                }
            });
            
            const errorApiResp = await ApiHelper.httpRespToErrorApiResp(response);

            if (errorApiResp != null) {
                return errorApiResp;
            }

            apiResp = new ApiResponse(false);
            apiResp.data = await response.json();
            
        } catch (ex) {
            this.logger.error('error getting user profile', ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }

    async activateUser(userId) {        
        const req = {
            UserId: userId
        };

        const url = urljoin(AppSettings.urls.policy, '/user/confirmEmail');

        let apiResp;

        try {
            const response = await fetch(url, {
                body: JSON.stringify(req),
                method: "POST"
            });

            apiResp = ApiHelper.deserializeJsonResponse(response);
        } catch (ex) {
            this.logger.error('error activating user', ex);

            const errMsg = AppErrors.generic;

            apiResp = new ApiResponse(true);
            apiResp.error = errMsg;
        }

        return apiResp;
    }
}

const instance = new AuthApiClient();

export { instance as AuthApiClient } 