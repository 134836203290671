/*
IGOR - Not using Okta built-in LoginCallback component as it has issues with IE 11
// https://github.com/okta/okta-auth-js/blob/master/lib/token.ts
// the issue happens in PKCE.loadMeta(sdk) in parseFromUrl() method

*/
import React, { useEffect, useReducer } from 'react';
import { AuthService } from 'Services/AuthService';
import Logger from 'Util/Logger';
import { StringUtil, WebStorageHelper } from "Util/Helpers";
import ErrorSnackbar from 'Components/ErrorSnackbar';
import { Typography, Box, Button } from '@material-ui/core';
import { useOktaAuth } from '@okta/okta-react';
import * as AppErrors from 'Model/AppErrors';
import { useHistory } from 'react-router';

const logger = new Logger(CustomLoginCallback.name);

function CustomLoginCallback(props) {
    const { authState } = useOktaAuth();

    const hist = useHistory();

    const searchParams = new URLSearchParams(window.location.search);
    const error = searchParams.get("error");
    const errorDescription = searchParams.get("errorDescription");
    const code = searchParams.get("code");
    const state = searchParams.get("state");

    const [initLoadData, setInitLoadData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            loading: true,
            callbackError: ''
        }
    );

    useEffect(() => {
        let isCancelled = false;

        function onUnload() {
            isCancelled = true;
        }

        async function exeucteCallback() {
            if (authState.isAuthenticated) {
                logger.debug('already authenticated');

                if (!isCancelled) {
                    setInitLoadData({ loading: false });
                }

                return;
            }

            logger.debug('executing callback ...');

            const authClient = AuthService.authClient;

            if (!authClient.token.isLoginRedirect() && !authState.isAuthenticated) {
                logger.error("isLoginRedirect() is false, isAuthenticated is false");

                if (!isCancelled) {
                    setInitLoadData({ loading: false, callbackError: 'User authentication error' });
                }

                return;
            }

            try {
                /*
                let pkceJson = WebStorageHelper.getItem(null, 'okta-pkce-storage');

                // alert(pkceJson);

                if(StringUtil.isNullOrEmpty(pkceJson) || StringUtil.isEqual(pkceJson, '{}'))
                {
                    logger.debug("empty pkce json from sessionStorage");
                    
                    pkceJson = WebStorageHelper.getItemFromDisk(null, 'okta-pkce-storage');

                    alert(pkceJson);
                }
                */

                const resp = await authClient.token.parseFromUrl();

                // alert('hoy');

                authClient.tokenManager.setTokens(resp.tokens);

                // alert('hoy 2');

                await authClient.handleLoginRedirect(resp.tokens);

                if (!isCancelled) {
                    setInitLoadData({ loading: false });
                }
            }
            catch (err) {
                if (StringUtil.isEither(err.name, 'AuthSdkError', 'OAuthError')) {
                    const errorSummary = err.errorSummary;

                    logger.error(`${err.name}: ${err.message}`, err);

                    if (StringUtil.startsWith(errorSummary, 'User is not assigned to the client application')) {
                        hist.replace('/welcome-lawpro-friend');
                    }

                    if (!isCancelled) {
                        setInitLoadData({ loading: false, callbackError: err.message });
                    }
                }
            }
        }

        exeucteCallback();

        return onUnload;

    }, []);

    /*
    if (StringUtil.isEqual(error, "access_denied")) {
        return <NotSignedIn />
    }
    */

    if (initLoadData.loading) {
        return (
            <Typography>Logging in...</Typography>
        );
    }

    if (!StringUtil.isNullOrEmpty(error) || !StringUtil.isNullOrEmpty(initLoadData.callbackError)) {
        return (
            <div style={{ marginTop: '0.5em' }}>
                <ErrorSnackbar variant="error" message={error || initLoadData.callbackError} centered={true} errorCode={AppErrors.code26} />
                <Box mt={1} display="flex" flexDirection="row-reverse">
                    <Button
                        variant="contained"
                        onClick={async () => { await AuthService.authClient.signOut() }}
                    >
                        Logout
                    </Button>
                </Box>
            </div>
        );
    }

    return (
        <Typography>Logging in...</Typography>
    );
}

export default CustomLoginCallback;