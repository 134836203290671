import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, FormControl, FormHelperText } from '@material-ui/core';
import TPUsersDL from './Filters/TPUsersDL';
import { StringUtil, UserHelper } from 'Util/Helpers';
import { AdminApiClient } from './AdminApiClient';
import { ValidationHelper } from 'Util/ValidationHelper';
import { pick } from 'lodash';
import { useSelector } from 'react-redux';
import * as UserPermissions from 'Model/UserPermisions';
import * as UserActions from 'User/State/UserActions';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    title: {
        fontWeight: 500
    },
    formControl: {
        width: '100%'
    }
}));

export default function OwnerInfo(props) {
    const classes = useStyles();

    const {
        po,
        setActionMsgType,
        setActionMsg,
        setWorking
    } = props;

    let { readOnly } = props;

    const secureId = po.SecureId;

    const [validationMsg, setValidationMsg] = useState('');

    const selectUserInfo = useMemo(
        UserActions.createUserInfoSelector,
        []
    );
    
    const userInfo =  useSelector(x => selectUserInfo(x));

    const canChangeOwnership = UserHelper.hasPermission(userInfo, UserPermissions.change_ownership);

    readOnly = readOnly || !canChangeOwnership;

    async function handleOwnerChange(event, itemsArr) {
        const newOwner = event.target.value;

        setWorking(true);
        setValidationMsg('');

        let userInfo = {};

        if (!StringUtil.isNullOrEmpty(itemsArr) && itemsArr.length > 0) {
            const found = itemsArr.find(i => StringUtil.isEqual(i.SecureId, newOwner));
            const slim = pick(found, ['SecureId', 'FirstName', 'LastName']);
            userInfo = slim;
        }

        const resp = await AdminApiClient.changeOwner(secureId, userInfo);

        setWorking(false);

        if (resp.hasError) {
            setActionMsgType('error');
            setActionMsg(resp.errorMessage);

            if (!resp.hasValidationResult) {
                return;
            }
        }

        const respData = resp.data;
        const mirroredBack = respData.Owner;

        const validationMap = ValidationHelper.getValidationMap(respData);

        if (!StringUtil.isNullOrEmpty(validationMap)) {
            return; // we hit a validation error -- TODO, set validations msg
        }

        setActionMsgType(respData.Status);
        setActionMsg(respData.StatusDescription);

        return mirroredBack;
    }

    async function handleContributorsChange(event, itemsArr) {
        const newContributors = event.target.value;

        setWorking(true);
        setValidationMsg('');

        let userInfoArr = [];

        if (!StringUtil.isNullOrEmpty(itemsArr) && itemsArr.length > 0) {
            for (const c of newContributors) {
                const found = itemsArr.find(i => StringUtil.isEqual(i.SecureId, c));
                const slim = pick(found, ['SecureId', 'FirstName', 'LastName']);
                userInfoArr.push(slim);
            }
        }

        const resp = await AdminApiClient.changeContributors(secureId, userInfoArr);

        setWorking(false);

        if (resp.hasError) {
            setActionMsgType('error');
            setActionMsg(resp.errorMessage);

            if (!resp.hasValidationResult) {
                return;
            }
        }

        const respData = resp.data;
        const mirroredBack = respData.Contributors;

        const validationMap = ValidationHelper.getValidationMap(respData);

        if (!StringUtil.isNullOrEmpty(validationMap)) {
            return; // we hit a validation error -- TODO, set validations msg
        }

        setActionMsgType(respData.Status);
        setActionMsg(respData.StatusDescription);

        return mirroredBack;
    }

    let owner;
    let contributors;

    if (!StringUtil.isNullOrEmpty(po.Owner)) {
        owner = po.Owner.SecureId;
    }

    if (!StringUtil.isNullOrEmpty(po.Contributors) && po.Contributors.length > 0) {
        contributors = po.Contributors.map(x => { return x.SecureId });
    }

    return (
        <React.Fragment>
            <Box p={0} m={0} mb={1} className={classes.root}>
                <Typography variant="subtitle1" className={classes.title}>
                    Owner:
            </Typography>
                <FormControl className={classes.formControl} error={!StringUtil.isNullOrEmpty(validationMsg)}>
                    <TPUsersDL hideClear={true} hideLabel={true} name="Owner" readOnly={readOnly} onChange={handleOwnerChange} revertOnError={true} eagerLoad single initialValue={owner} />
                    <FormHelperText>{validationMsg}</FormHelperText>
                </FormControl>
            </Box>
            <Box p={0} m={0} mb={1} className={classes.root}>
                <Typography variant="subtitle1" className={classes.title}>
                    Contributors:
              </Typography>
                <FormControl className={classes.formControl} error={!StringUtil.isNullOrEmpty(validationMsg)}>
                    <TPUsersDL hideLabel={true} name="Contributors" readOnly={readOnly} onChange={handleContributorsChange} revertOnError={true} eagerLoad initialValue={contributors} />
                    <FormHelperText>{validationMsg}</FormHelperText>
                </FormControl>
            </Box>
        </React.Fragment>
    );
}