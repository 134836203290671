import * as PolicyActions from './PolicyActions';
import { DocumentsApiClient } from 'Policy/DocumentsApiClient';
import Logger from 'Util/Logger';
import BrowserHelper from 'Util/BrowserHelper';

const logger = new Logger("DocumentThunks");

export function generatePolicyDocument(orderSecureId, policyNumber) {
    return async function (dispatch, getState) {
        const currState = getState();

        dispatch({ type: PolicyActions.generatingPDF.type, payload: { orderSecureId, policyNumber } });

        const resp = await DocumentsApiClient.generatePolicyDocument(orderSecureId, policyNumber);

        dispatch({ type: PolicyActions.generatingPDF.type, payload: {} }); // not generating ...

        if (resp.hasError) {
            dispatch({ type: PolicyActions.setActionMessage.type, payload: { type: 'error', message: resp.errorMessage } });

            return;
        }

        const respData = resp.data;

        const file = new Blob([respData], { type: 'application/pdf' });

        if(BrowserHelper.isIE11()) {
            // this will auto-download the file. Only thing we can do on IE 11...
            
            window.navigator.msSaveOrOpenBlob(file, `${policyNumber}.pdf`);

            return;
        }

        const dataUrl = window.URL.createObjectURL(file); // WE MUST DISPOSE IT WHEN WE CLOSE THE DOC to release memory

        dispatch({ type: PolicyActions.viewGeneratedPDF.type, payload: { orderSecureId, policyNumber, dataUrl } });
    }
}