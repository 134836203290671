import { StringUtil } from 'Util/Helpers';

class ApiResponse
{
    error = '';
    hasValidationResult = false;
    hasValidationErrors = false;
    hasValidationWarnings = false;
    hasValidationInfo = false;
    genericValidationMessage = '';
    data = null;
    notAuthenticated = false;
    notAuthorized = false;
    _hasError = false;

    constructor(vHasError = false) {
        this._hasError = vHasError;
    }

    get hasError()
    {
        return this._hasError || !StringUtil.isNullOrEmpty(this.error) || !StringUtil.isNullOrEmpty(this.genericValidationMessage);
    }

    get errorMessage()
    {
        if(!StringUtil.isNullOrEmpty(this.error))
            return this.error;

        if(!StringUtil.isNullOrEmpty(this.genericValidationMessage))
            return this.genericValidationMessage;

        return '';
    }
}

export default ApiResponse;