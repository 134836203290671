import { createAction } from '@reduxjs/toolkit';

export const set_new_order = createAction('order/set_new');

export const add_property = createAction('order/add_property');
export const remove_property = createAction('order/remove_property');

export const add_purchaser = createAction('order/add_purchaser');
export const remove_purchaser = createAction('order/remove_purchaser');

export const add_mortgage = createAction('order/add_mortgage');
export const remove_mortgage = createAction('order/remove_mortgage');

export const changeOrder = createAction('order/change');
export const shadowUpdateOrder = createAction('order/shadow_update_order');

export const setValidationResult = createAction('order/setValidationResult');
export const clearValidationError = createAction('order/clearValidationError');

export const setIsSaving = createAction('order/setIsSaving');
export const setJustSaved = createAction('order/setJustSaved');
export const setOrderVolatileData = createAction('order/setOrderVolatileData');
export const setPolicyMap = createAction('order/setPolicyMap');
export const setActionMessage = createAction('order/setActionMessage');
export const clearActionMessage = createAction('order/clearActionMessage');

export const add_remaining_mortgage = createAction('order/add_remaining_mortgage');
export const remove_remaining_mortgage = createAction('order/remove_remaining_mortgage');

export const add_nph_condo_legal_description = createAction('order/add_nph_condo_legal_description');
export const remove_nph_condo_legal_description = createAction('order/remove_nph_condo_legal_description');


export const setQuestionary = createAction('order/setQuestionary');

export const generatingPDF = createAction('order/generatingPDF');
export const viewGeneratedPDF = createAction('order/viewGeneratedPDF');
export const setIsCalculatingPremium = createAction('order/setIsCalculatingPremium');
export const setPremium = createAction('order/setPremium');