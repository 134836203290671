import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StringUtil } from 'Util/Helpers';
import Typography from '@material-ui/core/Typography';
import { Grid, Tooltip, Box, Icon } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';
import RequiredInd from '../RequiredInd';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles(theme => ({
    questionTitle: {
        fontWeight: 500,
        fontSize: theme.fontSize
    },
    questionTitleMultiPart: {
        color: grey[700]
    },
    questionInfo: {
        verticalAlign: "middle",
        marginBottom: 3,
        color: theme.palette.info.main
    },
    iconRoot: {
        marginBottom: -25,
        left: -35,
        position: 'relative',
        float: 'left',
        overflow: 'visible'
    },
    imageIcon: {
        height: 28
    }
}));

function PossibilitiesList(props) {
    const { q } = props;

    const possibilities = q.Possibilities;

    if (StringUtil.isNullOrEmpty(possibilities) || possibilities.length === 0) {
        return null;
    }

    return (
        <ul>
            {possibilities.map((p, index) =>
                <li key={index}><Typography>{p}</Typography></li>
            )}
        </ul>
    )
}

function QuestionAside(props) {
    const { q } = props;

    const aside = q.Aside;

    if (StringUtil.isNullOrEmpty(aside)) {
        return null;
    }

    return (
        <Box ml={1.5} mr={1.5} pt={1} pb={1} pl={1.5} pr={1.5} border={1} borderColor="grey.400" borderRadius={5}>
            <Typography variant="body2" component="pre" style={{ whiteSpace: 'pre-wrap' }}>{aside}</Typography>
        </Box>
    )
}

function QuestionTitle(props) {
    const classes = useStyles();

    const { q, isReviewRequired, indexer } = props;

    if (q == null || !StringUtil.isNullOrEmpty(q.SupplementaryTo)) {
        return null;
    }

    let qTitle = null;
    const qTitleClass = classes.questionTitle;

    if (StringUtil.isNullOrEmpty(q.InfoHint)) {
        qTitle = (
            <Typography className={qTitleClass}>
                {q.Text}
                {!q.Optional && <RequiredInd />}
            </Typography>);
    }
    else {
        qTitle = (
            <Typography className={qTitleClass}>
                {q.Text}{!q.Optional && <RequiredInd />}&nbsp;
                <Tooltip title={q.InfoHint} aria-label={q.InfoHint}>
                    <InfoIcon className={classes.questionInfo} />
                </Tooltip>
            </Typography>
        );

    }

    let qId = q.Id;

    if (!StringUtil.isNullOrEmpty(indexer))
        qId = `${indexer}${qId}`;

    let qContainerId = `${qId}_title`;

    return (
        <Grid id={qContainerId} item xs={12} style={{ paddingBottom: 0 }}>
            {isReviewRequired && isReviewRequired(qId) &&
                <Icon classes={{ root: classes.iconRoot }}>
                    <img className={classes.imageIcon} src="/img/under_review.svg" />
                </Icon>
            }
            {qTitle}
            <PossibilitiesList q={q} />
            <QuestionAside q={q} />
        </Grid>
    );
}

export default QuestionTitle;