import { StringUtil } from 'Util/Helpers';

class ValidationHelper {
    hasItems(response) {
        if (StringUtil.isNullOrEmpty(response))
            return false;

        const validationResult = response.ValidationResult;

        if (StringUtil.isNullOrEmpty(validationResult))
            return false;

        return validationResult.Errors.length > 0;
    }

    hasErrors(response) {
        if (StringUtil.isNullOrEmpty(response))
            return false;

        const validationResult = response.ValidationResult;

        if (StringUtil.isNullOrEmpty(validationResult))
            return false;

        return validationResult.HasErrors;
    }

    hasWarnings(response) {
        if (StringUtil.isNullOrEmpty(response))
            return false;

        const validationResult = response.ValidationResult;

        if (StringUtil.isNullOrEmpty(validationResult))
            return false;

        return validationResult.HasWarnings;
    }

    hasInfo(response) {
        if (StringUtil.isNullOrEmpty(response))
            return false;

        const validationResult = response.ValidationResult;

        if (StringUtil.isNullOrEmpty(validationResult))
            return false;

        return validationResult.Errors.some(item => item.Severity === 'info');
    }

    getGenericErrorMessage(response) {
        if (StringUtil.isNullOrEmpty(response))
            return '';

        const validationResult = response.ValidationResult;

        if (StringUtil.isNullOrEmpty(validationResult))
            return '';

        let errMsg = '';

        for (const e of validationResult.Errors) {
            if (e.FieldName === 'generic-error') {
                errMsg = e.ErrorMessage;

                break;
            }
        }

        return errMsg;
    }

    getValidationMap(response) {
        if (!this.hasItems(response))
            return null;

        const map = {};

        for (const e of response.ValidationResult.Errors) {
            map[e.FieldName.toLowerCase()] = e;

            if(e && StringUtil.isEqual(e.Severity, "error")) {
                e.IsError = true;
            }
        }

        return map;
    }

    scrollToFirstError(response) {
        if(!this.hasErrors(response)) {
            return;
        }

        const item = document.querySelector("[class*=Mui-error], [class*=makeStyles-error]"); // this uses DOM and assumes controls have rendered ...

        if(!StringUtil.isNullOrEmpty(item)) {
          item.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }
}

const instance = new ValidationHelper();

export { instance as ValidationHelper }