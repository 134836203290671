import React, { useState } from 'react';
import YAML from 'yaml'
import { Icon, makeStyles, Typography, Button, Collapse, Box } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';
import { StringUtil } from 'Util/Helpers';
import { BackgroundCheckTypeEnum } from 'Model/BackgroundCheck';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
    iconRoot: {
        marginBottom: -25,
        left: -50,
        position: 'relative',
        float: 'left',
        overflow: 'visible',
        top: -20
    },
    imageIcon: {
        height: 28
    },
    questionTitle: {
        fontWeight: 500
    },
    inputLabel: {
        color: theme.palette.text.primary,
        "&.shrink": {
            transform: "translate(14px, -10px) scale(1)"
        },
    },
    button: {
        marginBottom: -35,
        position: 'relative',
        float: 'right',
        zIndex: 1,
        top: -26
    }
}));

function BackgroundCheck(props) {
    const {
        type,
        text,
        po,
        executeCheck
    } = props;

    const classes = useStyles();

    const [working, setWorking] = useState(false);
    const [show, setShow] = useState(false);

    const backgroundCheck = po[type];

    if (backgroundCheck == null)
        return null;

    async function handleRetry() {
        setWorking(true);
        await executeCheck(type);
        setWorking(false);
    }

    let checkFailed = !backgroundCheck.Cleared;

    let error = backgroundCheck.Error;

    let isError = !StringUtil.isNullOrEmpty(error);

    const information = backgroundCheck.Result;

    let toDisplay = `${type} passed`;

    if (information) {
        if (Object.keys(information).length > 0)
            toDisplay = YAML.stringify(information);
    }
    if (StringUtil.isEqual(type, BackgroundCheckTypeEnum.PinCheckOnDirectory)) {
        toDisplay = backgroundCheck.PinDirectoryItems.map(x => `PIN : ${x.PIN}\n\n${x.Issues}`).join('\n\n\n');
    }
    console.log(type);
    console.log(isError);

    if (StringUtil.isEqual(type, BackgroundCheckTypeEnum.NameChecksOnSupplementalDb)) {
        if (backgroundCheck.find(x => !StringUtil.isNullOrEmpty(x.Error))) {
            isError = true;
            error = backgroundCheck.filter(x => !StringUtil.isNullOrEmpty(x.Error)).map(x => x.Error).join('\n');
        }
        else if (backgroundCheck.find(x => x.Cleared !== true)) {
            toDisplay = backgroundCheck.filter(x => x.Cleared != true).map(x => `${x.Information.queryString} matched`).join('\n');
            checkFailed = true;
        }
        else
            checkFailed = false;
    }
    if (isError)
        toDisplay = error;

    const label = <Typography className={classes.questionTitle}>
        {text}
    </Typography>;

    const retryButton =
        <Button
            startIcon={working ? <CircularProgress size={15} thickness={4} /> : <RefreshIcon />}
            className={classes.button}
            onClick={event => handleRetry()}
        >
            {working ? `Retrying` : `Retry`}
        </Button>

    const showHideButton =
        <Button
            className={classes.button}
            onClick={event => setShow(prevState => !prevState)}
        >
            {show ? `Hide` : `Show`}
        </Button>

    const wrapperProps = {
        m: 1,
        border: 1,
        width: '100%',
        paddingLeft: '15px',
        paddingTop: '10px',
        paddingBottom: '10px',
        borderColor: 'grey.400'
    };

    return <Box borderRadius="borderRadius" {...wrapperProps}>
        {/* Background Check Label */}
        <Box component="span">{label}</Box>
        {/* Review Icon */}
        {(checkFailed && !isError) &&
            <Icon classes={{ root: classes.iconRoot }}>
                <img className={classes.imageIcon} src="img/under_review.svg" />
            </Icon>
        }
        {/* Error Icon */}
        {isError && <ReportProblemRoundedIcon className={classes.iconRoot} color='error' />}
        {/* Success Icon */}
        {(!checkFailed && !isError) && <CheckCircleIcon className={classes.iconRoot} style={{ color: "green" }} />}
        {/* Retry Button */}
        {isError && retryButton}
        {/* Show/Hide Button */}
        {!isError && showHideButton}
        {/* Content */}
        <Collapse in={show} style={{ whiteSpace: "pre-wrap" }}>
            {toDisplay}
        </Collapse>
    </Box>
}

export default BackgroundCheck;