import { Box, Button, FormGroup } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Question } from 'Components/Questionary';
import React from 'react';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';

function NHPCondoLegalDescriptionInfo(props) {
    const { item, idx, canAdd, add, collectionName, totalCount, remove, questionParams } = props;

    const isFirst = idx === 0;

    questionParams.indexer = QuestionaryHelper.createIndexer(collectionName, item);

    return <FormGroup row>
        <Question qId="UnitType" {...questionParams} inline txtWidth={25} hideLabel={!isFirst} />
        <Question qId="CondoPlan" {...questionParams} inline txtWidth={25} hideLabel={!isFirst} />
        <Question qId="Unit" {...questionParams} inline txtWidth={25} hideLabel={!isFirst} />
        <Question qId="Level" {...questionParams} inline txtWidth={25} hideLabel={!isFirst} />
        <Box mt={1} mr={2} display="flex" flexDirection="row-reverse">
            {!isFirst && <Button
                type="button"
                size="small"
                startIcon={<DeleteIcon />}
                disabled={isFirst}
                onClick={() => { remove(item) }}>
                Remove
            </Button>}
            {isFirst && <Button
                type="button"
                size="small"
                startIcon={<AddIcon />}
                disabled={!canAdd}
                onClick={add}>
                Add
            </Button>}
        </Box>
    </FormGroup>;
}

export default NHPCondoLegalDescriptionInfo;