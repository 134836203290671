import { Box, TableCell, Typography } from '@material-ui/core';
import { Question } from 'Components/Questionary';
import Conditional from 'Policy/Conditional';
import React, { Fragment } from "react";
import { QuestionaryHelper } from 'Util/QuestionaryHelper';

function PurchaserInfo(props) {
    const { item, collectionName, questionParams, getInitialAnswer, propertyConditionalProps } = props;

    if (!item)
        return null;

    questionParams.indexer = QuestionaryHelper.createIndexer(collectionName, item);

    const groupName = "PurchaserName";

    return <Fragment>
        <TableCell>
            <Typography>
                Owner Policy {item.PolicyNumber}
            </Typography>
        </TableCell>
        <TableCell>
            <Box style={{ display: 'inline-flex' }}>
                <Conditional upon="ShouldDisableCorporationName" when={(x) => x === true} getInitialAnswer={getInitialAnswer} indexer={questionParams.indexer}>
                    <Question qId={`${groupName}First`} {...questionParams} displayOnly hideLabel />
                    <Question qId={`${groupName}Last`} {...questionParams} displayOnly hideLabel />
                </Conditional>
                <Conditional upon="ShouldDisableIndividualName" when={(x) => x === true} getInitialAnswer={getInitialAnswer} indexer={questionParams.indexer}>
                    <Question qId={`${groupName}Corporation`} {...questionParams} displayOnly hideLabel />
                </Conditional>
            </Box>
        </TableCell>
        <TableCell>
                <Question qId="PurchasePrice" {...propertyConditionalProps} displayOnly hideLabel />
                <Question qId="PurchasePriceInclHST" {...propertyConditionalProps} displayOnly hideLabel />
                <Question qId="PropertyValue" {...propertyConditionalProps} displayOnly hideLabel />
        </TableCell>
    </Fragment>;
}

export default PurchaserInfo;