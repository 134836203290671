import { Box, Collapse, FormGroup, IconButton, Link, Tooltip, Typography } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Fact from 'Components/Fact';
import { Question, SectionHeader } from 'Components/Questionary';
import { PropertyTypeEnum, TransactionTypeEnum } from 'Model/PolicyOrder';
import Conditional from 'Policy/Conditional';
import { PolicyApiClient } from 'Policy/PolicyApiClient';
import React, { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StringUtil } from 'Util/Helpers';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';
import { ValidationHelper } from 'Util/ValidationHelper';
import ClosingInstructions from './ClosingInstructions';
import Fraud from './Fraud';
import MortgagesSection from './MortgagesSection';
import NHPCondoLegalDescriptionSection from './NHPCondoLegalDescriptionSection';
import OffTitle from './OffTitle';
import PurchasersSection from './PurchasersSection';
import TitleSurvey from './TitleSurvey';
import { createSelector } from 'reselect';
import { cloneDeep, isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: grey[200]
    },
    plainLink: {
        color: 'inherit',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none'
        },
        width: '100%',
        textAlign: 'left'
    },
    lablelessTopBuffer: {
        paddingTop: 20
    }
}));

function createQuestionarySelector() {
    return createSelector(
        state => state.order.questionary,
        x => x
    );
}

function createMortgagesSelector() {
    return createSelector(
        state => state.order.mortgages,
        (_, mortId) => mortId,
        (x, mortId) => x.filter(m => StringUtil.isEqual(m.MortgagePropertyId, mortId))
    );
}

function createPurchasersSelector() {
    return createSelector(
        state => state.order.purchasers,
        (_, propId) => propId,
        (x, propId) => x.filter(p => StringUtil.isEqual(p.PurchaserPropertyId, propId))
    );
}

function ExistingOwnerHasTIPolicy(props) {
    const classes = useStyles();
    const { item, questionParams, collectionName, getInitialAnswer } = props;

    questionParams.indexer = QuestionaryHelper.createIndexer(collectionName, item);

    const conditionalProps = {
        indexer: questionParams.indexer,
        getInitialAnswer: getInitialAnswer
    };

    return <Fragment>
        <Question qId="ExistingOwnerHasTIPolicy" {...questionParams} />
        <Conditional upon="ExistingOwnerHasTIPolicy" when={(x) => StringUtil.isYes(x)} {...conditionalProps}>
            <Fact error>If the registered owner already has title insurance, they are not eligible for Existing Owner Policy.</Fact>
        </Conditional>
    </Fragment>;
}

function PropertyInfo(props) {
    const classes = useStyles();
    
    let { questionary } = props;
    const { item, idx, questionParams, canAdd, add, remove, openIdx, setOpenIdx, collectionName, loadQuestionary, getInitialAnswer } = props;
    
    const isOpen = idx === openIdx;

    const isFirst = idx === 0;

    questionParams.indexer = QuestionaryHelper.createIndexer(collectionName, item);

    function expandCollapse() {
        //TODO Remove
        return;
        if (isOpen) {
            setOpenIdx(-1);
        }
        else {
            setOpenIdx(idx);
            loadQuestionary(item.TransactionType, item.PropertyType, item.IsEnrolledInNHP);
        }
    }

    //#region Mortgage Dependencies
    const selectMortgages = useMemo(
        createMortgagesSelector,
        []
    );
    
    const mortgages = useSelector(x => selectMortgages(x, item.Id))
    //#endregion Mortgage Dependencies

    //#region Purchaser Dependencies
    const selecetPurchasers = useMemo(
        createPurchasersSelector,
        []
    );
    
    const purchasers = useSelector(x => selecetPurchasers(x, item.Id));
    //#endregion Purchaser Dependencies

    const nhpCondoLegalDescriptions = useSelector(x => x.order.nhpCondoLegalDescriptions).filter(n => StringUtil.isEqual(n.NHPCondoLegalDescriptionPropertyId, item.Id));

    const selectQuestionary = useMemo(
        createQuestionarySelector,
        []
    );

    const reloadedQuestionary = useSelector(x => selectQuestionary(x));

    if (reloadedQuestionary && !isEmpty(reloadedQuestionary)) {
        const reloadedQuestionaryCopy = cloneDeep(reloadedQuestionary); // reloadedQuestionary is ro and we need to make the questions editable...

        questionary = reloadedQuestionaryCopy;
        questionParams.questionary = reloadedQuestionaryCopy;
    }

    const conditionalProps = {
        indexer: questionParams.indexer,
        getInitialAnswer: getInitialAnswer
    };

    async function searchProjectsEventHandler(searchCriteria) {
        const resp = await PolicyApiClient.searchProject(searchCriteria);

        if (resp.hasError) return null;

        const respData = resp.data;
        const validationMap = ValidationHelper.getValidationMap(respData);
        if (!StringUtil.isNullOrEmpty(validationMap)) return null;

        const opts = respData.ProjectOptions;

        return opts;
    }

    //#region Property
    const property = <Fragment>
        <Question qId="TransactionType" {...questionParams} />

        <Question qId="IsEnrolledInNHP" {...questionParams}>
            <Conditional upon="IsEnrolledInNHP" when={(x) => StringUtil.isYes(x)} {...conditionalProps}>
                <Question qId="NHPCode" {...questionParams} onSearchEventHandler={searchProjectsEventHandler} />
                <Fact info>This property is part of the TitlePLUS New Home Program! Your order will be pre-populated with title information and you are not required to conduct a title search.</Fact>
            </Conditional>
        </Question>

        <Question qId="PropertyType" {...questionParams} />
        <Conditional upon="PropertyType" when={(x) => StringUtil.isEqual(x, PropertyTypeEnum.Leasehold)} {...conditionalProps}>
            <Fact info>Leasehold properties are subject to review and additional searches</Fact>
        </Conditional>
        <Conditional upon="PropertyType" when={(x) => StringUtil.isEqual(x, PropertyTypeEnum.Farm)} {...conditionalProps}>
            <Fact info>Farm properties are subject to review and additional searches</Fact>
        </Conditional>
        <Conditional upon="PropertyType" when={(x) => StringUtil.isEqual(x, PropertyTypeEnum.Commercial)} {...conditionalProps}>
            <Fact info>Commercial properties are subject to review and additional searches</Fact>
        </Conditional>

        <Question qId="IsParcelOfTiedLand" {...questionParams}>
            <Conditional upon="IsParcelOfTiedLand" when={(x) => StringUtil.isYes(x)} {...conditionalProps}>
                <Question qId="CommonElementsCondoNumber" {...questionParams} />
            </Conditional>
        </Question>
        <Question qId="NumberOfUnits" {...questionParams} short />
        <Question qId="PropertyAddress" {...questionParams} optional>
            <Question qId="PropertyAddressStreet" {...questionParams} txtWidth={150} />
            <Question qId="PropertyAddressUnit" {...questionParams} optional txtWidth={25} />
            <Question qId="PropertyAddressCity" {...questionParams} />
            <Question qId="PropertyAddressProvince" {...questionParams} />
            <Question qId="PropertyPIN" {...questionParams} short />
            <Box mb={2} />
        </Question>

        <Question qId="NHPFreeholdLegalDescription" {...questionParams} optional>
            <FormGroup row>
                <Box className={classes.lablelessTopBuffer}>
                    <Question qId="SubDivisionType" {...questionParams} inline txtWidth={25} hideLabel />
                </Box>
                <Question qId="LotNumber" {...questionParams} inline txtWidth={25} />
                <Question qId="Plan" {...questionParams} inline txtWidth={25} />
                <Conditional upon="SubDivisionType" when={(x) => StringUtil.isEither(x, "PartOfLot", "PartOfBlock")} {...conditionalProps}>
                    <Question qId="SubSubDivision" {...questionParams} short inline txtWidth={25} />
                    <Question qId="ReferencePlan" {...questionParams} short inline txtWidth={25} />
                </Conditional>
            </FormGroup>
            <Box mb={2} />
        </Question>
        <NHPCondoLegalDescriptionSection {...props} itemsList={nhpCondoLegalDescriptions} property={item} />

        <Question qId="PropertyLegalDescription" {...questionParams} />
        <Question qId="IsPropertyInLandRegistry" {...questionParams} />
        <Question qId="LandRegistryOffice" {...questionParams} txtWidth={151} />

        <Conditional upon="LandRegistryOffice" when={(x) => StringUtil.isEqual(x, "Sudbury")} {...conditionalProps}>
            <Question qId="PropertySearchRequest" {...questionParams}>
                <Conditional upon="PropertySearchRequest" when={(x) => StringUtil.isEqual(x, "search_not_done")} {...conditionalProps}>
                    <Fact error>Not eligible for TitlePLUS!</Fact>
                </Conditional>
            </Question>
        </Conditional>

        <Question qId="ActingFor" {...questionParams} />
        <Question qId="CoverageType" {...questionParams} />
        <Question qId="PurchasePrice" {...questionParams} />
        <Question qId="PurchasePriceInclHST" {...questionParams} />
        <Conditional upon="CoverageType" when={(x) => StringUtil.isEither(x, "owner_and_lender", "lender_policy_only")} {...conditionalProps}>
            <Question qId="IsMortgagePresent" {...questionParams} />
        </Conditional>
        <Question qId="AddExistingOwnerPolicy" {...questionParams} />

        <Conditional upon="AddExistingOwnerPolicy" when={(x) => StringUtil.isYes(x)} {...conditionalProps}>
            <ExistingOwnerHasTIPolicy {...props} />
        </Conditional>

        <Conditional upon="ExistingOwnerHasTIPolicy" when={(x) => StringUtil.isNo(x)} {...conditionalProps}>
            <Question qId="AcquisitionDate" {...questionParams} />
            <Question qId="PropertyValueDeterminedBy" {...questionParams} />
            <Question qId="PropertyValue" {...questionParams} />
        </Conditional>

        <Conditional upon="TransactionType" when={(x) => StringUtil.isEqual(x, TransactionTypeEnum.ExistingOwner)} {...conditionalProps}>
            <Question qId="AcquisitionDate" {...questionParams} />
            <Question qId="PropertyValueDeterminedBy" {...questionParams} />
            <Question qId="PropertyValue" {...questionParams} />
        </Conditional>

    </Fragment>;
    //#endregion Property

    return (
        <Fragment>
            <Box p={1} m={1} display="flex" className={classes.header}>
                <Box flexGrow={1}>
                    <Link className={classes.plainLink} component="button" onClick={(e) => { e.preventDefault(); expandCollapse(); }}>
                        <Typography>Property {idx + 1}</Typography>
                    </Link>
                </Box>
                <Box mr={1}>
                    <Tooltip title="Expand/Collapse">
                        <span>
                            <IconButton
                                aria-label="expand/collapse"
                                size="small"
                                disabled={!canAdd}
                                onClick={() => { expandCollapse() }}
                            >
                                {isOpen ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <Box mr={1}>
                    <Tooltip title="Add Property - Coming Soon!">
                        <span>
                            <IconButton
                                aria-label="add"
                                size="small"
                                disabled={!canAdd}
                                disabled={true}
                                onClick={add}
                            >
                                <AddIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <Box>
                    <Tooltip title="Remove - Coming Soon!">
                        <span>
                            <IconButton
                                aria-label="remove"
                                size="small"
                                disabled={isFirst}
                                onClick={() => { remove(item) }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            </Box>
            <Collapse in={isOpen}>
                <Box m={2}>
                    {property}
                    <PurchasersSection {...props} itemsList={purchasers} propertyId={item.Id} property={item} propertyConditionalProps={conditionalProps} />
                    <MortgagesSection {...props} itemsList={mortgages} propertyId={item.Id} property={item} propertyConditionalProps={conditionalProps} />
                    <TitleSurvey {...props} conditionalProps={conditionalProps} />
                    <OffTitle {...props} conditionalProps={conditionalProps} />

                    <SectionHeader section="additionalConcerns" title="Additional Concerns" questionary={questionary} subSection />
                    <Question qId="AdditionalAdverseMatters" multiline={true} optional={true} {...questionParams} />

                    <Fraud {...props} conditionalProps={conditionalProps} />
                    <Conditional upon="NHPCode" when={(x) => !StringUtil.isNullOrEmpty(x)} {...conditionalProps}>
                        <ClosingInstructions {...props} />
                    </Conditional>
                </Box>
            </Collapse>
        </Fragment>
    );
}

export default PropertyInfo;