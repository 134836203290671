import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux'
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import useDebounce from 'Util/UseDebounce';
import * as PolicyThunks from 'Policy/State/PolicyThunks';

function createOrderChangesCountSelector() {
    return createSelector(
        state => state.order.orderChangesCount,
        x => x
    );
}

function AutoSaver(props) {
    const { po } = props;

    const selectOrderChangesCount = useMemo(
        createOrderChangesCountSelector,
        []
    );

    const orderChangesCount = useSelector(x => selectOrderChangesCount(x));

    const debouncedNeedToAutoSave = useDebounce(orderChangesCount, 5000);

    const dispatch = useDispatch();

    // silent save
    useEffect(() => {
        let isCancelled = false;

        function onUnload() {
            isCancelled = true;
        }

        if (debouncedNeedToAutoSave) {
            dispatch(PolicyThunks.autoSave(po));
        }

        return onUnload;
    },
        [debouncedNeedToAutoSave]
    );

    return null;
}

export default AutoSaver;