import React from 'react';
import { FormControl, FormHelperText, Select, MenuItem, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StringUtil } from 'Util/Helpers';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles(theme => ({
    error: {
        color: theme.palette.error.main
    },
    emptyLabel: {
        color: grey[700]
    }
}));

function DropDown(props) {
    const { q, name, fieldName, subQuestion, error, helperText, defaultValue, fieldStateResolver, ...passThroughProps } = props;

    const classes = useStyles();

    let opts = props.opts;

    if (StringUtil.isNullOrEmpty(opts))
        opts = [];

    const classN = error? classes.error : null;

    // this is an uncontrolled select
    return (
        <FormControl error={error}>
            {subQuestion &&
                <InputLabel id={name} required={!q.Optional}>{q.Text}</InputLabel>
            }
            <Select
                displayEmpty
                name={name}
                labelId={name}
                style={{ minWidth: 155 }}
                error={error}
                className={classN}
                defaultValue={defaultValue}
                {...passThroughProps}
            >
                <MenuItem value="">
                    <span className={classes.emptyLabel}>-- Select --</span>
                </MenuItem>
                {opts.map((opt) => {
                    return <MenuItem
                        key={opt.Id}
                        value={opt.Id}
                    >{opt.Label}</MenuItem>
                })}
            </ Select>
            {!StringUtil.isNullOrEmpty(helperText) &&
                <FormHelperText style={{ marginBottom: '0.8rem' }}>{helperText}</FormHelperText>
            }
        </FormControl>
    );
}

export default DropDown;