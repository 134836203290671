import { createSelector } from 'reselect';

class FormFieldHelper
{
    _nullFieldStateResolver = () => { return {}; }; // empty object
    
    // do not add defaultValue to field state!
    createFieldStateSelector_WithLowerCasing() {
        return createSelector(
            state => state,
            (_, fieldName) => fieldName,
            (x, fieldName) => x[fieldName ? fieldName.toLowerCase() : fieldName]
        );
    }

    createFieldStateSelector() {
        return createSelector(
            state => state,
            (_, fieldName) => fieldName,
            (x, fieldName) => x[fieldName]
        );
    }

    get nullFieldStateResolver()
    {
        return this._nullFieldStateResolver;
    }
}

const instance = new FormFieldHelper();

export { instance as FormFieldHelper }
