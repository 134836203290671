import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { InputLabel, Select, MenuItem, Chip } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { StringUtil } from 'Util/Helpers';
import ClearIcon from '@material-ui/icons/Clear';
import { isEqual, sortBy } from "lodash";

const useStyles = makeStyles((theme) => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        marginLeft: 2,
        marginRight: 2,
        marginTop: 2
    },
}));

function renderMenuListDefault(itemsArr, getStyle, renderItem, t) {
    if (!itemsArr) {
        return null;
    }

    return (
        itemsArr.map((item) => {
            return <MenuItem key={item} value={item} style={getStyle(item)}>{!StringUtil.isNullOrEmpty(renderItem) ? renderItem(t, item) : t(item)}</MenuItem>
        })
    );
}

function selectedItemLabelDefault(itemsArr, value, t) {
    return t(value);
}

function MultiSelectDropDown(props) {
    const { t } = useTranslation();

    const theme = useTheme();

    const classes = useStyles();

    const {
        single,
        labelId,
        label,
        name,
        onChange,
        error,
        helperText,
        hideClear,
        revertOnError,
        items,
        readOnly,
        renderItem,
        eagerLoad,
        loadItems,
        renderMenuList,
        selectedItemLabel,
        resolveInitialValue
    } = props;

    let { initialValue } = props;

    if (StringUtil.isNullOrEmpty(initialValue)) {
        if (single) {
            initialValue = '';
        }
        else {
            initialValue = [];
        }
    }

    const [value, setValue] = useState(initialValue);
    const [prevValue, setPrevValue] = useState(initialValue);
    const [open, setOpen] = useState(false);
    const [itemsArr, setItemsArr] = useState(items);

    const hasItems = itemsArr && itemsArr.length > 0;
    const loading = !hasItems && ((loadItems && open) || eagerLoad);

    const renderMenuListFunc = renderMenuList || renderMenuListDefault;
    const selectedItemLabelFunc = selectedItemLabel || selectedItemLabelDefault;

    function setLoadedItems(items) {
        if (StringUtil.isNullOrEmpty(items) || items.length === 0) {
            return;
        }

        setItemsArr(items);
    }

    useEffect(() => {
        let unloaded = false;

        function onUnload() {
            unloaded = true;
        }

        async function performLoad()
        {
            const loadedItems = await loadItems();

            if(unloaded) {
                return;
            }

            setLoadedItems(loadedItems);
        }

        if(!StringUtil.isNullOrEmpty(resolveInitialValue)) {
            const v = resolveInitialValue();

            if(!StringUtil.isNullOrEmpty(v) && !unloaded) {
                setValue(v);
                setPrevValue(v);

                // we have a value, we do not have the list! If eagerLoad is true, then the list would load in a bit
                const needToLoad = !hasItems && !eagerLoad;

                if(needToLoad) {
                    performLoad();
                }
            }
        }

        return onUnload;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let unloaded = false;

        if (!loading) {
            return;
        }

        async function performLoad()
        {
            const loadedItems = await loadItems();

            if(unloaded) {
                return;
            }

            setLoadedItems(loadedItems);
        }

        performLoad();

        return () => {
            unloaded = true;
        };
    }, [loading]);

    async function invokeOnChange(event) {
        if (StringUtil.isNullOrEmpty(onChange)) {
            return;
        }

        const valuePostChange = await onChange(event, itemsArr);

        if (revertOnError && !valuePostChange) {
            setValue(prevValue); // revert
        }
        else {
            if (valuePostChange) {
                setValue(valuePostChange);
            }

            setPrevValue(event.target.value);
        }
    }

    function invokeOnChangeIfNeedBe(newValue) {
        const noChange = isEqual(sortBy(newValue), sortBy(prevValue));

        if (noChange) {
            return;
        }

        const ev = {
            persist: function () { },
            target: {
                name,
                value: newValue
            }
        };

        invokeOnChange(ev);
    }

    const handleClose = () => {
        setOpen(false);
        
        if(single) {
            return;
        }
        
        invokeOnChangeIfNeedBe(value);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleChange = (event) => {
        if(single) {
            setValue(event.target.value);
            invokeOnChangeIfNeedBe(event.target.value);
            
            return;
        }
        
        if (event.target.value.includes("")) { // cleared the box
            setValue([]);
            setOpen(false);
            invokeOnChangeIfNeedBe([]);

            return;
        }

        setValue(event.target.value);
    };

    function getStyle(item) {
        let selected;

        if (single) {
            selected = StringUtil.isEqual(value, item);
        }
        else {
            selected = value.includes(item);
        }

        return {
            fontWeight: selected ? theme.typography.fontWeightMedium : theme.typography.fontWeighRegular
        };
    }

    function renderSelected(selected) {
        if (single) {
            return <div className={classes.chips}>
                <Chip color="primary" size="small" key={selected} label={selectedItemLabelFunc(itemsArr, selected, t)} className={classes.chip} />
            </div>
        }
        else {
            return <div className={classes.chips}>
                {selected.map((item) => (
                    <Chip color="primary" size="small" key={item} label={selectedItemLabelFunc(itemsArr, item, t)} className={classes.chip} />
                ))}
            </div>
        }
    }

    return (
        <Fragment>
            {!StringUtil.isNullOrEmpty(labelId) && <InputLabel id={labelId}>{label}</InputLabel>}
            <Select
                labelId={labelId}
                name={name}
                multiple={!single}
                value={value}
                onChange={handleChange}
                renderValue={renderSelected}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                disabled={readOnly}
            >
                {loading &&
                    <MenuItem value="" disabled><em>Loading...</em></MenuItem>
                }
                {!hideClear && !loading &&
                    <MenuItem value=""><ClearIcon /> <em>Clear</em></MenuItem>
                }
                {renderMenuListFunc(itemsArr, getStyle, renderItem, t)}
            </Select>
        </Fragment>
    );
}

export default MultiSelectDropDown;