import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Info as InfoIcon } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
    infoMsg: {
      display: 'inline-flex',
      alignItems: 'center'
    },
    icon: {
      marginRight: theme.spacing(0.5),
      color: theme.palette.primary.main
    }
}));

function MayUseLawPRO(props)
{
    const { children } = props;

    const classes = useStyles();

    return (
        <Typography className={classes.infoMsg}><InfoIcon className={classes.icon} />
            {children}
        </Typography>
    );
}

export default MayUseLawPRO;