import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Slide, Typography, IconButton, Toolbar, AppBar, Dialog, LinearProgress } from '@material-ui/core';
import { StringUtil } from 'Util/Helpers';
import ErrorSnackbar from 'Components/ErrorSnackbar';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    content: {
        flexGrow: 1,
    },
    viewFame: {
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0,
        border: 'none'
    },
    loader: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function FileView(props) {
    const classes = useStyles();
    const { open, loading, file, handleClose } = props;

    let filename = '';
    let fileUrl = '';
    let viewError = '';

    if (!StringUtil.isNullOrEmpty(file)) {
        filename = file.name;
        fileUrl = file.viewUrl;
        viewError = file.viewError;
    }

    const frameTitle = filename;

    return (
        <div>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h5" className={classes.title}>
                            {filename}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <main className={classes.content}>
                    {loading &&
                        <div className={classes.loader}>
                            <LinearProgress variant="query" color="secondary" />
                            <Typography align="center">Loading...</Typography>
                        </div>
                    }
                    {!StringUtil.isNullOrEmpty(viewError) &&
                        <ErrorSnackbar message={viewError} centered={true} borderRadius={0} />
                    }
                    {!StringUtil.isNullOrEmpty(fileUrl) &&
                        <iframe src={fileUrl} title={frameTitle} className={classes.viewFame}>
                        </iframe>
                    }
                </main>
            </Dialog>
        </div>
    );
}