import { StringUtil } from 'Util/Helpers';
import { CollectionTypeEnum } from 'Model/PolicyOrder';
import Logger from 'Util/Logger';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';

const logger = new Logger('PolicyHelper');

class BaseHelper {
    constructor(state) {
        this.state = state;
    }

    getItemTrueState(collectionName, item) {
        if (item == null)
            return null;

        const itemIndex = QuestionaryHelper.createIndexer(collectionName, item);

        let newItem = { ...item };

        Object.entries(this.state.orderChanges).forEach(entry => {
            const [key, value] = entry;

            if (key.startsWith(itemIndex)) {
                const k = key.replace(itemIndex, '');
                newItem[k] = value;
            }
        });

        return newItem;
    }

    where(collectionName, when) {
        if (StringUtil.isNullEmptyOrWhiteSpace(collectionName)) {
            logger.debug(`Invalid call. 'collectionName' cannot be null`);
            return false;
        }

        const items = this.state[collectionName.toLocaleLowerCase()];

        if (!items)
            return null;

        if (when) {
            return items.map(item => this.getItemTrueState(item))
                .filter(item => when(item) == true);
        }
        return items;
    }

    any(collectionName, when) {
        if (StringUtil.isNullEmptyOrWhiteSpace(collectionName)) {
            logger.debug(`Invalid call. 'collectionName' cannot be null`);
            return false;
        }

        const items = this.state[collectionName.toLocaleLowerCase()];

        if (items && when)
            return items.map(item => this.getItemTrueState(item))
                .some(item => when(item) == true);

        return false;
    }
}

class PropertyHelper extends BaseHelper {
    collectionName = CollectionTypeEnum.Properties;

    getItemTrueState(item) {
        return super.getItemTrueState(this.collectionName, item);
    }
}

class MortgageHelper extends BaseHelper {
    collectionName = CollectionTypeEnum.Mortgages;

    any(when) {
        return super.any(this.collectionName, when);
    }

    sum(when, fieldName) {
        const items = super.where(this.collectionName, when);

        if (items)
            return items.reduce((total, item) => total + StringUtil.toDecimal(item[fieldName]), 0);

        return 0;
    }

    getItemTrueState(item) {
        return super.getItemTrueState(this.collectionName, item);
    }
}

class PurchaserHelper extends BaseHelper {
    collectionName = CollectionTypeEnum.Purchasers;

    getItemTrueState(item) {
        return super.getItemTrueState(this.collectionName, item);
    }
}

class RemainingMortgageHelper extends BaseHelper {
    collectionName = CollectionTypeEnum.RemainingMortgages;

    //Create functions as and when needed.
}

export {
    PropertyHelper,
    MortgageHelper,
    PurchaserHelper,
    RemainingMortgageHelper
};