import React, { Fragment, useMemo } from 'react';
import { Question } from 'Components/Questionary';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';
import { StringUtil } from 'Util/Helpers';
import { Typography, Box, IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import grey from '@material-ui/core/colors/grey';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import Conditional from 'Policy/Conditional';

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: grey[200]
    }
}));

function MortgageInfo(props) {
    const { item, idx, questionParams, canAdd, add, remove, collectionName, propertyConditionalProps } = props;

    const classes = useStyles();

    const isFirst = idx === 0;

    questionParams.indexer = QuestionaryHelper.createIndexer(collectionName, item);

    const conditionalProps = {
        indexer: questionParams.indexer,
        getInitialAnswer: propertyConditionalProps.getInitialAnswer
    };

    return (
        <Fragment>
            <Box p={1} display="flex" className={classes.header}>
                <Box flexGrow={1}>
                    <Typography>Mortgage {idx + 1}</Typography>
                </Box>
                <Box mr={1}>
                    <Tooltip title="Add Mortgage">
                        <span>
                            <IconButton
                                aria-label="add"
                                size="small"
                                disabled={!canAdd}
                                onClick={add}
                            >
                                <AddIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <Box>
                    <Tooltip title="Remove">
                        <span>
                            <IconButton
                                aria-label="remove"
                                size="small"
                                disabled={isFirst}
                                onClick={() => { remove(item) }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            </Box>
            <Box p={2} className={classes.content}>
                <Question qId="MortgageTPInsured" {...questionParams} />

                <Conditional upon="ShouldShowMortgageDetails" when={(x) => x == true} {...conditionalProps}>
                    <Question qId="MortgageAdvanceDate" {...questionParams} />
                    {/* <Question qId="BlanketMortgage" {...questionParams} /> */}
                    <Question qId="MortgageType" {...questionParams} />
                    <Question qId="MortgageLenderName" allowFreeText {...questionParams} short />
                    <Question qId="MortgageReferenceNumber" {...questionParams} short />
                    <Question qId="MortgagePrincipalAmount" {...questionParams} />

                    {/* Uncomment once blanket mortgage is introduced */}
                    {/* <Conditional upon="BlanketMortgage" when={(x) => StringUtil.isYes(x)} {...conditionalProps}>
                        <Question qId="PriorityOfBlanketMortgage" {...questionParams} />
                    </Conditional> */}
                    {/* <Conditional upon="BlanketMortgage" when={(x) => StringUtil.isNo(x)} {...conditionalProps}> */}
                    <Question qId="PriorityUponClosing" {...questionParams} />
                    {/* </Conditional> */}

                    {!isFirst && <Question qId="ActingForLender" {...questionParams} />}
                </Conditional>
            </Box>
        </Fragment>
    );
}

export default MortgageInfo;