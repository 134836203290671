import { Box, Grid, Icon, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import clsx from 'clsx';
import AutoCompleteFreeFormQuestion from 'Components/AutoCompleteFreeFormQuestion';
import CheckBoxOptionGroup from 'Components/CheckBoxOptionGroup';
import DropDown from 'Components/DropDown';
import DropZone from 'Components/FileUpload/DropZone';
import Label from 'Components/Label';
import MultipleOptionGroup from 'Components/MultipleOptionGroup';
import OptionGroup from 'Components/OptionGroup';
import SingleCheckBox from 'Components/SingleCheckBox';
import withFieldStateBindings from 'Components/withFieldStateBindings';
import { withOnChangeDebounced } from 'Components/withOnChangeDebounced';
import withTwoWayFieldStateBindings from 'Components/withTwoWayFieldStateBindings';
import { ProvincesDDList } from 'Model/PolicyOrder';
import React from 'react';
import { StringUtil } from 'Util/Helpers';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';
import AdverseMattersFromTitleSearch from './CustomQuestions/AdverseMattersFromTitleSearch';
import BorrowerLawyerName from './CustomQuestions/BorrowerLawyerName';
import PropertiesList from './CustomQuestions/PropertiesList';
import PropertyPINs from './CustomQuestions/PropertyPINs';
import TermsAndConditions from './CustomQuestions/TermsAndConditions';
import DateControl from './DateControl';
import QuestionTitle from './QuestionTitle';

const useStyles = makeStyles(theme => ({
    questionBody: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1.5)
    },
    shortTextField: {
        width: '50ch',
        paddingRight: theme.spacing(2)
    },
    longTextField: {
        width: '100%',
        paddingRight: theme.spacing(2)
    },
    spacingFix: {
        marginTop: 8
    },
    dropZone: {
        marginRight: theme.spacing(2)
    },
    iconRoot: {
        marginBottom: -25,
        left: -47,
        position: 'relative',
        float: 'left',
        overflow: 'visible',
        top: 60
    },
    imageIcon: {
        height: 28
    },
    justify: {
        marginLeft: theme.spacing(1.5),
        //marginRight: theme.spacing(2)
    },
    indent: {
        marginLeft: theme.spacing(0.5)
    }
}));
const LabelBound = withTwoWayFieldStateBindings(Label);
const TextFieldBound = withFieldStateBindings(withTwoWayFieldStateBindings(withOnChangeDebounced(TextField)));
const DropDownBound = withFieldStateBindings(withTwoWayFieldStateBindings(DropDown));
const AutoCompleteBound = withFieldStateBindings(withTwoWayFieldStateBindings(AutoCompleteFreeFormQuestion));
const DateControlBound = withFieldStateBindings(DateControl);
const OptionGroupBound = withFieldStateBindings(withTwoWayFieldStateBindings(OptionGroup));
const CheckBoxOptionGroupBound = withFieldStateBindings(CheckBoxOptionGroup);
const MultipleOptionGroupBound = withFieldStateBindings(MultipleOptionGroup);
const CurrencyTextFieldBound = withFieldStateBindings(CurrencyTextField);
const SingleCheckBoxBound = withFieldStateBindings(SingleCheckBox);
const PropertyPINsBound = withFieldStateBindings(PropertyPINs);
const AdverseMattersFromTitleSearchBound = withFieldStateBindings(AdverseMattersFromTitleSearch);

function Question(props) {
    const classes = useStyles();

    const {
        qId,
        questionary,
        inputProps,
        subQuestion,
        hideLabel,
        topBuffer,
        multiline,
        optional,
        readOnly,
        getUploadUrls,
        isReviewRequired,
        short,
        indexer,
        inline,
        txtWidth,
        twoWayStateResolver,
        handleInputChange,
        onSearchEventHandler,
        allowFreeText,
        displayOnly,
        disabled,
        ...other
    } = props;

    let { fieldStateResolver } = props;

    const q = QuestionaryHelper.getQuestion(questionary, qId);

    if (q == null) {
        return null;
    }

    q.Optional = optional;

    let fieldName = qId;

    if (!StringUtil.isNullOrEmpty(indexer)) {
        fieldName = `${indexer}${fieldName}`
    }

    let answerControl = null;

    if (displayOnly)
        answerControl = (
            <LabelBound
                twoWayStateResolver={twoWayStateResolver}
                {...inputProps(fieldName)} />
        );
    else if (StringUtil.isEqual(q.Type, "MultiPart")) {
        answerControl = <Box mb={2} />;
    }
    else if (StringUtil.isEither(q.Type, "SingleSelection", "YesNo")) {
        answerControl = (
            <OptionGroupBound fieldName={fieldName} readOnly={readOnly} fieldStateResolver={fieldStateResolver} twoWayStateResolver={twoWayStateResolver} opts={q.Options} {...inputProps(fieldName)} {...other} />
        );
    }
    else if (StringUtil.isEqual(q.Type, "SingleSelectionCheckBox")) {
        answerControl = (
            <CheckBoxOptionGroupBound fieldName={fieldName} fieldStateResolver={fieldStateResolver} twoWayStateResolver={twoWayStateResolver} opts={q.Options} {...inputProps(fieldName)} {...other} />
        );
    }
    //Not used by any question, propably obsolete
    else if (StringUtil.isEqual(q.Type, "CheckBox")) {
        answerControl = (
            <SingleCheckBoxBound fieldName={fieldName} fieldStateResolver={fieldStateResolver} label={q.ContentText} {...inputProps(fieldName)} opts={q.Options} />
        );
    }
    else if (StringUtil.isEqual(q.Type, "MultipleSelection")) {
        answerControl = (
            <MultipleOptionGroupBound opts={q.Options} fieldName={fieldName} fieldStateResolver={fieldStateResolver} {...inputProps(fieldName)} />
        );
    }
    else if (StringUtil.isEqual(q.Type, "FileUpload")) {
        const uploadUrls = getUploadUrls(q);

        answerControl = (
            <DropZone uploadUrls={uploadUrls} readOnly={readOnly} containerClass={classes.dropZone} />
        );
    }
    else if (StringUtil.isEqual(q.Type, "AutoCompleteDropDownSelection")) {
        answerControl = <AutoCompleteBound q={q} opts={q.Options} fieldName={fieldName} subQuestion={subQuestion} fieldStateResolver={fieldStateResolver} twoWayStateResolver={twoWayStateResolver} {...inputProps(fieldName)} onSearchEventHandler={onSearchEventHandler} allowFreeText={allowFreeText} {...other} />
    }
    else if (StringUtil.isEqual(q.Type, "DropDownSelection")) {
        answerControl = <DropDownBound q={q} opts={q.Options} fieldName={fieldName} subQuestion={subQuestion} fieldStateResolver={fieldStateResolver} twoWayStateResolver={twoWayStateResolver} {...inputProps(fieldName)} />;
    }
    else if (StringUtil.isEqual(q.Type, "PropertiesList")) {
        answerControl = <PropertiesList q={q} fieldName={fieldName} subQuestion={subQuestion} optionGroupProps={inputProps} />
    }
    else if (StringUtil.isEqual(q.Type, "Province")) {
        answerControl = <DropDownBound q={q} opts={ProvincesDDList} fieldName={fieldName} subQuestion={subQuestion} fieldStateResolver={fieldStateResolver} twoWayStateResolver={twoWayStateResolver} {...inputProps(fieldName)} {...other} />
    }
    else if (StringUtil.isEqual(q.Type, "Date")) {
        answerControl = <DateControlBound q={q} fieldName={fieldName} fieldStateResolver={fieldStateResolver} {...inputProps(fieldName)} />
    }
    else {
        const addl = {
            required: !optional,
            readOnly: readOnly,
            autoComplete: 'off'
        };

        if (readOnly) {
            addl.InputProps = { readOnly: true };
        }

        if (subQuestion) {
            addl.label = q.Text;
        }

        if (hideLabel)
            addl.label = '';

        if (multiline) {
            addl.multiline = true;
            addl.variant = "outlined";
        }

        if (StringUtil.isEqual(q.Type, "BorrowerLawyerName")) {
            answerControl = (
                <BorrowerLawyerName qId={qId} textFieldProps={inputProps} addl={addl} optionGroupProps={inputProps} indexer={indexer} other={other} />
            );
        }
        else if (StringUtil.isEqual(q.Type, "TermsAndConditions")) {
            const p = { qId, fieldName, addl, inputProps };

            answerControl = (
                <TermsAndConditions {...p} />
            );
        }
        else if (StringUtil.isEqual(q.Type, "Numeric")) {
            addl.decimalPlaces = 0;
            addl.currencySymbol = '';
            addl.digitGroupSeparator = '';

            answerControl = (
                <CurrencyTextFieldBound variant="standard" fieldStateResolver={fieldStateResolver} {...addl} {...inputProps(fieldName)} />
            );
        }
        else if (StringUtil.isEqual(q.Type, "Currency")) {
            answerControl = (
                <CurrencyTextFieldBound variant="standard" fieldStateResolver={fieldStateResolver} {...addl} {...inputProps(fieldName)} />
            );
        }
        else if (StringUtil.isEqual(q.Type, "PropertyPIN")) {
            answerControl = (
                <PropertyPINsBound qId={qId}
                    indexer={indexer}
                    optionGroupProps={inputProps}
                    twoWayStateResolver={twoWayStateResolver}
                    {...addl}
                    other={other}
                />
            );
        }
        else if (StringUtil.isEqual(q.Type, "AdverseMattersFromTitleSearch")) {
            answerControl = (
                <AdverseMattersFromTitleSearchBound qId={qId} fieldStateResolver={fieldStateResolver} opts={q.Options} {...inputProps(fieldName)} {...addl} />
            );
        }
        else {
            let cName = classes.longTextField;
            let txtStyle = {};

            if (txtWidth) {
                cName = null;
                txtStyle = { width: `${txtWidth}ch` };
            }
            else if (short) {
                cName = classes.shortTextField;
            }

            answerControl = (
                <TextFieldBound
                    className={cName}
                    style={txtStyle}
                    fieldStateResolver={fieldStateResolver}
                    twoWayStateResolver={twoWayStateResolver}
                    {...other}
                    {...addl}
                    {...inputProps(fieldName)}
                    disabled={disabled} />
            );
        }
    }

    if (subQuestion || hideLabel || displayOnly) {
        let subQClassName = null;

        if (topBuffer) {
            subQClassName = classes.spacingFix;
        }

        if (hideLabel)
            subQClassName = classes.justify;

        if (displayOnly)
            subQClassName = classes.indent;

        const control = <React.Fragment>
            {isReviewRequired && isReviewRequired(fieldName) &&
                <Icon classes={{ root: classes.iconRoot }}>
                    <img className={classes.imageIcon} src="/img/under_review.svg" />
                </Icon>
            }
            <div className={subQClassName}>
                {answerControl}
            </div>
        </React.Fragment>

        return control;
    }

    let gridClass = clsx(classes.questionBody, topBuffer ? classes.spacingFix : null);

    if (inline) {
        return (
            <div>
                <QuestionTitle q={q} isReviewRequired={isReviewRequired} indexer={indexer} inline={inline} />
                <div className={gridClass}>
                    {answerControl}{props.children}
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <QuestionTitle q={q} isReviewRequired={isReviewRequired} indexer={indexer} />
            <Grid item xs className={gridClass}>
                {answerControl}{props.children}
            </Grid>
        </React.Fragment>
    );
}

export default Question;