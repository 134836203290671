import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

const instance = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

export { instance as Store };