import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { StringUtil } from 'Util/Helpers';
import AutoCompleteFreeForm from './AutoCompleteFreeForm';
import SyntheticEvent from './SyntheticEvent';

function AutoCompleteFreeFormQuestion(props) {
    const { q, name, fieldName, subQuestion, error, helperText, defaultValue, fieldStateResolver, onChange, allowFreeText, onSearchEventHandler, ...passThroughProps } = props;

    const [opts, setOpts] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (props.opts)
            setOpts(props.opts);
    }, [props.opts]);

    function handleOnAutoCompleteChange(event, option) {
        event.persist();

        let value = null;

        if (option)
            value = option.Id;

        console.log('in ac free form que');
        console.log(value);

        const ev = new SyntheticEvent(fieldName, value);

        onChange(ev);
    }

    async function searchEventHandler(value) {
        if (allowFreeText) {
            const ev = new SyntheticEvent(fieldName, value);
            onChange(ev);
        }
        
        if (onSearchEventHandler) {
            setOpen(true);
            const result = await onSearchEventHandler(value);
            setOpts(result);
        }
    }

    // this is an uncontrolled select
    return (
        <FormControl error={error}>
            {subQuestion &&
                <InputLabel id={name} required={!q.Optional}>{q.Text}</InputLabel>
            }
            <AutoCompleteFreeForm
                bound={true}
                multiSelect={false}
                openPopup={open}
                options={opts}
                optionLabelProp="Label"
                onChangeEventHandler={handleOnAutoCompleteChange}
                //onSearchEventHandler={handleSearch}                
                defaultValue={defaultValue}
                groupByProp="Label"
                onSearchEventHandler={searchEventHandler}
            />

            {!StringUtil.isNullOrEmpty(helperText) &&
                <FormHelperText style={{ marginBottom: '0.8rem' }}>{helperText}</FormHelperText>
            }
        </FormControl>
    );
}

export default AutoCompleteFreeFormQuestion;