import { Box, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1, 2, 0, 0)
    }
}));

function Fact(props) {
    const classes = useStyles();
    const { warning, error, success, children } = props;

    let severity = 'info';

    if (error)
        severity = 'error';
    else if (warning)
        severity = 'warning';
    else if (success)
        severity = "success";

    return <Box className={classes.root}>
        <Alert severity={severity}>{children}</Alert>
    </Box>;
}

export default Fact;