import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Icon } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { green, red, grey, yellow } from '@material-ui/core/colors';
import { RegistrationStatusEnum } from 'Model/PolicyOrder';

export const styles = (theme) => {
    const s = {
        root: {
            marginLeft: theme.spacing(2.5)
        },
        iconRoot: {
        },
        imageIcon: {
            verticalAlign: 'middle',
            marginRight: '5px',
            width: theme.spacing(3),
            height: theme.spacing(3.5),
            marginBottom: theme.spacing(2)
        },
    };

    s[RegistrationStatusEnum.Registered] =
    {
        color: green[800],
        verticalAlign: 'text-top'
    };

    s[RegistrationStatusEnum.InQueue] =
    {
        color: yellow[800],
        verticalAlign: 'text-top'
    };

    s[RegistrationStatusEnum.RegistrationFailed] = {
        color: red[700],
        verticalAlign: 'text-top'
    };

    s[RegistrationStatusEnum.RegistrationAttemptsExhausted] = {
        color: red[700],
        verticalAlign: 'text-top'
    };

    s[RegistrationStatusEnum.RegistrationProhibited] = {
        color: red[700],
        verticalAlign: 'text-top'
    };

    s[RegistrationStatusEnum.RegistrationBug] = {
        color: red[700],
        verticalAlign: 'text-top'
    };

    s[RegistrationStatusEnum.Cancelled] = {
        color: red[700],
        verticalAlign: 'text-top'
    };

    s[RegistrationStatusEnum.NotRegistered] = {
        color: grey[700],
        verticalAlign: 'text-top'
    };

    return s;
};

function RegistrationStatus(props) {
    const { t } = useTranslation();

    const { status, classes, className } = props;

    let statusText = t(status);

    const textClassName = clsx(classes.root, classes[status], className);

    const toReturn = <span className={textClassName}>
        <Icon classes={{ root: classes.iconRoot }}>
            <img className={`${classes.imageIcon} ${classes[status]}`} src="/img/notary.png" alt="unknown" />
        </Icon>
        {statusText}
    </span>;

    return toReturn;
}

RegistrationStatus.propTypes = {
    status: PropTypes.string
};

export default withStyles(styles)(RegistrationStatus);