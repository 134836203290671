import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CircularProgress, Typography, Button, Box } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { StringUtil } from 'Util/Helpers';
import { useSelector } from 'react-redux';
import { AuthService } from 'Services/AuthService';
import * as UserActions from 'User/State/UserActions';
import Logger from 'Util/Logger';
import ErrorSnackbar from 'Components/ErrorSnackbar';
import * as AppErrors from 'Model/AppErrors';
import * as UserStatus from 'Model/UserStatus';

const useStylesLoading = makeStyles(theme => ({
    btnBusyInd: {
        color: green[500],
        marginRight: theme.spacing(1)
    },
}));

function BusyInd(props) {
    const classes = useStylesLoading();

    return (
        <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
            <CircularProgress size={24} className={classes.btnBusyInd} /><Typography>Loading user info...</Typography>
        </Box>
    );
}

function usherUser(us, hist, location) {
    const currPath = location.pathname;
    let redirectTo = null;
    
    if (StringUtil.isNullOrEmpty(us)) {
        // TODO -- redirect to an error page ...
    }
    if (StringUtil.isEither(us, UserStatus.Created, UserStatus.ExistingImported)) {
        redirectTo = "/user/register";
    }
    if (StringUtil.isEither(us, UserStatus.ReadyForDueDiligence, UserStatus.WaitingForAssociationApproval)) {
        redirectTo = "/user/register-success";
    }

    if(redirectTo && !StringUtil.isEqual(redirectTo, currPath)) {
        setTimeout(() => {
            hist.push(redirectTo);
        }, 1000);

        return true;
    }

    return false;
}

const logger = new Logger(withUserProfile.name);

function withUserProfile(Component) {
    function ComponentExtended(props) {
        const { forwardedRef, ...passThroughProps } = props;

        const hist = useHistory();
        const location = useLocation();

        const selectUserInfoLoadStatus = useMemo(
            UserActions.createUserInfoLoadStatusSelector,
            []
        );

        const userInfoLoadStatus = useSelector(x => selectUserInfoLoadStatus(x));

        const status = userInfoLoadStatus.status;

        if (StringUtil.isEither(status, 'loading')) {
            return (
                <React.Fragment>
                    <BusyInd />
                </React.Fragment>
            );
        }
        else if (StringUtil.isEqual(status, 'error')) {
            return (
                <React.Fragment>
                    <ErrorSnackbar centered message={AppErrors.generic} errorCode={AppErrors.code25} />
                    <Box mt={1} display="flex" flexDirection="row-reverse">
                        <Button
                            variant="contained"
                            onClick={async () => { await AuthService.authClient.signOut() }}
                        >
                            Logout
                        </Button>
                    </Box>
                </React.Fragment>
            );
        }
        else if(StringUtil.isEqual(status, 'loaded')) {
            if(usherUser(userInfoLoadStatus.userStatus, hist, location)) {
                return null;
            }
        }

        return <Component ref={forwardedRef} {...passThroughProps} />;
    }

    function forwardRefBody(props, ref) {
        return <ComponentExtended {...props} forwardedRef={ref} />;
    }

    const name = Component.displayName || Component.name;
    forwardRefBody.displayName = `${withUserProfile.name}(${name})`;

    return React.forwardRef(forwardRefBody);
}

export default withUserProfile;