import React, { useMemo } from 'react';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import { green, grey, red } from '@material-ui/core/colors';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { OrderStatusEnum, PermittedOrderActionsEnum } from 'Model/PolicyOrder';
import { StringUtil, UserHelper } from 'Util/Helpers';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { SectionHeader } from 'Components/Questionary';
import * as UserPermisions from 'Model/UserPermisions';
import * as UserActions from 'User/State/UserActions';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        }
    },
    btnApprove: {
        margin: 2,
        backgroundColor: green[500],
        color: grey[50],
        '&:hover': {
            background: green[900],
            color: grey[50]
        }
    },
    btnDecline: {
        margin: 2,
        backgroundColor: red[500],
        color: grey[50],
        '&:hover': {
            background: red[900],
            color: grey[50]
        }
    },
    pointer: {
        cursor: 'default'
    },
    decisionComments: {
        width: '100%',
        paddingRight: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    icon: {
        color: grey[50]
    }
}));

function createOrderVolatileDataSelector() {
    return createSelector(
        state => state.order.orderVolatileData,
        (_, propName) => propName,
        (x, propName) => x[propName]
    );
}

function PolicyDecisionActions(props) {
    const { changeOrderStatus } = props;

    const classes = useStyles();

    const selectOrderVolatileData = useMemo(
        createOrderVolatileDataSelector,
        []
    );

    const selectUserInfo = useMemo(
        UserActions.createUserInfoSelector,
        []
    );
    
    const userInfo =  useSelector(x => selectUserInfo(x));

    const permittedActions = useSelector(x => selectOrderVolatileData(x, "PermittedActions"));

    const allowApprove = StringUtil.contains(permittedActions, PermittedOrderActionsEnum.Approve);
    const allowDecline = StringUtil.contains(permittedActions, PermittedOrderActionsEnum.Decline);

    if (!allowApprove && !allowDecline) {
        return null;
    }

    if(!UserHelper.hasPermission(userInfo, UserPermisions.decision_deal)) {
        return null;
    }

    return (
        <React.Fragment>
            <SectionHeader title="Decision" />
            <div className={classes.container}>
                <div>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        startIcon={<TouchAppIcon className={classes.icon} />}
                        onClick={(event) => changeOrderStatus(event, OrderStatusEnum.UserActionRequired)}>User Action Required</Button>
                    <Button
                        label="Approve"
                        startIcon={<ThumbUpAltIcon className={classes.icon} />}
                        disabled={!allowApprove}
                        className={classes.btnApprove}
                        onClick={(event) => changeOrderStatus(event, OrderStatusEnum.Approved)}>Approve</Button>
                    <Button
                        label="Decline"
                        endIcon={<ThumbDownAltIcon className={classes.icon} />}
                        disabled={!allowDecline}
                        className={classes.btnDecline}
                        onClick={(event) => changeOrderStatus(event, OrderStatusEnum.Declined)}>Decline</Button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default PolicyDecisionActions;