import React, { useState } from 'react';
import { Link, Box } from '@material-ui/core';
import { AppSettings } from 'AppSettings';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

function HelpSigningIn(props) {
    const { classes } = props;

    const [expanded, setExpanded] = useState(false);

    function help(event) {
        event.preventDefault();

        setExpanded(!expanded);
    }

    function forgotPassword(event) {
        event.preventDefault();

        const url = AppSettings.idrm.forgotPassword;

        const fullUrl = `${url}?BACKURL=${window.location.origin}`; // take back to home page

        window.location.assign(fullUrl);
    }

    function forgotUsername(event) {
        event.preventDefault();

        const url = AppSettings.idrm.forgotUsername;

        const fullUrl = `${url}?BACKURL=${window.location.origin}`; // take back to home page

        window.location.assign(fullUrl);
    }

    return (
        <React.Fragment>
            <Box mt={1} display="flex" justifyContent="flex-end">
                <Link component="button" variant="subtitle1" onClick={help}>
                    <Box display="flex" alignItems="center"><ContactSupportIcon className={classes.helpIcon} />Help Signing In</Box>
                </Link>
            </Box>
            {expanded &&
                <React.Fragment>
                    <Box mt={1} display="flex" justifyContent="flex-end">
                        <Link component="button" variant="body1" onClick={forgotPassword}>
                            Forgot Password
                        </Link>
                    </Box>
                    <Box mt={1} display="flex" justifyContent="flex-end">
                        <Link component="button" variant="body1" onClick={forgotUsername}>
                            Forgot Username
                        </Link>
                    </Box>
                </React.Fragment>
            }
        </React.Fragment>
    );
}

export default HelpSigningIn;