import { Checkbox, CircularProgress, Collapse, ListItem, TextField } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { StringUtil } from 'Util/Helpers';
import useDebounce from 'Util/UseDebounce';

const MultiSelectAutoComplete = forwardRef((props, ref) => {

    const { openPopup, options, optionIdProp, optionLabelProp, groupByProp, textFieldLabel, onChangeEventHandler, onSearchEventHandler } = props;

    const [showHideGroup, setShowHideGroup] = useState({});
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [changeCount, setChangeCount] = useState(0);
    const searchInputRef = useRef();
    const debouncedSearch = useDebounce(changeCount, 2000);
    const autoCompleteRef = useRef();

    useEffect(() => {
        if (options) {
            const grps = options.map(o => o[groupByProp]).reduce((x, y) => x.includes(y) ? x : [...x, y], []);
            if (grps) {
                var distinctGroups = [...grps];

                distinctGroups.map(g => setShowHideGroup(prevState => ({ ...prevState, [g]: true })));
            }
        }
        setOpen(openPopup);
    }, [openPopup, options]);

    useEffect(() => {
        if (changeCount == 0)
            return;

        if (onSearchEventHandler) {
            setLoading(true);
            onSearchEventHandler(searchInputRef.current.value).then(result => {
                setLoading(false);
            });
        }

    }, [debouncedSearch]);

    //Exposing the function to the parent
    useImperativeHandle(ref, () => ({
        clearSearch() {
            autoCompleteRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click(); //need to figure out a better approach        
        }
    }));

    return (
        <Autocomplete
            //freeSolo
            ref={autoCompleteRef}
            multiple
            disableCloseOnSelect
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            loading={loading}
            getOptionLabel={(option) => option[optionLabelProp]}
            // getOptionSelected={(option, value) => StringUtil.isEqual(option[optionLabelProp], value[optionLabelProp])}
            getOptionSelected={(option, value) => StringUtil.isEqual(option[optionIdProp], value[optionIdProp])}

            groupBy={(option) => option[groupByProp]}
            // options={options.sort((a, b) => StringUtil.isEqual(-b[optionLabelProp], a[optionLabelProp]))} //Sort to fix MUI Autocomplete duplicate headers
            options={options.sort((a, b) => StringUtil.compareOrdinality(a[groupByProp], b[groupByProp]))} //Sort to fix MUI Autocomplete duplicate headers

            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox checked={selected} />
                    {option[optionLabelProp]}
                </React.Fragment>
            )}

            renderGroup={option =>
                <React.Fragment key={option.key}>
                    {groupByProp &&
                        <ListItem button
                            onClick={event => setShowHideGroup(prevState => ({ ...prevState, [option.group]: !prevState[option.group] }))}>
                            {showHideGroup[option.group] ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                            {option.group}
                        </ListItem>
                    }
                    <Collapse in={showHideGroup[option.group]} style={{ whiteSpace: "pre-wrap" }}>
                        {option.children}
                    </Collapse>
                </React.Fragment>
            }

            renderInput={(params) =>
                <TextField
                    label={textFieldLabel}
                    {...params}
                    //variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress size={20} /> : <SearchIcon />}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    inputRef={searchInputRef}
                    onChange={event => setChangeCount(prevState => prevState + 1)} />}
            onChange={onChangeEventHandler} // prints the selected value        
        />
    );
});

export default MultiSelectAutoComplete;