import { SectionHeader } from 'Components/Questionary';
import React, { Fragment } from 'react';
import { StringUtil } from 'Util/Helpers';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';

function ClosingInstructions(props) {
    const { questionary } = props;

    const stips = QuestionaryHelper.getStipulations(questionary);

    const showSection = stips.some((s) => StringUtil.isEqual(s.Section, "closingInstructions"));

    if (!showSection)
        return null;

    return (
        <Fragment>
            <SectionHeader section="closingInstructions" title="Closing Instructions" questionary={questionary} subSection />
        </Fragment>
    );
}

export default ClosingInstructions;