const BackgroundCheckTypeEnum = {
    PinCheckOnPolicies: "PinCheckOnPolicies",
    PinCheckOnDirectory: "PinCheckOnDirectory",
    LawyerCheck: "LawyerCheck",
    NameChecksOnSupplementalDb: "NameChecksOnSupplementalDb"
};

const BackgroundCheckContext = {
    Retry: "Retry",
}

export { BackgroundCheckTypeEnum, BackgroundCheckContext };