import { Button, FormControl, FormGroup, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CheckBoxOptionGroup from 'Components/CheckBoxOptionGroup';
import SyntheticEvent from 'Components/SyntheticEvent';
import withFieldStateBindings from 'Components/withFieldStateBindings';
import { withOnChangeDebounced } from 'Components/withOnChangeDebounced';
import withTwoWayFieldStateBindings from 'Components/withTwoWayFieldStateBindings';
import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';
import { StringUtil } from 'Util/Helpers';

const useStyles = makeStyles(theme => ({
    shortTextField: {
        width: '24ch',
        paddingRight: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
}));

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
}

const CheckBoxOptionGroupBound = withFieldStateBindings(CheckBoxOptionGroup);
const TextFieldBound = withFieldStateBindings(withTwoWayFieldStateBindings(withOnChangeDebounced(TextField)));

function PropertyPINs(props) {
    const classes = useStyles();
    const { qId, indexer, twoWayStateResolver, optionGroupProps, readOnly, other } = props;

    const pinsFieldName = `${indexer}${qId}Collection`;
    const pinsProps = { ...optionGroupProps(pinsFieldName) };
    let opts = optionGroupProps(pinsFieldName).defaultValue;

    if (StringUtil.isNullOrEmpty(opts) || opts.length === 0)
        opts = new Array(1).fill('');

    const [pins, setPINs] = useState(opts);

    const naFieldName = `${indexer}${qId}NA`;
    const naProps = { ...optionGroupProps(naFieldName) };
    const naChecked = StringUtil.isEqual(naProps.defaultValue, 'yes');

    const [disablePIN, setDisablePIN] = useState(naChecked);
    const [formKey, setFormKey] = useState("defaultPinValue");

    const isDisabled = disablePIN || readOnly;

    function naChange(e) {
        e.persist();

        if (readOnly)
            return;

        const value = StringUtil.isEqual(e.target.value, 'yes') ? 'yes' : null;

        const ev = new SyntheticEvent(naFieldName, value);
        naProps.onChange(ev);

        setDisablePIN(false);
        if (value === 'yes')
            setDisablePIN(true);
    }

    function add() {
        let p = [...pins, ''];

        const ev = new SyntheticEvent(pinsFieldName, p);
        pinsProps.onChange(ev);

        setPINs(p);
    }

    function remove(index) {
        let p = [...pins];
        p.splice(index, 1);

        const ev = new SyntheticEvent(pinsFieldName, p);
        pinsProps.onChange(ev);

        setFormKey(k => k + '-changed');
        setPINs(p);
    }

    const onPINChange = index => event => {
        event.persist();

        let p = [...pins];
        p[index] = event.target.value;

        const ev = new SyntheticEvent(pinsFieldName, p);
        pinsProps.onChange(ev);

        setPINs(p);
    }

    return (
        <FormControl name={qId} area-label={qId}>
            {pins.map((x, index) => {
                const textField = <TextFieldBound
                    key={formKey}
                    name={pinsFieldName}
                    className={classes.shortTextField}
                    defaultValue={x}
                    onChange={onPINChange(index)}
                    twoWayStateResolver={twoWayStateResolver}
                    InputProps={{
                        readOnly: isDisabled,
                        inputComponent: TextMaskCustom
                    }}
                    disabled={disablePIN}
                    {...other}
                />;

                if (index === 0)
                    return <FormGroup key={index} row>
                        {textField}
                        <CheckBoxOptionGroupBound
                            opts={[{ Id: `yes`, Label: "PIN not assigned" }]}
                            {...naProps}
                            onChange={naChange}
                            {...other}
                        />
                        <Button
                            type="button"
                            size="small"
                            startIcon={<AddCircleOutlineIcon />}
                            disabled={isDisabled}
                            onClick={add}>
                            Add
                            </Button>
                    </FormGroup>;

                return <FormGroup key={index} row>
                    {textField}
                    <Button
                        type="button"
                        size="small"
                        startIcon={<RemoveCircleOutlineIcon />}
                        disabled={isDisabled}
                        onClick={() => { remove(index) }}>
                        Remove
                        </Button>
                </FormGroup>;
            })}
        </FormControl>
    );
}

export default PropertyPINs;