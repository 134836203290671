import * as UserActions from 'User/State/UserActions';
import { AuthApiClient } from 'Auth/AuthApiClient';
import Logger from 'Util/Logger';

const logger = new Logger("UserThunks");

export function loadUserProfile(isAuthenticated) {
    return async function (dispatch, getState) {
        if(!isAuthenticated) {
            dispatch({ type: UserActions.set_user_info.type, payload: null });

            return;
        }

        dispatch({ type: UserActions.set_user_info_load_status.type, payload: { status: 'loading' } });
        
        const resp = await AuthApiClient.loadUserProfile();

        if (resp.hasError) {
            dispatch({ type: UserActions.set_user_info_load_status.type, payload: { status: 'error', errorMessage: resp.errorMessage } });

            return;
        }

        const up = resp.data;

        dispatch({ type: UserActions.set_user_info.type, payload: up });
    }
}