import { Box, makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Question, SectionHeader } from 'Components/Questionary';
import { Clerk, Lawyer, UnderwritingManager } from 'Model/UserRoles';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import StringUtil from 'Util/StringUtil';

const useStyles = makeStyles(theme => ({
    stipDivider: {
        margin: theme.spacing(1, 3, 0, 0)
    },
    container: {
        margin: theme.spacing(3, 1.5, 0, 1.5),
        padding: theme.spacing(0, 1.5, 1, 1.5),
        border: `${1}px solid`,
        borderColor: grey[400],
        borderRadius: 5
    },
    centerAlignText: {
        textAlign: 'center'
    },
    fixSpacing: {
        marginTop: 9
    }

}));

function createOrderSelector() {
    return createSelector(
        state => state,
        x => x
    );
}

function CertificateOfTitleSection(props) {
    const classes = useStyles();

    const { questionary, questionParams, getInitialAnswer, userInfo } = props;

    const selectOrder = useMemo(
        createOrderSelector,
        []
    );
    
    let solicitor = useSelector(x => selectOrder(x.order.orderChanges.SolicitorName));
    const hasPurchaseTransaction = useSelector(x => selectOrder(x.order.orderChanges.HasPurchaseTransaction));

    if (!userInfo)
        return null;

    if (StringUtil.isNullOrEmpty(solicitor))
        solicitor = '{solicitor name}';

    const role = userInfo.Role;

    const isLawyer = StringUtil.isEqual(role, Lawyer);
    const isClerk = StringUtil.isEqual(role, Clerk);
    const isParalegal = StringUtil.isEqual(role, "Paralegal");
    const isUnderwritingManager = StringUtil.isEqual(role, UnderwritingManager);

    let certificateDescription = `To: Lawyers’ Professional Indemnity Company (LAWPRO)

I confirm that:

        •	I am a lawyer in good standing with my Law Society, entitled to act on this transaction;
        •	I have investigated title and other matters relating to the insured real property as prescribed by my Law Society, except for those that you do not require;
        •	In my opinion, insofar as those investigations revealed, each insured party will obtain a good and marketable interest in the property as described in Schedule A, subject to any exceptions in Schedule B;
        •	I have disclosed all matters that would otherwise qualify my opinion;
        •	I will advise you of any additional title registrations or material changes to the information submitted that I become aware of before closing; and
        •	I will disclose all exceptions in Schedule B to the purchaser, mortgagor and/or mortgage lender, as applicable;`;

    if (isClerk || isParalegal || isUnderwritingManager)
        certificateDescription = `I ${userInfo.FirstName} ${userInfo.LastName} have authority to give this Certificate of Title on behalf of lawyer ${solicitor}.
        
${certificateDescription}`;

    if (hasPurchaseTransaction)
        certificateDescription = `${certificateDescription}
        •	I will have the purchaser(s) sign the TitlePLUS Acknowledgment.`;

    return (
        <React.Fragment>
            <SectionHeader section="acknowledgement" title="Acknowledgement" questionary={questionary} subSection iconImgPath={"/img/certificate-of-title.svg"} />
            <Box className={classes.container}>
                <Box className={`${classes.centerAlignText}`}>
                    <SectionHeader section="certificateOfTitle" title="Certificate of Title" questionary={questionary} subSection />
                </Box>
                <Typography component="pre">
                    {certificateDescription}
                </Typography>
                <Question qId="CertificateOfTitleAcknowledgement" {...questionParams} indexer={null} hideLabel />
            </Box>
        </React.Fragment>
    );
}

export default CertificateOfTitleSection;