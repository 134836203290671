import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone'
import { grey } from '@material-ui/core/colors';
import LinearProgress from '@material-ui/core/LinearProgress';
import { green } from '@material-ui/core/colors';
import { ApiHelper } from 'Util/ApiHelper';
import { Box, TableBody, Table } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import UploadedFile from './UploadedFile';
import Logger from 'Util/Logger';
import { unionBy } from "lodash";
import { StringUtil } from 'Util/Helpers';
import FileView from './FileView';
import ErrorSnackbar from 'Components/ErrorSnackbar';
import clsx from 'clsx';

const logger = new Logger(Dropzone.name);

const useStyles = makeStyles((theme) => ({
    progressBar: {
        width: '100%',
        marginTop: 2
    }
}));

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0.5rem',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: grey[400],
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: grey[500],
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const LinearProgressStyled = withStyles({
    colorPrimary: {
        backgroundColor: green[200]
    },
    barColorPrimary: {
        backgroundColor: green[500]
    },
})(LinearProgress);

function UploadProgress() {
    const classes = useStyles();
    const [completed, setCompleted] = useState(0);

    useEffect(() => {
        let isCancelled = false;

        function progress() {
            if (!isCancelled) {
                setCompleted((oldCompleted) => {
                    if (oldCompleted === 100) {
                        return 0;
                    }
                    const diff = Math.random() * 10;
                    return Math.min(oldCompleted + diff, 100);
                });
            }
        }

        const timer = setInterval(progress, 500);

        return () => {
            clearInterval(timer);
            isCancelled = true;
        };
    }, []);

    return (
        <div className={classes.progressBar}>
            <LinearProgressStyled variant="determinate" value={completed} />
        </div>
    );
}

function Dropzone(props) {
    const { uploadUrls, readOnly, containerClass } = props;

    const {
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({ accept: 'image/*, .pdf', onDrop: handleOnDrop, disabled: readOnly });

    const [loading, setLoading] = useState(true);
    const [loadError, setLoadError] = useState('');
    const [busy, setBusy] = useState(false);
    const [files, setFiles] = useState([]);
    const [filesFailed, setFilesFailed] = useState([]);
    const [fileViewOpen, setFileViewOpen] = useState(false);
    const [fileViewLoading, setFileViewLoading] = useState(false);
    const [fileToView, setFileToView] = useState();

    const { t } = useTranslation();

    const classes = useStyles();

    const theme = useTheme();

    useEffect(() => {
        let isCancelled = false;

        async function load() {
            var fileListResp = await ApiHelper.apiGet(uploadUrls.list);

            if (!isCancelled) {
                setLoading(false);
            }

            if (fileListResp.hasError) {
                if (!isCancelled) {
                    setLoadError(fileListResp.errorMessage)
                }

                return;
            }

            const uploadedArr = [];

            for (const f of fileListResp.data.Uploaded) {
                uploadedArr.push({
                    name: f.Filename,
                    size: f.Length
                });
            }

            const newFileSet = unionBy(uploadedArr, files, "name");

            if (!isCancelled) {
                setLoading(false);
                setFiles(newFileSet);
            }
        }

        load();

        return () => {
            isCancelled = true;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function handleOnDrop(acceptedFiles, rejectedFiles, event) {
        if (readOnly) {
            return;
        }

        setBusy(true);

        const promiseArray = [];

        for (const f of acceptedFiles) {
            logger.debug(`uploading ${f.name} ${f.size} ${f.type} via pre-signed resolver ${uploadUrls.upload}`);

            promiseArray.push(ApiHelper.uploadFileViaS3PreSigned(f, uploadUrls.upload));
        }

        const resultArr = await Promise.all(promiseArray);

        setBusy(false);

        const uploadedArr = [];
        const failedArr = [];

        for (const uploadResult of resultArr) {
            if (uploadResult.hasError) {
                uploadResult.file.uploadError = uploadResult.errorMessage;

                failedArr.push(uploadResult.file);
            }
            else {
                uploadedArr.push(uploadResult.file);
            }
        }

        const newFileSet = unionBy(uploadedArr, files, "name");
        const newFailedSet = unionBy(failedArr, filesFailed, "name");

        setFiles(newFileSet);
        setFilesFailed(newFailedSet);
    }

    async function handleView(event, file) {
        event.preventDefault();

        setFileViewLoading(true);
        setFileViewOpen(true);

        const viewUrl = `${uploadUrls.view}&filename=${file.name}`

        const viewUrlResp = await ApiHelper.apiGet(viewUrl);

        setFileViewLoading(false);

        const tmpFile =
        {
            name: file.name,
            size: file.size,
            type: file.type
        };

        if (viewUrlResp.hasError) {
            tmpFile.viewError = viewUrlResp.errorMessage;
        }
        else {
            tmpFile.viewUrl = viewUrlResp.data.Url;
        }

        setFileToView(tmpFile);
    }

    async function handleDelete(event, file) {
        const found = files.find(f => StringUtil.isEqual(f.name, file.name));

        if (StringUtil.isNullOrEmpty(found)) {
            return;
        }
    }

    async function handleDeleteForFailed(event, file) {
        const found = filesFailed.find(f => StringUtil.isEqual(f.name, file.name));

        if (StringUtil.isNullOrEmpty(found)) {
            return;
        }

        const filtered = filesFailed.filter(f => f !== found);

        setFilesFailed(filtered);

        return;
    }

    function handleFileViewClose(event) {
        setFileViewOpen(false);
        setFileViewLoading(false);
        setFileToView(null);
    }

    const filesList = files.map(file => (
        <UploadedFile key={file.name} file={file} readOnly={readOnly} handleView={handleView} handleDelete={handleDelete} />
    ));

    const filesListFailed = filesFailed.map(file => (
        <UploadedFile key={file.name} file={file} handleDelete={handleDeleteForFailed} />
    ));

    let uploadMsg = "Drag 'n' drop files here, or click here to select files to upload";

    if (isDragActive) {
        uploadMsg = "Drop the files here ...";
    }
    else if (readOnly) {
        uploadMsg = "Upload disabled";
    }

    const containerClassName = clsx("container", containerClass);

    return (
        <section className={containerClassName}>
            <div {...getRootProps({ style: baseStyle })}>
                <input {...getInputProps()} />
                {uploadMsg}
            </div>
            {loading &&
                <div className={classes.progressBar}>
                    <LinearProgress variant="indeterminate" />
                </div>
            }
            {busy &&
                <UploadProgress />
            }
            {!StringUtil.isNullOrEmpty(loadError) &&
                <ErrorSnackbar message={t('failed_to_retrieve_uploaded_docs')} centered={true} variant="error" mt={0.5} p={0.5} />
            }
            {filesFailed.length > 0 &&
                <div style={{ color: theme.palette.error.main }}>
                    <Box mt={1.3}><strong>Errors</strong></Box>
                    <Table aria-label="Errors list" size="small" style={{ width: "auto" }}>
                        <TableBody>
                            {filesListFailed}
                        </TableBody>
                    </Table>
                </div>
            }
            {files.length > 0 &&
                <div>
                    <Box mt={1.3}><strong>Uploaded</strong></Box>
                    <Table aria-label="Uploaded files list" size="small" style={{ width: "auto" }}>
                        <TableBody>
                            {filesList}
                        </TableBody>
                    </Table>
                </div>
            }
            <FileView open={fileViewOpen} loading={fileViewLoading} file={fileToView} handleClose={handleFileViewClose} />
        </section>
    );
}

export default Dropzone;