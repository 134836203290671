import * as PolicyActions from './State/PolicyActions';

class PolicyViewHelper {
    po;
    dispatch;

    constructor(po, dispatch) {
        this.po = po;
        this.dispatch = dispatch;
    }

    getDealIdFromQueryString() {
        const searchParams = new URLSearchParams(window.location.search);
        const secureId = searchParams.get("id");

        return secureId;
    }

    _mapCollection(collection) {
        let result = [];

        if (collection && collection.length > 0) {
            result = collection.map((item, index) => {
                const copy = { ...item, ItemNumber: index + 1 };

                return copy;
            });
        }

        return result;
    }

    setNewOrderData(po) {
        this.po = po;

        const orderData = {
            properties: this._mapCollection(po.Properties),
            purchasers: this._mapCollection(po.Purchasers),
            mortgages: this._mapCollection(po.Mortgages),
            remainingMortgages: this._mapCollection(po.RemainingMortgages),
            nhpCondoLegalDescriptions: this._mapCollection(po.NHPCondoLegalDescriptions),
            exceptions: po.Exceptions,
            policyMap: po.PolicyMap,
            premium: po.Premium,
            po
        };

        this.dispatch({ type: PolicyActions.set_new_order.type, payload: orderData });
    }
}

export default PolicyViewHelper;