import * as actions from './UserActions';
import { createReducer } from '@reduxjs/toolkit';
import { StringUtil } from 'Util/Helpers';

const initialState = {
    userInfoLoadStatus: { status: 'not_loaded' },
    userInfo: null,
    userAssociations: []
};

function mapCollection(collection) {
    let result = [];

    if (collection && collection.length > 0) {
        result = collection.map((item, index) => {
            const copy = { ...item, ItemNumber: index + 1 };

            return copy;
        });
    }

    return result;
}

const UserReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(actions.set_user_info_load_status, (state, action) => {
            state.userInfoLoadStatus = action.payload;
        })
        .addCase(actions.set_user_info, (state, action) => {
            const ui = action.payload;
            
            state.userInfo = ui;

            if(ui) {
                state.userInfoLoadStatus = { status: 'loaded', userStatus: ui.Status }
                
                const associations = mapCollection(ui.UserAssociations);

                state.userAssociations = associations;
            }
            else
            {
                state.userInfoLoadStatus = { status: 'disposed' }; // we explicitly cleaned it...
                state.userAssociations = [];
            }
        })
        .addCase(actions.update_user_status, (state, action) => {
            if(!state.userInfo)
                return;

            const newUserStatus = action.payload;

            state.userInfo.Status = newUserStatus;
            state.userInfoLoadStatus.userStatus = newUserStatus;
        })
        .addCase(actions.update_user_role, (state, action) => {
            if(!state.userInfo)
                return;

            state.userInfo.Role = action.payload;
        })
        .addCase(actions.set_associations, (state, action) => {
            state.userAssociations = action.payload;  
        })
        .addCase(actions.add_user_link_invitation, (state, action) => {
            state.userAssociations.push(action.payload);
        })
        .addCase(actions.remove_user_link_invitation, (state, action) => {
            const item = action.payload;

            state.userAssociations = state.userAssociations.filter(x => !StringUtil.isEqual(x.Id, item.Id));
        })
        .addDefaultCase((state, action) => { })
});

export default UserReducer;