import { createAction } from '@reduxjs/toolkit'

export const reset = createAction('registration/reset');
export const setUserInput = createAction('registration/setUserInput');
export const clearValidationError = createAction('registration/clearValidationError');
export const setValidationResult = createAction('registration/setValidationResult');
export const setError = createAction('registration/setError');
export const clearError = createAction('registration/clearError');
export const setBusy = createAction('registration/setBusy');
export const removeAssociation = createAction('registration/removeAssociation');
export const sendUserToSetPayment = createAction('registration/sendUserToSetPayment');