import * as actions from './RegistrationActions';
import { createReducer } from '@reduxjs/toolkit';
import { StringUtil } from 'Util/Helpers';
import { SectionHelper } from 'Util/SectionHelper';


const initialState = {
    userInput: {},
    formState: {},
    removedUserAssociations: [],
    isBusy: false,
    errorMsg: '',
    redirectToPayment: false
}

const RegistrationReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(actions.reset, (state, action) => {
            state.userInput = {};
            state.formState = {};
            state.removedUserAssociations = [];
            state.isBusy = false;
            state.errorMsg = '';
            state.redirectToPayment = false;
        })
        .addCase(actions.setUserInput, (state, action) => {
            const { fieldName, fieldValue } = action.payload;

            if (StringUtil.endsWith(fieldName, '.IsDeleted')) {
                const fieldsToRemove = SectionHelper.getSectionsAnswersToRemove(fieldName, state.userInput);

                if(fieldsToRemove.length > 0) {
                    for(const fieldToRemove of fieldsToRemove) {
                        delete state.userInput[fieldToRemove];
                    }
                }
            }

            const currValue = state.userInput[fieldName];

            if (currValue === fieldValue) {
                return; // do not change anything...
            }

            state.userInput[fieldName] = fieldValue;
        })
        .addCase(actions.setValidationResult, (state, action) => {
            const { validationMap } = action.payload;

            if (!validationMap) {
                return;
            }

            const formStateUpdate = {};

            // fieldName should alreay be lowercase ...
            for (const fieldName of Object.keys(validationMap)) {
                let fieldState = state.formState[fieldName];
                const validationError = validationMap[fieldName];
                const isError = StringUtil.isEqual(validationError.Severity, "error");

                let newState;

                if (fieldState) {
                    newState = { ...fieldState };
                }
                else {
                    newState = { name: fieldName };
                }

                newState.error = isError;
                newState.helperText = validationError.ErrorMessage;

                formStateUpdate[fieldName] = newState;
            }

            state.formState = { ...state.formState, ...formStateUpdate };
        })
        .addCase(actions.clearValidationError, (state, action) => {
            const { fieldName } = action.payload;

            if (!fieldName) {
                return;
            }

            const fieldNameLower = fieldName.toLowerCase();

            const fieldState = state.formState[fieldNameLower];

            if (!fieldState) { return; }

            const newState = { ...fieldState };

            newState.error = false;
            newState.helperText = '';

            state.formState[fieldNameLower] = newState;
        })
        .addCase(actions.setError, (state, action) => {
            const newErrMsg = action.payload;

            state.errorMsg = newErrMsg;
        })
        .addCase(actions.clearError, (state, action) => {
            state.errorMsg = '';
        })
        .addCase(actions.setBusy, (state, action) => {
            const isBusy = action.payload;

            state.isBusy = isBusy;
        })
        .addCase(actions.sendUserToSetPayment, (state, action) => {
            const redirect = action.payload;

            state.redirectToPayment = redirect;
        })
        .addCase(actions.removeAssociation, (state, action) => {
            const assos = action.payload;

            state.removedUserAssociations.push(assos);
        })
        .addDefaultCase((state, action) => { })
});

export default RegistrationReducer;