import React, { useState } from 'react';
import { StringUtil } from 'Util/Helpers';
import { DatePicker } from "@material-ui/pickers";
import SyntheticEvent from 'Components/SyntheticEvent';
import { cloneDeep } from 'lodash';

export default function DateControl(props) {
    const { q, fieldName, onChange, defaultValue, ...other } = props;

    let minDate, maxDate;

    let initialValue = defaultValue;

    if (StringUtil.isNullEmptyOrWhiteSpace(initialValue)) {
        initialValue = null;
    }

    const [value, setValue] = useState(initialValue);

    if (!StringUtil.isNullOrEmpty(q.MinDateInDays)) {
        const d = new Date();
        d.setDate(d.getDate() - q.MinDateInDays);

        minDate = d;
    }

    if (!StringUtil.isNullOrEmpty(q.MaxDateInDays)) {
        const d = new Date();
        d.setDate(d.getDate() + q.MaxDateInDays);

        maxDate = d;
    }

    function invokeOnChange(val) {
        const _val = cloneDeep(val);
        setValue(_val);

        if (!onChange) {
            return;
        }

        const ev = new SyntheticEvent(fieldName, val);

        onChange(ev);
    }

    // this has to be a controlled component
    return <DatePicker
        autoOk={true}
        clearable={true}
        onChange={invokeOnChange}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        format="MMMM DD, yyyy"
        {...other}
    />
}