import PolicyReducer from 'Policy/State/PolicyReducer';
import UserReducer from 'User/State/UserReducer';
import RegistrationReducer from 'User/State/RegistrationReducer';
import TPStaffUsersSlice from 'Admin/State/TPStaffUsersSlice';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    order: PolicyReducer,
    user: UserReducer,
    userRegistration: RegistrationReducer,
    tpStaffUsers: TPStaffUsersSlice
});

export default rootReducer;