import React, { useState, Fragment, useMemo } from 'react';
import { Grid, Divider, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, CardContent, CardActions, Button } from '@material-ui/core';
import { StringUtil } from 'Util/Helpers';
import { useTranslation } from "react-i18next";
import { grey } from '@material-ui/core/colors';
import OrderStatus from './OrderStatus';
import PolicyTags from 'Admin/PolicyTags';
import OwnerInfo from 'Admin/OwnerInfo';
import { DateFormatUtil } from 'Util/DateFormatUtil';
import RegistrationStatus from './RegistrationStatus';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import PremiumInfo from './Sections/PremiumSection/PremiumInfo';

const useStyles = makeStyles(theme => ({
    pricingInfo: {
        top: 70,
        // Fix IE 11 position sticky issue.
        marginTop: 70,
        width: 208,
        flexShrink: 0,
        order: 2,
        position: 'sticky',
        height: 'calc(100vh - 70px)',
        overflowY: 'auto',
        padding: theme.spacing(2, 2, 2, 0),
        display: 'none',
        [theme.breakpoints.up('xs')]: {
            display: 'block',
        },
    },
    bolder: {
        fontWeight: 500
    },
    orderStatusPill: {
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        padding: '8px 16px',
        backgroundColor: grey[100]
    },
    orderStatus: {
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: 14
    },
    lastUpdatedBy: {
        marginTop: theme.spacing(1.5),
        color: grey[700]
    },
    iconRoot: {
        marginLeft: theme.spacing(0.5),
        width: theme.spacing(3),
        height: theme.spacing(3.5)
        //marginLeft: theme.spacing(2)
    },
    imageIcon: {
        filter: `invert(36%) sepia(42%) saturate(2448%) hue-rotate(335deg) brightness(95%) contrast(103%)`,
        verticalAlign: 'middle',
        marginRight: '5px',
        width: theme.spacing(3),
        height: theme.spacing(3.5)

    },
    registrationStatus: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'table',
        marginBottom: '5px',
        //display: 'inline-flex',
        //border: '1px solid #ddd',
        alignItems: 'center'
    }
}));

function createOrderVolatileDataSelector() {
    return createSelector(
        state => state.order.orderVolatileData,
        (_, propName) => propName,
        (x, propName) => x[propName]
    );
}

function OrderStatusBound(props) {
    const { classes } = props;

    const selectOrderVolatileData = useMemo(
        createOrderVolatileDataSelector,
        []
    );

    const orderStatus = useSelector(x => selectOrderVolatileData(x, "Status"));

    return (
        <OrderStatus status={orderStatus} className={classes.orderStatus} />
    );
}

function PremiumSynopsis(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        po
    } = props;

    const isNew = StringUtil.isEqual(po.SecureId, 'new');

    const showLastUpdated = !isNew;
    const showAdminInfo = !isNew;

    return (
        <nav>
            <Card>
                <PremiumInfo {...props} />
                
                {!StringUtil.isNullOrEmpty(po) &&
                    <Fragment>
                        <Divider variant="middle" />
                        <CardContent>
                            <Paper elevation={0} className={classes.orderStatusPill}>
                                <OrderStatusBound classes={classes} />
                            </Paper>
                            {showLastUpdated &&
                                <Typography variant="body2" className={classes.lastUpdatedBy}><span className={classes.bolder}>Last updated: </span>by {po.CreatedByName} {DateFormatUtil.relativeToNowCal(po.LastUpdatedOn)}</Typography>
                            }
                        </CardContent>
                        {showAdminInfo &&
                            <Fragment>
                                <Divider variant="middle" />
                                <CardContent>
                                    <PolicyTags {...props} />
                                    <OwnerInfo {...props} />
                                </CardContent>
                            </Fragment>
                        }
                        {po.RegistrationStatus &&
                            <RegistrationStatus status={po.RegistrationStatus} className={classes.orderStatus} />
                        }
                    </Fragment>
                }
            </Card>
        </nav>
    );
}

export default PremiumSynopsis;