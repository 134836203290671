import Logger from 'Util/Logger';
import { StringUtil, WebStorageHelper } from 'Util/Helpers';

class DealsListFilterSettings
{
    logger;
    
    constructor()
    {
        this.logger = new Logger(DealsListFilterSettings.name);
    }
    
    _resolveUserDefault() {                
        const now = new Date();


        const goBack = new Date(now);
        goBack.setDate(goBack.getDay() - 60);

        const future = new Date(now);
        future.setDate(future.getDay() + 365); // when buying from builder u may have closing date 1 yr into future. possible.

        // hard-code for now. Same value for all users.
        const settings = {
            Page: 1,
            PageSize: 15,
            OrderBy: 'LastUpdatedOn',
            SortDirection: 'desc',
            ClosingDate: [goBack, now],
            LastUpdatedOn: [goBack, now]
        };

        return settings;
    }

    getLastUsedFilter() {
        let lastFilter;
        
        const lastFilterJson = WebStorageHelper.getItem('status_page', 'last_filter');

        if (!StringUtil.isNullOrEmpty(lastFilterJson)) {
            try {
                lastFilter = JSON.parse(lastFilterJson);
                
                const userDefaultFilter = this._resolveUserDefault();

                if(!lastFilter.ClosingDateChanged) {
                    lastFilter.ClosingDate = userDefaultFilter.ClosingDate;
                }
                if(!lastFilter.LastUpdatedOnChanged)
                {
                    lastFilter.LastUpdatedOn = userDefaultFilter.LastUpdatedOn;
                }
            }
            catch (err) {
                this.logger.error('error deserializing last filter', err);
            }
        }
        else
        {
            lastFilter = this._resolveUserDefault();
        }

        return lastFilter;
    }

    saveLastUsedFilter(filter) {        
        WebStorageHelper.setItem('status_page', 'last_filter', JSON.stringify(filter));
    }
}

const instance = new DealsListFilterSettings();

export { instance as DealsListFilterSettings }