import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Badge, Icon } from '@material-ui/core';
import { StringUtil } from 'Util/Helpers';
import { useTranslation } from "react-i18next";
import { green, red, grey } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { OrderStatusEnum } from 'Model/PolicyOrder';

export const styles = (theme) => {
    const s = {
        root: {
        },
        iconRoot: {
            marginLeft: theme.spacing(0.5)
        },
        imageIcon: {
            height: '100%'
        },
    };

    s[OrderStatusEnum.Approved] =
    {
        color: green[800],
        display: 'flex',
        alignItems: 'center'
    };

    s[OrderStatusEnum.UnderReview] =
    {
        display: 'flex',
        alignItems: 'center'
    };

    s[OrderStatusEnum.Declined] = {
        color: red[700]
    };

    s[OrderStatusEnum.Cancelled] = {
        color: red[700],
        display: 'flex',
        alignItems: 'center'
    };

    s[OrderStatusEnum.Deleted] = {
        color: grey[700],
        display: 'flex',
        alignItems: 'center'
    };

    return s;
};

function OrderStatus(props) {
    const { t } = useTranslation();

    const { status, classes, className } = props;

    let statusText = t(status);

    const textClassName = clsx(classes.root, classes[status], className);

    if (StringUtil.isEqual(status, OrderStatusEnum.UserActionRequired)) {
        return (
            <Badge color="error" variant="dot">
                <span className={textClassName}>{statusText}&nbsp;&nbsp;</span>
            </Badge>
        );
    }
    else if (StringUtil.isEqual(status, OrderStatusEnum.UnderReview)) {
        return (
            <span className={textClassName}>
                {statusText}
                <Icon classes={{ root: classes.iconRoot }}>
                    <img className={classes.imageIcon} src="/img/under_review.svg" alt="under review" />
                </Icon>
            </span>
        );
    }
    else if (StringUtil.isEqual(status, OrderStatusEnum.Approved)) {
        return (
            <span className={textClassName}>
                {statusText}
                <CheckCircleIcon className={classes.iconRoot} />
            </span>
        );
    }

    else if (StringUtil.isEqual(status, OrderStatusEnum.Cancelled)) {
        return (
            <span className={textClassName}>
                {statusText}
                <CancelOutlinedIcon className={classes.iconRoot} />
            </span>
        );
    }

    else if (StringUtil.isEqual(status, OrderStatusEnum.Deleted)) {
        return (
            <span className={textClassName}>
                {statusText}
                <DeleteIcon className={classes.iconRoot} />
            </span>
        );
    }

    return (
        <span className={textClassName}>{statusText}</span>
    );
}

OrderStatus.propTypes = {
    status: PropTypes.string
};

export default withStyles(styles)(OrderStatus);