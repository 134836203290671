import PropTypes from 'prop-types';

class SyntheticEvent {
    target;

    constructor(name, value) {
        this.target = { name, value };
    }

    persist() {
        // do nothing
    }
}

SyntheticEvent.propTypes = {
    target: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.object
    })
};

export default SyntheticEvent;