import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Snackbar } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import DoneIcon from '@material-ui/icons/Done';
import SendIcon from '@material-ui/icons/Send';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import UndoIcon from '@material-ui/icons/Undo';
import { StringUtil } from 'Util/Helpers';
import MuiAlert from '@material-ui/lab/Alert';
import { OrderStatusEnum, PermittedOrderActionsEnum } from 'Model/PolicyOrder';
import ViewPolicyPDF from './ViewPolicyPDF';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import * as PolicyActions from './State/PolicyActions';

const useStyles = makeStyles(theme => ({
    btn: {
        margin: theme.spacing(1, 0),
    },
    btnLeftM: {
        margin: theme.spacing(1, 0, 1, 1)
    },
    btnBusyInd: {
        color: green[500],
        marginTop: 12,
        marginLeft: 12,
        marginRight: 12
    }
}));

function createActionMsgSelector() {
    return createSelector(
        state => state.order.actionMsg,
        x => x
    );
}

function Popup() {
    const dispatch = useDispatch();

    const selectActionMsg = useMemo(
        createActionMsgSelector,
        []
    );

    const actionMsg = useSelector(x => selectActionMsg(x));

    const actionResultOpen = !StringUtil.isNullOrEmpty(actionMsg.message);

    const closeActionResult = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch({ type: PolicyActions.clearActionMessage.type });
    };

    return (
        <Snackbar open={actionResultOpen} autoHideDuration={6000} onClose={closeActionResult}>
            <MuiAlert elevation={6} variant="filled" onClose={closeActionResult} severity={actionMsg.type}>
                {actionMsg.message}
            </MuiAlert>
        </Snackbar>
    );
}

function createIsSavingSelector() {
    return createSelector(
        state => state.order.isSaving,
        x => x
    );
}

function createOrderVolatileDataSelector() {
    return createSelector(
        state => state.order.orderVolatileData,
        (_, propName) => propName,
        (x, propName) => x[propName]
    );
}

function PolicyViewActions(props) {
    const classes = useStyles();

    const {
        save,
        changingStatus,
        restore,
        po,
        changeOrderStatus
    } = props;

    const selectOrderVolatileData = useMemo(
        createOrderVolatileDataSelector,
        []
    );

    const selectIsBusy = useMemo(
        createIsSavingSelector,
        []
    );

    const isBusy = useSelector(x => selectIsBusy(x)); // this is used in many places, so the control will re-render if busy ...

    const permittedActions = useSelector(x => selectOrderVolatileData(x, "PermittedActions"));
    const poStatus = useSelector(x => selectOrderVolatileData(x, "Status"));

    const allowSave = StringUtil.contains(permittedActions, PermittedOrderActionsEnum.Save);
    const allowSubmit = StringUtil.contains(permittedActions, PermittedOrderActionsEnum.SubmitOrder);
    const allowChange = StringUtil.contains(permittedActions, PermittedOrderActionsEnum.RevertToUnsubmitted);
    const allowCancel = StringUtil.contains(permittedActions, PermittedOrderActionsEnum.Cancel);
    const allowDelete = StringUtil.contains(permittedActions, PermittedOrderActionsEnum.Delete);
    const allowRestore = StringUtil.contains(permittedActions, PermittedOrderActionsEnum.Restore);

    let submitBtnCaption = "Submit Order";
    let submitBtnIcon = (<SendIcon />);

    if (StringUtil.isEqual(poStatus, OrderStatusEnum.UnderReview)) {
        submitBtnCaption = "Update Order";
        submitBtnIcon = (<DoneIcon />);
    }

    return (
        <React.Fragment>            
            <div style={{ width: '100%' }}>
                <Box display="flex">
                    <Box flexGrow={1}>
                        <span style={{ display: 'inline-flex' }}>
                            {allowDelete && <Button
                                className={classes.btn}
                                type="button"
                                variant="contained"
                                color="secondary"
                                startIcon={<DeleteIcon />}
                                disabled={isBusy}
                                onClick={e => changeOrderStatus(e, OrderStatusEnum.Deleted)}
                            >
                                Delete Order
                            </Button>
                            }
                            {allowCancel &&
                                <Button
                                    className={classes.btn}
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<CancelOutlinedIcon />}
                                    disabled={isBusy}
                                    onClick={e => changeOrderStatus(e, OrderStatusEnum.Cancelled)}
                                >
                                    Cancel Order
                                </Button>
                            }
                            {allowRestore &&
                                <Button
                                    className={classes.btn}
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<UndoIcon />}
                                    disabled={isBusy}
                                    onClick={restore}
                                >
                                    Restore Order
                                </Button>
                            }
                            {allowChange &&
                                <Button
                                    className={classes.btnLeftM}
                                    type="button"
                                    variant="contained"
                                    startIcon={<RestorePageIcon />}
                                    onClick={e => changeOrderStatus(e, OrderStatusEnum.NotSubmitted)}
                                    disabled={isBusy}
                                >
                                    Change Order
                                </Button>
                            }
                            {changingStatus && <CircularProgress size={24} className={classes.btnBusyInd} />}
                        </span>
                    </Box>
                    {allowSave &&
                        <Box>
                            <span style={{ display: 'inline-flex' }}>
                                {isBusy && <CircularProgress size={24} className={classes.btnBusyInd} />}
                                <Button
                                    className={classes.btn}
                                    type="button"
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                    onClick={save}
                                    disabled={isBusy}
                                >
                                    Save
                                </Button>
                            </span>
                        </Box>
                    }
                    {allowSubmit &&
                        <Box pl={1}>
                            <Button
                                className={classes.btn}
                                type="button"
                                variant="contained"
                                color="primary"
                                startIcon={submitBtnIcon}
                                onClick={e => save(e, true)}
                                disabled={isBusy}
                            >
                                {submitBtnCaption}
                            </Button>
                        </Box>
                    }
                </Box>
            </div>
            <Popup />
            <ViewPolicyPDF po={po} />
        </React.Fragment>
    );
}

export default PolicyViewActions;