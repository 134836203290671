import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableSortLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StringUtil } from 'Util/Helpers';

const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

function ColumnHeader(props) {
    const classes = useStyles();

    const { id, align, children, sortDirection, orderBy, sortDirectionBias, orderByChanged } = props;

    let sortDirectionVal = false;

    const isActive = StringUtil.isEqual(orderBy, id);

    if (!StringUtil.isNullOrEmpty(sortDirectionBias) && isActive) {
        sortDirectionVal = sortDirection;
    }

    function invokeOrderByChanged(event) {
        orderByChanged(event, id, sortDirectionBias);
    }

    if (StringUtil.isNullOrEmpty(sortDirectionBias)) {
        return (
            <TableCell
                key={id}
                align={align || 'left'}
            >
                {children}
            </TableCell>
        );
    }

    return (
        <TableCell
            key={id}
            align={align || 'left'}
            sortDirection={sortDirectionVal}
        >
            <TableSortLabel
                active={isActive}
                direction={sortDirectionVal || sortDirectionBias}
                onClick={invokeOrderByChanged}
            >
                {children}
                {isActive ? (
                    <span className={classes.visuallyHidden}>
                        {sortDirectionVal === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                ) : null}
            </TableSortLabel>
        </TableCell>
    );
}

ColumnHeader.propTypes = {
    id: PropTypes.string.isRequired,
    sortDirection: PropTypes.oneOf(['asc', 'desc']),
    sortDirectionBias: PropTypes.oneOf(['asc', 'desc']),
    orderBy: PropTypes.string,
    orderByChanged: PropTypes.func
};

export default ColumnHeader;