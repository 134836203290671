import { IdGenerator } from './IdGenerator';
import { QuestionaryHelper } from './QuestionaryHelper';
import { StringUtil } from './Helpers';
import SyntheticEvent from 'Components/SyntheticEvent';

class SectionHelper {
    addNewSection(collectionName, questionParams, props) {
        const newId = IdGenerator.NewId();

        const itemInfo = {
            Id: newId,
            IsNew: true,
            ...props
        };

        itemInfo.indexer = QuestionaryHelper.createIndexer(collectionName, itemInfo);

        const fieldName = `${itemInfo.indexer}IsNew`;

        const onChange = questionParams.inputProps(fieldName).onChange;

        const ev = new SyntheticEvent(fieldName, true);

        onChange(ev);

        if (props)
            Object.keys(props).forEach(key => {
                const p = `${itemInfo.indexer}${key}`;
                const e = new SyntheticEvent(p, props[key]);
                onChange(e);
            });

        return itemInfo;
    }

    removeSection(props) {
        const { collectionName, item, questionParams } = props;

        const indexer = QuestionaryHelper.createIndexer(collectionName, item);

        const fieldName = `${indexer}IsDeleted`;

        const onChange = questionParams.inputProps(fieldName).onChange;

        const ev = new SyntheticEvent(fieldName, true);

        onChange(ev); // trigger an explicit delete onChange event
    }

    getSectionsAnswersToRemove(fieldName, changes) {
        const parts = fieldName.split('.');
        const collection = parts[0]; // assuming 1 level deep
        const itemId = parts[1];
        const indexer = QuestionaryHelper.createIndexer(collection, { Id: itemId });
        const fieldsToRemove = [];

        for (const f in changes) {
            if (StringUtil.startsWith(f, indexer) && !StringUtil.endsWith(f, '.IsDeleted')) {
                // remove all fields with the item id, except for IsDeleted field
                fieldsToRemove.push(f);
            }
        }

        return fieldsToRemove;
    }

    removeSectionAnswers(fieldName, inputs, setInptus) {
        const parts = fieldName.split('.');
        const collection = parts[0]; // assuming 1 level deep
        const itemId = parts[1];
        const indexer = QuestionaryHelper.createIndexer(collection, { Id: itemId });
        const fieldsToRemove = [];

        for (const f in inputs) {
            if (StringUtil.startsWith(f, indexer) && !StringUtil.endsWith(f, '.IsDeleted')) {
                // remove all fields with the item id, except for IsDeleted field
                fieldsToRemove.push(f);
            }
        }

        if (fieldsToRemove.length > 0) {
            setInptus(x => {
                for (const f in x) {
                    if (fieldsToRemove.includes(f)) {
                        delete x[f];
                    }
                }

                x[fieldName] = true;

                return x;
            });
        }
        else {
            setInptus(x => ({ ...x, [fieldName]: true }));
        }
    }
}

const instance = new SectionHelper();

export { instance as SectionHelper };