import { Box, Paper, Table, TableBody, TableContainer, TableRow, Typography, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import { PolicyTypeEnum } from 'Model/PolicyOrder';
import React, { Fragment } from 'react';
import { StringUtil } from 'Util/Helpers';
import Discounts from './Discounts';
import Fees from './Fees';
import Taxes from './Taxes';
import MuiTableCell from "@material-ui/core/TableCell";

const useStyles = makeStyles((theme) => ({
    stipDivider: {
        //margin: theme.spacing(1, 1, 0, 0)
    },
    defaultSize: {
        minWidth: 400
    },
    alignRight: {
        float: 'right'
    },
    container: {
        padding: theme.spacing(1),
        border: `${1}px solid`,
        borderColor: grey[400],
        borderRadius: 5
    }
}));

const TableCell = withStyles({
    root: {
        borderBottom: "none",
        padding: 0,
        paddingTop: 8,
        paddingLeft: 8
    }
})(MuiTableCell);

function PremiumBreakdown(props) {
    const classes = useStyles();
    const { premium, setBasePremium, discountProps, feeProps, readOnly } = props;

    const getWidth = (val) => `${val.toString().length + 8}ch`;

    const inputProps = {
        InputProps: {
            disableUnderline: readOnly
        },
        readOnly: readOnly
    };

    const readOnlyInputProps = {
        InputProps: {
            disableUnderline: true
        },
        readOnly: true
    };

    return <Fragment>
        <Box className={classes.defaultSize}>
            {premium.BreakDown.map((p, index) => {
                const premium = p.Premium.PremiumAmount;

                return <Box key={index} style={{ marginBottom: 20 }} className={`${classes.container}`}>

                    <TableContainer className={classes.table}>
                        <Table className={classes.table}>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        {/* Policy Number */}
                                        <Typography>{StringUtil.isEqual(p.Type, PolicyTypeEnum.LenderPolicyOnly) ? 'Lender' : 'Owner'} Policy: {p.PolicyNumber}</Typography>
                                    </TableCell>
                                </TableRow>

                                {/* Base Premium */}
                                <TableRow>
                                    <TableCell>
                                        <Typography>Base Premium:</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <CurrencyTextField
                                            defaultValue={premium.BasePremium}
                                            onChange={(event) => setBasePremium(event, index)}
                                            className={classes.alignRight}
                                            outputFormat="string"
                                            {...inputProps}
                                            style={{ width: getWidth(premium.BasePremium) }}
                                        />
                                    </TableCell>
                                </TableRow>

                                {/* Fees */}
                                <Fees fees={p.Fees} policyIdx={index} {...feeProps} readOnly={readOnly} />


                                {/* Pre tax discounts */}
                                <Discounts items={p.Discounts} policyIdx={index} isAppliedAfterTax={false} {...discountProps} readOnly={readOnly} />

                                {/* Pre tax amount */}
                                <TableRow>
                                    <TableCell>
                                        <Typography>Pre Tax Amount:</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <CurrencyTextField
                                            value={premium.PreTaxAmount}
                                            className={classes.alignRight}
                                            {...readOnlyInputProps}
                                            style={{ width: getWidth(premium.PreTaxAmount) }} />
                                    </TableCell>
                                </TableRow>

                                {/* Taxes */}
                                <TableRow>
                                    <TableCell>
                                        <Taxes taxes={premium.Taxes} policyIdx={index} />
                                    </TableCell>
                                    <TableCell>
                                        <CurrencyTextField
                                            value={premium.TaxesTotal}
                                            className={classes.alignRight}
                                            {...readOnlyInputProps}
                                            style={{ width: getWidth(premium.TaxesTotal) }} />
                                    </TableCell>
                                </TableRow>

                                {/* Total policy premium */}
                                <TableRow>
                                    <TableCell>
                                        <Typography>Total Policy Premium</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <CurrencyTextField
                                            value={premium.TotalAmount}
                                            className={classes.alignRight}
                                            {...readOnlyInputProps}
                                            style={{ width: getWidth(premium.TotalAmount) }} />
                                    </TableCell>
                                </TableRow>

                                {/* Post tax discounts */}
                                <Discounts items={p.Discounts} policyIdx={index} isAppliedAfterTax={true} {...discountProps} readOnly={readOnly} />

                                {/* Net Amount after kickbacks */}
                                <TableRow>
                                    <TableCell>
                                        <Typography>Net Amount</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <CurrencyTextField
                                            value={p.Premium.NetAmount}
                                            className={classes.alignRight}
                                            {...readOnlyInputProps}
                                            style={{ width: getWidth(p.Premium.NetAmount) }} />
                                    </TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>


                </Box>;
            })}
        </Box>
    </Fragment>;
}

export default PremiumBreakdown;