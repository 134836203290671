import { cloneDeep } from 'lodash';
import Logger from 'Util/Logger';

class Calculator {
    logger;

    constructor() {
        this.logger = new Logger(Calculator.name);
    }

    calculatePremium(premium) {
        if (!premium.BreakDown)
            return null;

        let newPremiumBreakdown = premium.BreakDown.map(p => {
            let premium = cloneDeep(p.Premium);

            let discountAmount = 0;
            const discountItems = p.Discounts;
            if (discountItems.length > 0)
                discountAmount = discountItems.map(x => x.DiscountAmount).reduce((a, b) => a + b);
            premium.DiscountAmount = discountAmount;

            let feesAmount = 0;
            const feeItems = p.Fees;
            if (feeItems.length > 0)
                feesAmount = feeItems.map(x => x.FeeAmount).reduce((a, b) => a + b);
            premium.FeesAmount = feesAmount

            let preTaxDiscount = 0;
            const preTaxDiscountItems = discountItems.filter(x => x.ApplyAfterTax !== true);
            if (preTaxDiscountItems.length > 0)
                preTaxDiscount = preTaxDiscountItems.map(x => x.DiscountAmount).reduce((a, b) => a + b);

            let postTaxDiscount = 0;
            const postTaxDiscountItems = discountItems.filter(x => x.ApplyAfterTax === true);
            if (postTaxDiscountItems.length > 0)
                postTaxDiscount = postTaxDiscountItems.map(x => x.DiscountAmount).reduce((a, b) => a + b);

            premium.PremiumAmount.PreTaxAmount = premium.PremiumAmount.BasePremium + feesAmount - preTaxDiscount;

            const taxes = premium.PremiumAmount.Taxes.map(x => premium.PremiumAmount.PreTaxAmount * x.Rate * 0.01);
            premium.PremiumAmount.TaxesTotal = parseFloat(taxes.reduce((a, b) => a + b).toFixed(2));

            premium.PremiumAmount.TotalAmount = premium.PremiumAmount.PreTaxAmount + premium.PremiumAmount.TaxesTotal;
            //Processing fees and hst goes here
            premium.Total = premium.PremiumAmount.TotalAmount; //add processing fees and other chargable items here
            premium.NetAmount = parseFloat((premium.PremiumAmount.TotalAmount - postTaxDiscount).toFixed(2));

            return {
                ...p,
                'Premium': premium
            };
        });

        const newPremium = {
            ...premium,
            'BreakDown': newPremiumBreakdown,
            'PremiumAmount': {
                'BasePremium': newPremiumBreakdown.map(x => x.Premium.PremiumAmount.BasePremium).reduce((a, b) => a + b),
                'PreTaxAmount': newPremiumBreakdown.map(x => x.Premium.PremiumAmount.PreTaxAmount).reduce((a, b) => a + b),
                'TaxesTotal': parseFloat(newPremiumBreakdown.map(x => x.Premium.PremiumAmount.TaxesTotal).reduce((a, b) => a + b).toFixed(2)),
                'TotalAmount': parseFloat(newPremiumBreakdown.map(x => x.Premium.PremiumAmount.TotalAmount).reduce((a, b) => a + b).toFixed(2)),
                'NetAmount': newPremiumBreakdown.map(x => x.Premium.NetAmount).reduce((a, b) => a + b)
            },
            'DiscountAmount': newPremiumBreakdown.map(x => x.Premium.DiscountAmount).reduce((a, b) => a + b),
            'FeesAmount': newPremiumBreakdown.map(x => x.Premium.FeesAmount).reduce((a, b) => a + b),
            'Total': parseFloat(newPremiumBreakdown.map(x => x.Premium.Total).reduce((a, b) => a + b).toFixed(2)),
            'NetAmount': parseFloat(newPremiumBreakdown.map(x => x.Premium.NetAmount).reduce((a, b) => a + b).toFixed(2))
        };

        return newPremium;
    }
}

const instance = new Calculator();

export { instance as Calculator };
