class BrowserHelper
{
    static isIE11()
    {
        const result = navigator.userAgent.indexOf('Trident') > -1 && navigator.userAgent.indexOf("rv:11.0") > -1;

        return result;
    }
}

export default BrowserHelper;