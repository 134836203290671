import { Typography } from '@material-ui/core';
import React from 'react';

function Taxes(props) {
    const { taxes, setTaxes } = props;

    if (!taxes)
        return null;

    return taxes.map((t, index) => <Typography key={index}>{t.Type} ({t.Rate}%) </Typography>);
}

export default Taxes;