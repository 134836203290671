const OrderStatusEnum = {
    NotSubmitted: "NotSubmitted",
    Submitted: "Submitted",
    UnderReview: "UnderReview",
    UserActionRequired: "UserActionRequired",
    Approved: "Approved",
    CoverageIssued: "CoverageIssued",
    Declined: "Declined",
    Cancelled: "Cancelled",
    Complete: "Complete",
    Deleted: "Deleted"
};

const PermittedOrderActionsEnum = {
    Save: "Save",
    RevertToUnsubmitted: "RevertToUnsubmitted",
    SubmitOrder: "SubmitOrder",
    Approve: "Approve",
    Decline: "Decline",
    Cancel: "Cancel",
    Delete: "Delete",
    Restore: "Restore",
    GenerateDocs: "GenerateDocs",
    ChangeStatus: "ChangeStatus",
    TakeNotes: "TakeNotes",
    ApplyExceptions: "ApplyExceptions",
    ApplyEndorsements: "ApplyEndorsements"
};

const ExceptionTypeEnum = {
    Manual: "Manual",
    Automatic: "Automatic"
}

const ApplicabilityTypeEnum = {
    Manual: "Manual",
    Automatic: "Automatic"
}

const EndorsementTypeEnum = {
    Standard: "Standard",
    Custom: "Custom"
}

const PolicyTypeEnum = {
    OwnerPolicyOnly: "owner_policy_only",
    LenderPolicyOnly: "lender_policy_only",
    OwnerAndLenderPolicy: "owner_and_lender"
}

const TagsEnum = {
    MngmntReview: "MngmntReview",
    ClaimsMatch: "ClaimsMatch",
    SuppDbMatch: "SuppDbMatch"
};

const SourceEnum = {
    DP: "DP",
    SelfServe: "SelfServe",
    DialIn: "DialIn"
}

const RegistrationStatusEnum = {
    NotRegistered: "NotRegistered",
    Registered: "Registered",
    InQueue: "InQueue",
    RegistrationFailed: "RegistrationFailed",
    Cancelled: "Cancelled",
    RegistrationAttemptsExhausted: "RegistrationAttemptsExhausted",
    RegistrationProhibited: "RegistrationProhibited",
    RegistrationBug: "RegistrationBug"
}

const TransactionTypeEnum = {
    Resale: "Resale",
    New: "New",
    MortgageOnly: "MortgageOnly",
    ExistingOwner: "ExistingOwner"
}

const PropertyTypeEnum = {
    Freehold: "Freehold",
    Condominium: "Condominium",
    SingleFamily: "SingleFamily",
    MultiUnit: "MultiUnit",
    VacantLand: "VacantLand",
    VacantLandCondo: "VacantLandCondo",
    ParcelOfTiedLand: "ParcelOfTiedLand",
    Farm: "Farm",
    Leasehold: "Leasehold",
    Commercial: "Commercial"
}

const CollectionTypeEnum = {
    Properties: "Properties",
    Mortgages: "Mortgages",
    Purchasers: "Purchasers",
    RemainingMortgages: "RemainingMortgages",
    NHPCondoLegalDescriptions : "NHPCondoLegalDescriptions"
}

const MortgageTypeEnum = {
    Private: "Private",
    Institutional: "Institutional",
    VendorTakeBack: "VendorTakeBack"
}

const ProvincesDDList = [
    { Id: "ON", Label: "Ontario"},
    { Id: "AB", Label: "Alberta"},
    { Id: "MB", Label: "Manitoba"}
];

export {
    OrderStatusEnum,
    PermittedOrderActionsEnum,
    TagsEnum,
    SourceEnum,
    ExceptionTypeEnum,
    ApplicabilityTypeEnum,
    EndorsementTypeEnum,
    PolicyTypeEnum,
    RegistrationStatusEnum,
    TransactionTypeEnum,
    PropertyTypeEnum,
    CollectionTypeEnum,
    MortgageTypeEnum,
    ProvincesDDList
};