import { Box, Button, makeStyles, TextField, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import purple from '@material-ui/core/colors/purple';
import teal from '@material-ui/core/colors/teal';
import yellow from '@material-ui/core/colors/yellow';
import DeleteIcon from '@material-ui/icons/Delete';
import BoxedChip from 'Components/BoxedChip';
import { ApplicabilityTypeEnum, PolicyTypeEnum } from 'Model/PolicyOrder';
import React from 'react';
import { StringUtil } from 'Util/Helpers';

const useStyles = makeStyles(() => ({
    exceptionTitle: {
        fontWeight: 500
    },
    multilineInput: {
        minHeight: 30,
        color: "black",
    },
    fullWidth: {
        width: "100%"
    },
    floatLeft: {
        float: "left"
    },
    floatRight: {
        float: "right"
    }
}));

function Exception(props) {

    const classes = useStyles();

    const { index, ex, isError, getErrMsg, onDescriptionChange, onDeleteException, canChangeExceptions, exRef } = props;

    //useselector and check permissions
    // if (!StringUtil.contains(po.PermittedActions, PermittedOrderActionsEnum.ApplyExceptions))
    //     return null;

    //#region Props and HTML
    const exceptionBoxProps = option => {
        return {
            ml: 1.5,
            mr: 1.5,
            mb: 1.5,
            pt: 1,
            pb: 0.5,
            pl: 1.5,
            pr: 1.5,
            border: 1,
            borderColor: option.IsError ? "error.main" : option.AlreadyApplied ? green[500] : grey[400],
            borderRadius: 5,
            style: { overflow: "hidden", backgroundColor: option.AlreadyApplied ? green[50] : "inherit" }
        };
    };

    const policyNumberProps = option => {
        return {
            color: purple[500],
            label: option.AppliedTo,
            className: classes.floatRight
        };
    };

    const policyTypeChipProps = option => {
        const l = StringUtil.isEqual(option.PolicyType, PolicyTypeEnum.LenderPolicyOnly) ? "Lender Policy" : "Owner Policy";
        return {
            color: StringUtil.isEqual(option.PolicyType, PolicyTypeEnum.LenderPolicyOnly) ? teal[500] : yellow[900],
            label: `${l}`,
            className: classes.floatRight
        };
    };

    const exceptionTypeChipProps = option => {
        return {
            color: StringUtil.isEqual(option.Type, ApplicabilityTypeEnum.Manual) ? pink[500] : indigo[500],
            label: option.Type,
            className: classes.floatRight
        };
    };

    return <React.Fragment>
        <Box key={ex.SecureId} {...exceptionBoxProps(ex)}>
            <Typography className={classes.exceptionTitle} component="span">
                {/* Exception Title */}
                {ex.Topic}
                {/* Policy Number */}
                <BoxedChip {...policyNumberProps(ex)} />
                {/* Owner / Lender Policy */}
                <BoxedChip {...policyTypeChipProps(ex)} />
                {/* Manual / Automatic Exception */}
                {StringUtil.isEqual(ex.Type, ApplicabilityTypeEnum.Automatic) && <BoxedChip {...exceptionTypeChipProps(ex)} />}
            </Typography>
            {/* Exception description */}
            <TextField
                fullWidth
                multiline
                value={ex.Description}
                error={isError(index)}
                helperText={getErrMsg(index)}
                onChange={event => onDescriptionChange(event.target.value, ex.SecureId)}
                InputProps={{
                    disableUnderline: true,
                    classes: {
                        inputMultiline: classes.multilineInput
                    },
                }}
                inputRef={exRef}
                disabled={ex.Deleting}
            />
            {/* Delete button */}
            {canChangeExceptions &&
                <Button className={classes.floatRight} style={{ color: grey[700] }}
                    onClick={() => onDeleteException(ex.SecureId)}
                    startIcon={ex.Saving || ex.Deleting ? <CircularProgress size={15} /> : <DeleteIcon />}
                    disabled={ex.Saving || ex.Deleting}>
                    {ex.Saving ? "Saving" : ex.Deleting ? "Deleting" : "Delete"}
                </Button>
            }
        </Box>
    </React.Fragment>;
    //#endregion Props and HTML 
}

export default Exception;