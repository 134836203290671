import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Question, SectionHeader } from 'Components/Questionary';
import { Button, Box, CircularProgress, Typography } from '@material-ui/core';
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { StringUtil } from 'Util/Helpers';
import * as QuestionIds from 'Components/Questionary/QuestionIds';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import ColumnHeader from 'Components/Table/ColumnHeader';
import { PermittedOrderActionsEnum, PolicyTypeEnum } from 'Model/PolicyOrder';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import * as DocumentThunks from 'Policy/State/DocumentThunks';
import { green } from '@material-ui/core/colors';
import { isEmpty } from 'lodash';

const useStylesBusyInd = makeStyles(theme => ({
    btnBusyInd: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: green[500]
    },
}));

function createOrderVolatileDataSelector() {
    return createSelector(
        state => state.order.orderVolatileData,
        (_, propName) => propName,
        (x, propName) => x[propName]
    );
}

function createGeneratingPDFSelector() {
    return createSelector(
        state => state.order.generatingPDF,
        x => x
    );
}

function SmallBusyInd(props) {
    const classes = useStylesBusyInd();

    return (
        <React.Fragment>
            <Typography>Generating...</Typography> <CircularProgress size={24} className={classes.btnBusyInd} />
        </React.Fragment>
    );
}

function DocumentRow(props) {
    const { premium, dispatch, po, generatingPolicyNum } = props;

    const selectGeneratingPDF = useMemo(
        createGeneratingPDFSelector,
        []
    );

    const generatingPDF = useSelector(x => selectGeneratingPDF(x));

    // todo: show which lender

    const orderSecureId = po.SecureId;
    const policyNumber = premium.PolicyNumber;

    let type = '';
    let lenderName = '';

    if (StringUtil.isEqual(premium.Type, PolicyTypeEnum.LenderPolicyOnly)) {
        type = 'Lender';
    }
    else if (StringUtil.isEqual(premium.Type, PolicyTypeEnum.OwnerPolicyOnly)) {
        type = 'Purchaser';
    }

    const handleViewPolicyPDF = (event) => {
        dispatch(DocumentThunks.generatePolicyDocument(orderSecureId, policyNumber));
    };

    const isBusy = !isEmpty(generatingPDF);

    return (
        <TableRow hover tabIndex={-1}>
            <TableCell>{premium.PolicyNumber}</TableCell>
            <TableCell>{type}</TableCell>
            <TableCell></TableCell>
            <TableCell align="right">
                <span style={{ display: 'inline-flex' }}>
                    <Box display="flex" alignItems="center">
                        {StringUtil.isEqual(generatingPDF.policyNumber, policyNumber) &&
                            <SmallBusyInd />
                        }
                        <Button
                            size="small"
                            type="button"
                            variant="contained"
                            color="primary"
                            startIcon={<PictureAsPdfIcon />}
                            onClick={handleViewPolicyPDF}
                            disabled={isBusy}
                        >
                            View/Print Policy
                        </Button>
                    </Box>
                </span>
            </TableCell>
        </TableRow>
    );
}

function PolicyDocumentsSection(props) {
    const { questionary, questionParams, getInitialAnswer, po } = props;

    const selectOrderVolatileData = useMemo(
        createOrderVolatileDataSelector,
        []
    );

    const dispatch = useDispatch();

    const premium = useSelector(x => selectOrderVolatileData(x, "Premium"));

    const permittedActions = useSelector(x => selectOrderVolatileData(x, "PermittedActions"));

    const allowPrintDocs = StringUtil.contains(permittedActions, PermittedOrderActionsEnum.GenerateDocs);

    if (!allowPrintDocs) {
        return null; // do not show this section unless it's appropriate
    }

    if(!premium || !premium.BreakDown) {
        return null;
    }

    const breakDownArr = premium.BreakDown;

    return (
        <React.Fragment>
            <SectionHeader section="documents" title="Documents" questionary={questionary} />
            <Box mt={1} mb={2} flexGrow={1}>
                <TableContainer>
                    <Table aria-label="documents table" size="small">
                        <TableHead>
                            <TableRow>
                                <ColumnHeader id={"PolicyNum"}>Policy #</ColumnHeader>
                                <ColumnHeader id={"Type"}>Type</ColumnHeader>
                                <ColumnHeader id={"Info"}>Additional Info</ColumnHeader>
                                <ColumnHeader id={"Action"} align="right">Action</ColumnHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {breakDownArr.map((premium, index) => (
                                <DocumentRow premium={premium} key={index} dispatch={dispatch} po={po} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </React.Fragment>
    );
}

export default PolicyDocumentsSection;