import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AdminApiClient } from 'Admin/AdminApiClient';
import { StringUtil } from 'Util/Helpers';

const initialState = {
    list: null,
    status: 'idle',
    error: null
}

async function loadUsers() {
    const resp = await AdminApiClient.loadAllTPUsers();

    if (StringUtil.isNullOrEmpty(resp.data)) {
        return [];
    }

    const users = resp.data.Users;

    return users;
}

export const fetchTPStaffUsers = createAsyncThunk('tpStaffUsers/fetch', loadUsers);

const TPStaffUsersSlice = createSlice({
    name: 'tpStaffUsers',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchTPStaffUsers.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchTPStaffUsers.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.list = action.payload;
        },
        [fetchTPStaffUsers.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        }
    }
});

export default TPStaffUsersSlice.reducer;