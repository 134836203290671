import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormGroup, FormControl, TextField } from '@material-ui/core';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
    longTextField: {
        width: '100%',
        paddingRight: theme.spacing(2)
    }
}));

const txt = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus at lacinia lectus, eget blandit sapien. Vivamus quis est vel lorem dignissim pulvinar quis et purus. Curabitur pulvinar sapien et aliquet euismod. Vivamus magna nunc, tempus id facilisis eu, sodales id est. Suspendisse cursus nulla eget erat venenatis, quis consectetur elit efficitur. Suspendisse mollis risus vitae lectus maximus, vitae efficitur elit accumsan. Nulla volutpat nec ante sed congue. Ut malesuada justo non ante cursus, eget tristique lectus laoreet. Integer id eleifend dolor. Suspendisse vitae orci ac mi pulvinar commodo quis ac turpis. Nulla facilisi. Curabitur augue ex, aliquet at elementum ut, aliquam sed tortor. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec nulla urna, facilisis in dapibus in, ullamcorper sit amet risus.

Etiam accumsan laoreet purus sed sodales. Suspendisse potenti. Donec dignissim nisl neque, ac faucibus metus ultricies et. Nam interdum velit ligula, ullamcorper cursus justo hendrerit et. In vitae sem sodales, iaculis nisi scelerisque, imperdiet libero. Curabitur porttitor augue at dolor dignissim imperdiet. Curabitur vehicula venenatis nisl, sit amet ultrices sem laoreet a. Nullam quis sapien nec ligula mattis aliquam in sit amet purus. Duis tincidunt pretium libero.

Mauris pharetra vulputate malesuada. Praesent dignissim tortor at dui ullamcorper, vitae tristique ipsum vulputate. Aliquam felis justo, euismod sed est vitae, tincidunt sodales odio. Aliquam id sagittis magna. Ut condimentum turpis et pharetra pretium. Pellentesque orci elit, tincidunt non ex at, sagittis iaculis dolor. Pellentesque rutrum tellus nec enim condimentum, id suscipit nisi fermentum. Integer tincidunt velit ac dui rhoncus, eu consequat sapien feugiat. Aenean at urna et orci sollicitudin facilisis. Donec felis enim, bibendum ut luctus sit amet, mollis id mi. Donec fermentum orci et sem blandit fringilla. Integer sit amet orci orci. Cras non egestas mauris.

Suspendisse potenti. Cras vitae rutrum metus. Vivamus nec consequat magna. Praesent vitae pharetra enim. Cras sed diam lorem. Curabitur in tristique enim. Nullam in nibh et ipsum imperdiet hendrerit. Fusce nibh nisi, sagittis sed elementum eget, semper non magna. Phasellus odio ante, gravida quis aliquet sit amet, sollicitudin eu ex. Maecenas non mi pharetra, scelerisque sem nec, laoreet velit. Aliquam erat volutpat. Mauris tempor nisl massa, et porta nisl convallis et. Proin egestas urna facilisis, facilisis purus ut, tincidunt erat. Morbi cursus pretium enim ac tincidunt. Cras semper convallis arcu, sit amet mattis arcu dapibus eu. Donec tellus quam, tincidunt non leo ut, vulputate venenatis elit.

Ut fermentum iaculis ex et aliquet. Praesent condimentum sem id nisi commodo, non egestas est viverra. Nulla at magna suscipit, congue ipsum eget, dignissim est. Phasellus eleifend, elit non fringilla dapibus, est quam gravida metus, sit amet sollicitudin nulla dui molestie arcu. Morbi sollicitudin non mi volutpat iaculis. Sed at mauris lectus. Vestibulum facilisis, lacus ut egestas laoreet, tellus augue blandit tellus, vitae dignissim odio ligula ac ex. Donec tempus vestibulum erat, a imperdiet massa bibendum ac. Proin ac suscipit massa. Etiam iaculis sit amet lectus a posuere. Nullam facilisis ante quis massa efficitur elementum. Pellentesque scelerisque non sem ac scelerisque. Morbi pharetra, ante at consequat porttitor, sem lorem cursus tellus, rhoncus volutpat odio quam nec augue.

Aenean sodales felis ut luctus vulputate. Donec porttitor risus nibh, vitae cursus elit posuere eget. Cras vel tellus ornare, viverra libero sed, eleifend lorem. Curabitur scelerisque lorem vitae eros ornare euismod. Nunc facilisis tellus ac semper viverra. Duis in feugiat quam. Ut diam justo, bibendum quis rutrum vel, iaculis et ligula. Nulla tristique interdum felis, auctor vestibulum nisl feugiat non. Nunc tempor ullamcorper lorem, at blandit leo viverra in. Etiam accumsan sed ligula congue tincidunt. Donec tincidunt, sapien nec sollicitudin aliquam, leo nisi tempor nibh, quis vestibulum tortor ex nec turpis. Curabitur condimentum feugiat enim vitae consequat. Pellentesque volutpat sed justo non mollis.

Morbi dolor risus, consectetur at dapibus sit amet, volutpat rhoncus nunc. Vivamus aliquam, nunc blandit commodo venenatis, diam sapien malesuada massa, sit amet mollis elit augue et est. Suspendisse pretium diam at elit volutpat, at tempor libero interdum. Nulla facilisi. Pellentesque iaculis libero nec mauris accumsan auctor. Nulla quis arcu non dui laoreet vehicula. Nam sapien odio, congue eu iaculis a, pharetra nec ante. Nulla hendrerit tortor nec quam lacinia, eget aliquet odio gravida. Integer auctor faucibus justo non iaculis. Maecenas eget elit id sapien rutrum fringilla vel at tellus. In a tincidunt dolor. Morbi placerat hendrerit velit, ac imperdiet ante varius sed. Phasellus fringilla ante vel fermentum feugiat. Sed convallis nunc mauris, sed venenatis felis vulputate eu. Fusce tempus lectus erat. Sed a elementum metus.

Nam convallis leo elit, sed sollicitudin nibh ornare eget. Sed id risus mauris. Vestibulum volutpat bibendum felis, in accumsan leo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec sit amet sagittis ligula, a dapibus ante. Quisque iaculis lobortis erat, sit amet vehicula ligula placerat id. Sed in egestas risus, a fermentum purus.

Vestibulum lacinia at dolor semper placerat. Nam vel elit in ante faucibus ultricies. Vivamus cursus ac nulla eget volutpat. Pellentesque finibus lectus at justo placerat, ut rhoncus turpis imperdiet. Curabitur malesuada ante gravida neque venenatis gravida. Sed porttitor libero et elit imperdiet, non porttitor nisl sodales. Suspendisse id euismod sem, ut sodales sapien. Pellentesque enim nibh, sollicitudin ac neque id, elementum tincidunt lacus.

Vivamus ac dui id dolor lobortis vestibulum non sed nibh. Nunc in tortor leo. Curabitur non enim eget neque facilisis porttitor. Nulla augue tellus, hendrerit in elit at, ultrices convallis massa. Phasellus massa sem, molestie vel auctor non, efficitur id eros. Integer sagittis rutrum vehicula. Donec eu orci vitae ante elementum maximus id et metus. Sed placerat, arcu a consequat accumsan, nisl lectus gravida lacus, id finibus ante massa non felis. Praesent quam odio, lacinia posuere massa a, fermentum pulvinar odio. Suspendisse potenti. Aenean vitae nisi eget ante placerat vestibulum. Nam eget aliquet ipsum. Mauris id dictum neque. Phasellus porttitor nulla ac eros efficitur, non pellentesque turpis lobortis. Etiam pulvinar neque ac dapibus varius. Etiam at sollicitudin neque.`;

function TermsAndConditions(props) {
    const { qId, fieldName, addl, inputProps } = props;

    const classes = useStyles();
    
    addl.readOnly = true;

    return (
        <FormControl name={qId} area-label={qId} style={{ width: '100%' }}>
            <FormGroup row>
                <TextField className={classes.longTextField} rows={20} {...addl} {...inputProps(fieldName)} defaultValue={txt}></TextField>
            </FormGroup>
        </FormControl>
    );
}

export default TermsAndConditions;