import { Box, Button, makeStyles, TextField, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import purple from '@material-ui/core/colors/purple';
import teal from '@material-ui/core/colors/teal';
import yellow from '@material-ui/core/colors/yellow';
import brown from '@material-ui/core/colors/brown';
import DeleteIcon from '@material-ui/icons/Delete';
import BoxedChip from 'Components/BoxedChip';
import { ApplicabilityTypeEnum, EndorsementTypeEnum, PolicyTypeEnum } from 'Model/PolicyOrder';
import React from 'react';
import { StringUtil } from 'Util/Helpers';

const useStyles = makeStyles(() => ({
    title: {
        fontWeight: 500
    },
    multilineInput: {
        minHeight: 30,
        color: "black",
    },
    fullWidth: {
        width: "100%"
    },
    floatLeft: {
        float: "left"
    },
    floatRight: {
        float: "right"
    }
}));

function Endorsement(props) {

    const classes = useStyles();

    const { index, item, isError, getErrMsg, onDescriptionChange, onDelete, canChange, controlRef } = props;

    //#region Props and HTML
    const itemBoxProps = option => {
        return {
            ml: 1.5,
            mr: 1.5,
            mb: 1.5,
            pt: 1,
            pb: 0.5,
            pl: 1.5,
            pr: 1.5,
            border: 1,
            borderColor: option.IsError ? "error.main" : option.AlreadyApplied ? green[500] : grey[400],
            borderRadius: 5,
            style: { overflow: "hidden", backgroundColor: option.AlreadyApplied ? green[50] : "inherit" }
        };
    };

    const policyNumberProps = option => {
        return {
            color: purple[500],
            label: option.AppliedTo
        };
    };

    const policyTypeChipProps = option => {
        const l = StringUtil.isEqual(option.PolicyType, PolicyTypeEnum.LenderPolicyOnly) ? "Lender Policy" : "Owner Policy";
        return {
            color: StringUtil.isEqual(option.PolicyType, PolicyTypeEnum.LenderPolicyOnly) ? teal[500] : yellow[900],
            label: `${l}`
        };
    };

    const typeChipProps = option => {
        return {
            color: StringUtil.isEqual(option.Type, ApplicabilityTypeEnum.Manual) ? pink[500] : indigo[500],
            label: option.Type
        };
    };

    const itemTypeChipProps = option => {
        return {
            color: StringUtil.isEqual(option.EndorsementType, EndorsementTypeEnum.Standard) ? brown[700] : purple[700],
            label: option.EndorsementType
        };
    };

    return <React.Fragment>
        <Box key={item.SecureId} {...itemBoxProps(item)}>
            <Typography className={`${classes.title}`} component="span">
                {/* Title */}
                {item.Topic}
            </Typography>
            <Typography className={`${classes.floatRight}`} component="span">
                {/* Manual / Automatic Endorsement */}
                {StringUtil.isEqual(item.Type, ApplicabilityTypeEnum.Automatic) && <BoxedChip {...typeChipProps(item)} />}
                {/* Standard/ Custom Endorsement */}
                <BoxedChip {...itemTypeChipProps(item)} />
                {/* Owner / Lender Policy */}
                <BoxedChip {...policyTypeChipProps(item)} />
                {/* Policy Number */}
                <BoxedChip {...policyNumberProps(item)} />
            </Typography>
            {/* Endorsement description */}
            {StringUtil.isEqual(item.EndorsementType, EndorsementTypeEnum.Custom) &&
                <TextField
                    fullWidth
                    multiline
                    value={item.Description}
                    error={isError(index)}
                    helperText={getErrMsg(index)}
                    onChange={event => onDescriptionChange(event.target.value, item.SecureId)}
                    InputProps={{
                        disableUnderline: true,
                        classes: {
                            inputMultiline: classes.multilineInput
                        },
                    }}
                    inputRef={controlRef}
                    disabled={item.Deleting}
                />}
            {/* Delete button */}
            {canChange &&
                    <Button className={classes.floatRight} style={{ color: grey[700] }}
                        onClick={() => onDelete(item.SecureId)}
                        startIcon={item.Saving || item.Deleting ? <CircularProgress size={15} /> : <DeleteIcon />}
                        disabled={item.Saving || item.Deleting}>
                        {item.Saving ? "Saving" : item.Deleting ? "Deleting" : "Delete"}
                    </Button>
            }
        </Box>
    </React.Fragment>;
    //#endregion Props and HTML 
}

export default Endorsement;