import React from 'react';
import { RadioGroup, FormControl, FormControlLabel, Radio, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StringUtil } from 'Util/Helpers';

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main
  },
  readOnlySelected: {
    '&.Mui-disabled': {
      color: theme.palette.primary.main,
    //  text
      //opacity: 0.5,
    },
    '.MuiFormControlLabel-label.Mui-disabled':{
      color: theme.palette.primary.main,
    }
  },
  readOnly:{
    '&.Mui-disabled': {
      //color: theme.palette.primary.main,
      //opacity: 0.5,
    },
  }
}));

function OptionGroup(props) {
  const { name, fieldName, autoFocus, fieldStateResolver, defaultValue, error, helperText, readOnly, ...other } = props;

  const classes = useStyles();

  let opts = props.opts;

  if (StringUtil.isNullOrEmpty(opts))
    opts = [];

  const classN = error ? classes.error : null;

  let radioGroupProps = { ...other };

  if (readOnly) {
    //radioGroupProps["value"] = defaultValue;
    //delete radioGroupProps.onChange;
  }
  //else
  radioGroupProps["defaultValue"] = defaultValue;

  // this is an unctonrolled component...
  return (
    <FormControl error={error}>
      <RadioGroup aria-label={name} name={name} row className={classN} {...radioGroupProps}>
        {opts.map((opt) => {
          return <FormControlLabel
            key={opt.Id}
            value={opt.Id}
            control={<Radio
              //disabled
              color="primary" 
              //className={classes.readOnlySelected} 
              autoFocus={autoFocus} />}
            label={opt.Label}
            labelPlacement="end"
          />
        })}
      </RadioGroup>
      {!StringUtil.isNullOrEmpty(helperText) &&
        <FormHelperText style={{ marginBottom: '0.8rem' }}>{helperText}</FormHelperText>
      }
    </FormControl>
  );
}

export default OptionGroup;