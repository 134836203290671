import { makeStyles, TableCell, Typography } from '@material-ui/core';
import { Question } from 'Components/Questionary';
import React, { Fragment } from "react";
import { QuestionaryHelper } from 'Util/QuestionaryHelper';
import StringUtil from 'Util/StringUtil';

const useStyles = makeStyles((theme) => ({
    suffix: {
        textTransform: "capitalize",
        marginLeft: theme.spacing(0.5)
    }
}));


function MortgageInfo(props) {
    const classes = useStyles();
    const { idx, item, collectionName, questionParams } = props;

    if (!item)
        return null;

    questionParams.indexer = QuestionaryHelper.createIndexer(collectionName, item);

    return <Fragment>
        <TableCell>
            <Typography>
                Lender Policy {item.PolicyNumber}
            </Typography>
        </TableCell>
        <TableCell>
            <Typography className={classes.suffix}>
                {`${StringUtil.stringifyNumber(idx + 1)} Mortgage:`}
            </Typography>
            <Question qId="MortgageLenderName" {...questionParams} displayOnly hideLabel />
        </TableCell>
        <TableCell>
            <Question qId="MortgagePrincipalAmount" {...questionParams} displayOnly hideLabel />
        </TableCell>
    </Fragment>;
}

export default MortgageInfo;